import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CalendarComponent)
    }
  ]
})
export class CalendarComponent implements ControlValueAccessor, OnInit {
  input: any;
  @Input() fullSize = false;
  @Input() maxDate: NgbDateStruct | undefined;
  @Output() dateSelect = new EventEmitter<any>();
  @Input() readOnly = false;

  constructor(private elementRef: ElementRef, private datePipe: DatePipe) {
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: any): void {
    this.input = input;
  }

  ngOnInit(): void {
  }

  toggleCalendar(d: NgbInputDatepicker): void {
    d.toggle();
  }

  dateSelected($event: any): void {
    this.dateSelect.emit($event);
  }

  numberOnly($event: KeyboardEvent): void {
    const pattern = /\d/;
    if (!pattern.test($event.code)) {
      $event.preventDefault();
    }
  }

  formatAsDateAndFireEvent($event: KeyboardEvent, d: NgbInputDatepicker): void {
    const input = this.elementRef.nativeElement.querySelector('input');
    if (input.value.match(/[^0-9^\/]/) != null) {
      input.value = '';
    } else {
      if (input.value.match(/^\d{2}$/) !== null) {
        input.value += '/';
      } else if (input.value.match(/^\d{2}\/\d{2}$/) !== null) {
        input.value += '/';
      } else if (input.value.length === 10) {
        const match = input.value.match(/^(([012]\d)|([3][01]))\/((0\d)|(1[012]))\/(\d{4})$/);
        if (match != null) {
          d.manualDateChange('01/01/1901', false);
          d.manualDateChange(input.value, true);
          this.dateSelect.emit(this.input);
        } else {
          const now = this.datePipe.transform(new Date(), 'dd/MM/yyyy') || '01/01/1901';
          d.manualDateChange(now, true);
        }
      }
    }
  }
}
