import {Component, OnInit} from '@angular/core';
import {Role, UserRole} from '../../../models/domain';
import {HelperService} from '../../../core/services/helper.service';
import {ActivatedRoute} from '@angular/router';
import {AuditLogService} from '../../../core/services/audit-log.service';
import {UserRoleService} from '../../../core/services/user-role.service';
import {ConfirmComponent} from '../../shared/confirm/confirm.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NavigationService} from '../../../core/services/navigationService';

@Component({
  selector: 'app-edit-role-header',
  templateUrl: './edit-role-header.component.html',
  styleUrls: ['./edit-role-header.component.scss']
})
export class EditRoleHeaderComponent implements OnInit {

  usuarios: UserRole[];
  roles?: Role[];
  titleSearch = 'Consulte perfil de um usuário';
  placeHolderSearch = 'Nome do usuário';
  maskSearch = '';
  public userName: string | null | undefined;
  loading = false;
  private userSelect: string | null | undefined;

  constructor(private userRoleService: UserRoleService,
              private activatedRoute: ActivatedRoute,
              private auditLogService: AuditLogService,
              private helperService: HelperService,
              private modalService: NgbModal,
              private navigationService: NavigationService) {

    this.usuarios = [];
  }

  public setUser(value: string): void {
    this.searchSubmit(value);
  }

  goBack(): void {
    this.roles = undefined;
    this.userName = undefined;
    this.auditLogService.clear();
    this.helperService.showSubMenu();
  }

  searchSubmit(val: string): void {
    if (val != null) {
      this.loading = true;
      this.userRoleService.listUserRoles(val).subscribe(x => {
        this.loading = false;
        this.readUserRoleInfo(val, x);
      });
    }
  }

  ngOnInit(): void {
    this.loadUsers();
    this.userRoleService.onChangeUserRole.subscribe(x => {
      this.goBack();
      this.loadUsers();
    });
  }

  loadUsers(): void {
    this.loading = true;
    this.userRoleService.listUsers().subscribe(x => {
      this.usuarios = x;
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  private readUserRoleInfo(userName: string, userRole: Role[]): void {
    if (userRole == null || userRole.length === 0) {
      const modalRef = this.modalService.open(ConfirmComponent);
      modalRef.componentInstance.pergunta = `O usuário ${userName} não existe, deseja criá-lo?`;
      modalRef.closed.subscribe(x => {
        if (x) {
          this.readUserRoleInfo0(userName, userRole);
        }
      });
    } else {
      this.readUserRoleInfo0(userName, userRole);
    }
  }

  private readUserRoleInfo0(userName: string, userRole: Role[]): void {
    this.userName = userName;
    this.roles = userRole;
    if (this.userName != null) {
      this.userRoleService.loadAuditLogs(this.userName);
    }
    this.helperService.hideSubMenu();
  }

}
