<div class="container top-buffer" *ngIf="blocklist != null">
  <table class="table-blue">
    <thead>
    <tr>
      <th colspan="5"></th>
    </tr>
    <tr>
      <th class="cursor" (click)="sortTable('partner')">Parceiro<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('type')">Tipo<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('value')">Valor<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('reason')">Motivo<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('creationDate')">Data Criação<span class="{{setaSort}}"></span></th>
    </tr>
    <tr></tr>
    </thead>
    <tbody>
    <tr *ngFor="let blocklistInfo of blocklist">
      <td class="text-capitalize">{{ blocklistInfo.partner }}</td>
      <td class="text-capitalize">{{ blocklistInfo.type }}</td>
      <td class="text-capitalize">{{ blocklistInfo.value }}</td>
      <td class="text-capitalize">{{ blocklistInfo.reason }}</td>
      <td>{{ blocklistInfo.creationDate | date:'dd/MM/yyyy' }}</td>
    </tr>
    <tr *ngIf="blocklist.length == 0">
      <td colspan="5">Nenhum registro encontrado</td>
    </tr>
    </tbody>
  </table>
</div>
