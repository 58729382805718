import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthenticationService} from '../services/authentication.service';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {HelperService} from '../services/helper.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authenticationService.getAuthData();
    let url = request.url;
    if (!url.startsWith(environment.apiUrl)) {
      if (url.startsWith('/')) {
        url = `${environment.apiUrl}${url}`;
      } else {
        url = `${environment.apiUrl}/${url}`;
      }
    }
    if (currentUser) {
      request = request.clone({
        url: `${url}`,
        setHeaders: {
          Authorization: `Basic ${currentUser}`
        }
      });
    } else {
      request = request.clone({
        url: `${url}`
      });
    }
    return next.handle(request);
  }
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private helperService: HelperService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(response => {
        if (response.status === 401) {
          this.authenticationService.logout();
        } else if (response.status === 0) {
          this.helperService.showError('Serviço indisponível');
        } else if (response.status === 403) {
          this.helperService.showError('Acesso negado');
        } else if (response.status === 500) {
          this.helperService.showError('Ops! Ocorreu um erro.');
        } else if (response.status === 404) {
          this.helperService.showError('Ops! O cadastro não foi localizado.');
        } else if (request.headers.get('bypassDefaultMessageInterceptor') !== 'true') {
          if (response.error != null && response.error.message != null) {
            this.helperService.showError(response.error.message);
          } else {
            this.helperService.showError(response.message);
          }
        }
        return throwError(response);
      })
    );
  }
}
