import {Observable} from 'rxjs';
import {SuspdDividaDetalhe, SuspdOpcaoPagamento} from '../../models/domain';
import {Injectable} from '@angular/core';
import {ApiServices} from './api.services';

@Injectable()
export class DebtService {

  constructor(private apiServices: ApiServices) {
  }

  getDetailList(dealId: number): Observable<SuspdDividaDetalhe[]> {
    return this.apiServices.getDetailList(dealId);
  }

  getPaymentOptionListList(dealId: number): Observable<SuspdOpcaoPagamento[]> {
    return this.apiServices.getPaymentOptionListList(dealId);
  }

}
