import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SuspdCredor, SuspdPoliticaParcelamento } from '../../../models/domain';
import { PartnerService } from '../../../core/services/partner.service';
import { HelperService } from '../../../core/services/helper.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-politic-calc-batch-update',
  templateUrl: './installment-batch-update.component.html',
  styleUrls: ['./installment-batch-update.component.scss']
})
export class InstallmentBatchUpdateComponent implements OnInit {

  @Input() creditor: SuspdCredor | null = null;
  @Input() installmentPolicyList: SuspdPoliticaParcelamento[] | undefined;
  @Output() oncancel = new EventEmitter<any>();
  @Output() onsave = new EventEmitter<any>();
  loading = false;
  uploadFile: File | null = null;
  installmentPolicy: number | undefined;
  blocked = true;
  MaxFileSize = 104857600;

  constructor(private partnerService: PartnerService, private helperService: HelperService) {
  }

  ngOnInit(): void {
    this.partnerService.isBlocked().subscribe(blocked => {
      this.blocked = blocked;
    });
  }

  onSubmit(): void {
    if (this.creditor?.id != null && this.creditor?.identificador == "hoepers" && this.uploadFile != null) {
      if (this.uploadFile.size <= this.MaxFileSize) {
        this.loading = true;
        this.partnerService.updateCalculationPolicy(this.uploadFile).subscribe(x => {
          this.loading = false;
          this.helperService.showMessage(`${x}`);
          this.onsave.emit();
        }, err => {
          this.loading = false;
          const message = JSON.parse(err.error);
          this.helperService.showError(message.message);
        });
      } else {
        this.helperService.showError(`Esse arquivo tem um tamanho maior do que o esperado: ${this.validateSizeFile(this.uploadFile.size)}`);
      }
    } else {
      if (this.creditor?.id != null && this.installmentPolicy != null && this.installmentPolicy > 0 && this.uploadFile != null) {
        this.loading = true;
        this.partnerService.batchUpdate(this.creditor.id, this.installmentPolicy, this.uploadFile).subscribe(x => {
          this.loading = false;
          this.helperService.showMessage(`${x} registro(s) incluído(s) com sucesso`);
          this.onsave.emit();
        }, err => {
          this.loading = false;
        });
      } else {
        this.helperService.showMessage(`Favor preencher todos os campos`);
      }
    }
  }

  validateSizeFile(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onCancel(): void {
    this.oncancel.emit();
  }

  handleFile(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList) {
      this.uploadFile = fileList.item(0);
    } else {
      this.uploadFile = null;
    }
  }

  downloadModel(): void {
    if (this.creditor?.identificador == "hoepers") {
      window.open(`${environment.apiUrl}/modelo_politica_hoepers.csv`);
    } else {
      window.open(`${environment.apiUrl}/modelo_suspd_politica_calculo.csv`);
    }
  }
}
