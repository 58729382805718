import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SuspdPoliticaCalculo} from '../../../../models/domain';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {sortBy} from 'sort-by-typescript';
import {InstallmentListEditComponent} from '../installment-list-edit/installment-list-edit.component';
import {PartnerService} from '../../../../core/services/partner.service';
import {ConfirmComponent} from '../../../shared/confirm/confirm.component';
import {HelperService} from '../../../../core/services/helper.service';

@Component({
  selector: 'app-politic-calc-list',
  templateUrl: './installment-list.component.html',
  styleUrls: ['./installment-list.component.scss']
})
export class InstallmentListComponent {

  public politicCalcList?: SuspdPoliticaCalculo[];
  public politicSelected?: SuspdPoliticaCalculo;
  public politicCalcListSort: any;
  public sort: any;
  public desc = false;
  public setaSort: any = 'seta-baixo';
  loading = true;

  size = 10;
  page = 1;

  labelPrevious = 'Ant.';
  labelNext = 'Próx.';
  @Output() ondeleteSuspdPoliticaCalculo = new EventEmitter<SuspdPoliticaCalculo>();

  constructor(private modalService: NgbModal,
              public activeModal: NgbActiveModal,
              private partnerService: PartnerService,
              private helperService: HelperService) {
  }

  @Input()
  public setPoliticCalcListValue(politicList: SuspdPoliticaCalculo): void {
    this.loading = true;
    if (politicList !== undefined && politicList.id !== undefined) {
      this.politicSelected = politicList;
      this.partnerService.getPoliticCalc(politicList?.id).subscribe(x => {
        this.politicCalcList = x;
        this.politicCalcListSort = x;
        this.loading = false;
      });
      this.loading = false;
    }
  }

  ngOnInit(): void {
    this.loadPolitics();
  }

  showModalEdit(politicCalc: SuspdPoliticaCalculo): void {
    const modalRef = this.modalService.open(InstallmentListEditComponent, {windowClass: 'medium-modal', backdrop: 'static'});
    modalRef.shown.subscribe(x => {
      modalRef.componentInstance.setPoliticCalcListValue(politicCalc);
    });
    modalRef.closed.subscribe(x => {
      if (x === true) {
        this.loadPolitics();
      }
    });
  }

  showModalCreateNew(): void {
    const modalRef = this.modalService.open(InstallmentListEditComponent, {windowClass: 'medium-modal', backdrop: 'static'});
    modalRef.shown.subscribe(x => {
      modalRef.componentInstance.setNewPoliticCalcListValue(this.politicSelected);
    });
    modalRef.closed.subscribe(x => {
      if (x === true) {
        this.loadPolitics();
      }
    });
  }

  sortTable(sort: string): void {
    if (this.sort === sort && this.desc === false) {
      this.desc = true;
      this.setaSort = 'seta-cima';
    } else {
      this.desc = false;
      this.setaSort = 'seta-baixo';
    }
    this.sort = sort;
    if (this.desc) {
      this.politicCalcListSort.sort(sortBy('-'.concat(sort)));
    } else {
      this.politicCalcListSort.sort(sortBy(sort));
    }
  }

  tabSize($event: number): void {
    this.page = $event;
  }

  deleteClick(suspdPoliticaCalculo: SuspdPoliticaCalculo): void {
    if (this.loading) {
      return;
    }
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.pergunta = `Confirma exclusão da Política de Cálculo ${suspdPoliticaCalculo.id}?`;
    modalRef.closed.subscribe(x => {
      if (x) {
        this.loading = true;
        if (suspdPoliticaCalculo.id != null) {
          this.partnerService.deleteSuspdPoliticaCalculo(suspdPoliticaCalculo.id).subscribe(x => {
            this.loading = false;
            this.helperService.showMessage('Política de Cálculo excluído com sucesso!');
            this.ondeleteSuspdPoliticaCalculo.emit(suspdPoliticaCalculo);
            this.loadPolitics();
          });
        }
      }
    });
  }

  loadPolitics(): void {
    this.loading = true;
    if (this.politicSelected != null && this.politicSelected.id != null) {
      this.partnerService.getPoliticCalc(this.politicSelected.id).subscribe(x => {
        this.politicCalcList = x;
        this.politicCalcListSort = x;
        this.loading = false;
      });
    }
  }

  downloadCsv(): void {
    this.loading = true;
    if (this.politicSelected != null && this.politicSelected.id != null && this.politicSelected.chave != null) {
      this.partnerService.downloadCalcPolicyCsv(this.politicSelected.id, this.politicSelected.chave).subscribe(x => {
        this.loading = false;
        const blob = new Blob([x], {type: 'text/csv'});
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        link.download = `politica_calculo.csv`;
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      }, err => {
        this.loading = false;
      });
    }
  }
}
