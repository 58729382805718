import {Observable, Subject} from 'rxjs';
import {AuditLog, Role, UserRole} from '../../models/domain';
import {Injectable} from '@angular/core';
import {ApiServices} from './api.services';
import {AuditLogService} from './audit-log.service';
import {tap} from 'rxjs/operators';

@Injectable()
export class UserRoleService {

  private changeRoleSubject = new Subject<string>();

  constructor(private apiService: ApiServices, private auditLogService: AuditLogService) {
  }

  get onChangeUserRole(): Observable<string> {
    return this.changeRoleSubject.asObservable();
  }

  listUsers(): Observable<UserRole[]> {
    return this.apiService.listUsers();
  }

  listUserRoles(userName: string): Observable<Role[]> {
    return this.apiService.listUserRoles(userName);
  }

  insertRole(userName: string, roleId: number): Observable<UserRole> {
    return this.apiService.insertRole(userName, roleId).pipe(
      tap(x => {
        this.changeRoleSubject.next(x.userName);
      })
    );
  }

  deleteUser(userName: string): Observable<string> {
    return this.apiService.deleteUser(userName).pipe(
      tap(x => {
        this.changeRoleSubject.next(x);
      })
    );
  }

  loadAuditLogs(userName: string): void {
    this.apiService.loadUserRoleAuditLogs(userName).subscribe(logs => {
      this.auditLogService.emitLog(logs);
    });
  }
}
