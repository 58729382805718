import {Observable} from 'rxjs';
import {Role} from '../../models/domain';
import {Injectable} from '@angular/core';
import {ApiServices} from './api.services';

@Injectable()
export class RoleService {

  constructor(private apiService: ApiServices) {
  }

  listAll(): Observable<Role[]> {
    return this.apiService.listAllRoles();
  }
}
