import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserInfo} from '../../models/domain';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  samlUrl = `${environment.samlUrl}`;

  constructor(private authenticationService: AuthenticationService, private activatedRoute: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    this.authenticationService.logout();
    document.body.className = 'login-body';
    this.authenticationService.userLoginObservable.subscribe(user => {
      this.router.navigate(['']);
    });
    this.activatedRoute.queryParamMap
      .subscribe((params) => {
          const auth = params.get('auth');
          if (auth != null) {
            const tokenInfo = JSON.parse(window.atob(auth));
            const userInfo = new UserInfo();
            userInfo.username = tokenInfo.username;
            userInfo.token = tokenInfo.token;
            this.authenticationService.login(userInfo);
          }
        }
      );
  }

  ngOnDestroy(): void {
    document.body.className = '';
  }

}
