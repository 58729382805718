<app-search *ngIf="roles == null" (searchSubmit)="searchSubmit($event)" [searchTitle]="titleSearch" [large]="true" [searchPlaceholder]="placeHolderSearch"></app-search>

<div class="container" *ngIf="roles != null">
  <div class="name-container">
    <div class="list-inline" >
      <a href="javascript:void(0)" (click)="goBack()" class="link-back">
        <img class="white-icon icon-large" src="assets/svg/icons/arrow-left.svg" alt="Voltar para a pesquisa">
        <span class="text-white"><div class="link-back-text">Voltar para a pesquisa</div></span>
      </a>
    </div>
    <div class="name-title" *ngIf="userName != undefined">Usuário: {{ userName }}</div>
  </div>
</div>

<app-user-role [userRole]="roles" [userName]="userName" *ngIf="!loading && userName != null"></app-user-role>
<app-audit-log *ngIf="!loading && userName != null && roles != null"></app-audit-log>

<app-list-user *ngIf="!loading && roles == null" [usuarios]="usuarios" (usuario)="setUser($event)"></app-list-user>
<app-loading *ngIf="loading" [backgroundBlue]="true"></app-loading>

