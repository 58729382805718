import {Component, EventEmitter, Input, Output} from '@angular/core';
import {HelperService} from '../../../core/services/helper.service';
import {NavigationService} from '../../../core/services/navigationService';

@Component({
  selector: 'app-search-by-email',
  templateUrl: './search-by-email.component.html'
})
export class SearchByEmailComponent {
  @Output() searchByEmailSubmit: EventEmitter<String> = new EventEmitter();
  title = '';
  searchMask = 'Digite um e-mail';

  constructor(public helperService: HelperService,
              private navigationService: NavigationService) {
  }

  @Input()
  set titleValue(value: string) {
    this.title = value;
  }

  onSubmit(val: String): void {
    this.navigationService.populate('/search-debtor-by-email', '');
    this.searchByEmailSubmit.emit(String(val));
  }

}
