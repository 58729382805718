<div class="modal-header">
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-loading [backgroundBlue]="true" *ngIf="loading"></app-loading>
  <table class="table-blue" *ngIf="!loading">
    <thead>
    <tr>
      <th colspan="5"></th>
    </tr>
    <tr>
      <th>Chave</th>
      <th>Usuário</th>
      <th>Senha</th>
      <th>URL</th>
      <th></th>
    </tr>
    <tr></tr>
    </thead>
    <tbody *ngIf="systemUserList != null">
    <ng-container *ngFor="let userInfo of systemUserList; let i = index">
      <tr>
        <td><input class="form-control" type="text" [value]="userInfo.chave || ''" maxlength="255" #chave
                   (blur)="chaveBlur(i, chave)"></td>
        <td><input class="form-control" type="text" [value]="userInfo.usuario || ''" maxlength="255" #usuario
                   (blur)="usuarioBlur(i, usuario)"></td>
        <td><input class="form-control" type="string" [value]="userInfo.senha || ''" #senha
                   (blur)="senhaBlur(i, senha)"></td>
        <td><input class="form-control" type="string" [value]="userInfo.url || ''" #url
                   (blur)="urlBlur(i, url)"></td>
        <td><a href="javascript:void(0)" (click)="delete(i)"><img class="icon-large remove-icon" src="assets/svg/icons/remover.svg" alt="Excluir"></a></td>
      </tr>
      <tr *ngIf="isInvalid[i]">
        <td colspan="7" class="warn"><small>Dados inválidos</small></td>
      </tr>
    </ng-container>
    <tr *ngIf="systemUserList.length == 0">
      <td colspan="7">Nenhum registro encontrado</td>
    </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <app-button-edit [inverted]="true" (onclick)="saveClick()" label="Ok, confirmar"></app-button-edit>
  <app-button-edit [inverted]="true" [leftBuffer]="true" [primary]="false" (onclick)="insertClick()" label="Incluir chave"></app-button-edit>
</div>
