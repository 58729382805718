<app-loading *ngIf="loading" [backgroundBlue]="true"></app-loading>
<form [formGroup]="systemForm">
  <div class="triangulo-para-cima"></div>
  <div class="container">
    <div class="partner-container">
      <div class="row">
        <div class="data-title">Nome:</div>
        <div class="data-value text-capitalize"><input class="form-control form-input" formControlName="nome"
                                                       maxlength="255" type="text"></div>
        <div
          *ngIf="systemForm.controls.nome.invalid && (systemForm.controls.nome.dirty || systemForm.controls.nome.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="systemForm.controls.nome.value !== ''">O valor do campo está inválido</li>
            <li *ngIf="systemForm.controls.nome.value === ''">O valor do campo é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="data-title">Identificador:</div>
        <div class="data-value text-capitalize"><input class="form-control form-input" formControlName="identificador"
                                                       maxlength="255" type="text"></div>
        <div
          *ngIf="systemForm.controls.identificador.invalid && (systemForm.controls.identificador.dirty || systemForm.controls.identificador.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="systemForm.controls.identificador.value !== ''">O valor do campo está inválido</li>
            <li *ngIf="systemForm.controls.identificador.value === ''">O valor do campo é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="data-title">Bean:</div>
        <div class="data-value text-capitalize"><input class="form-control form-input" formControlName="bean"
                                                       type="text"></div>
        <div
          *ngIf="systemForm.controls.bean.invalid && (systemForm.controls.bean.dirty || systemForm.controls.bean.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="systemForm.controls.bean.value !== ''">O valor do campo está inválido</li>
            <li *ngIf="systemForm.controls.bean.value === ''">O valor do campo é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="data-title">Status:</div>
        <div class="data-value">
          <select class="form-control form-input" formControlName="status" name="status">
            <option value="0">Inativo</option>
            <option value="1">Ativo</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="data-title">MS URL:</div>
        <div class="data-value text-capitalize"><input class="form-control form-input" formControlName="msUrl"
                                                       maxlength="255" type="text"></div>
        <div
          *ngIf="systemForm.controls.msUrl.invalid && (systemForm.controls.msUrl.dirty || systemForm.controls.msUrl.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="systemForm.controls.msUrl.value !== ''">O valor do campo está inválido</li>
            <li *ngIf="systemForm.controls.msUrl.value === ''">O valor do campo é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="data-title">Imagem:</div>
        <div class="data-value text-capitalize"><input class="form-control form-input" formControlName="imageUrl"
                                                       maxlength="255" type="text"></div>
        <div
          *ngIf="systemForm.controls.imageUrl.invalid && (systemForm.controls.imageUrl.dirty || systemForm.controls.imageUrl.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="systemForm.controls.imageUrl.value !== ''">O valor do campo está inválido</li>
            <li *ngIf="systemForm.controls.imageUrl.value === ''">O valor do campo é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="data-title">Parceiro:</div>
        <div class="data-value text-capitalize">
          <ng-multiselect-dropdown (onDeSelect)="onDeSelect($event)" (onSelect)="onSelect($event)" [data]="creditorList"
                                   formControlName="creditorList" [settings]="dropdownSettings"
                                   placeholder="Selecione"></ng-multiselect-dropdown>
        </div>
      </div>
      <div class="row top-buffer btn-edit-container">
        <app-button-edit (onclick)="onSubmit()" [disabled]="loading" label="Ok, Confirmar"></app-button-edit>
        <app-button-edit (onclick)="onCancel()" [leftBuffer]="true" [primary]="false"
                         label="Cancelar Edições"></app-button-edit>
      </div>
    </div>
  </div>
</form>

