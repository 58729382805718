import {Component, OnInit} from '@angular/core';
import {DebtorService} from '../../../core/services/debtor.service';
import {ActivatedRoute} from '@angular/router';
import { CpfInfoResponse } from '../../../models/domain';
import { HelperService } from '../../../core/services/helper.service';

@Component({
  selector: 'app-search-cpf-by-hash',
  templateUrl: './search-cpf-by-hash.component.html',
  styleUrls: ['./search-cpf-by-hash.component.scss']
})
export class SearchCpfByHashComponent implements OnInit {

  searchTitle = 'Consulte CPFs por HASH';
  showMenu = false;
  loading = false;
  content: string = "";
  cpfInfoResponse: CpfInfoResponse[] = [];

  labelPrevious = 'Ant.';
  labelNext = 'Próx.';

  size = 20;
  page = 1;

  constructor(private debtorService: DebtorService,
              private activatedRoute: ActivatedRoute,
              private helperService: HelperService) {
  }

  public setHash(value: string) {
    this.content = value;
    this.loading = true;
    this.debtorService.cpfToHash(this.content).subscribe(responseHash => {
      this.loading = false;
      this.cpfInfoResponse = responseHash;
    }, err => {
      this.helperService.showError(err.error.message);
      this.loading = true;
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      if (params.get('hash') != null) {
        this.setHash(String(params.get('hash')));
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    });
  }

  tabSize($event: number): void {
    this.page = $event;
  }
}
