export class InstallmentStatusConverter {
    private static readonly mapping = new Map<number, string>([
        [0, "Cancelado"],
        [1, "Aguardando geração do boleto"],
        [2, "Aguardando o envio do boleto"],
        [3, "Aguardando o pagamento"],
        [4, "Pago"],
        [5, "Em análise"],
        [6, "Em processamento"],
        [7, "Externo"],
        [10, "Cartão de crédito pendente"],
        [11, "Boleto liberado sem registro"],
        [12, "Boleto liberado aguardando registro"]
    ]);

    static convert(value: number): string {
        return InstallmentStatusConverter.mapping.get(value) || "Status não conhecido";
    }
}