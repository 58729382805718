import {Component} from '@angular/core';
import {ActivationsInfo} from '../../../models/domain';
import {ActivationsService} from '../../../core/services/activations.service';

@Component({
  selector: 'app-consulta-activations',
  templateUrl: './consulta-activations.component.html',
  styleUrls: ['./consulta-activations.component.scss']
})

export class ConsultaActivationsComponent {
  titleSearch = 'Consulte os acionamentos de um CPF, número de telefone ou e-mail';
  placeHolderSearch = 'CPF, número de telefone ou e-mail';

  cpfOrContact = '';
  loading = false;
  activations: ActivationsInfo[] | undefined;

  constructor(private activationsService: ActivationsService) {
  }

  searchSubmit(val: string): void {
    this.cpfOrContact = val;
    this.loading = true;
    this.activationsService.searchActivations(this.cpfOrContact).subscribe(activationsList => {
      this.loading = false;
      this.activations = activationsList;
    }, error => {
      this.loading = false;
    });
  }
}
