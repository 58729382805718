import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewPrivacyPolicyComponent } from '../view-privacy-policy/view-privacy-policy.component';

@Component({
  selector: 'app-list-privacy-policy',
  templateUrl: './list-privacy-policy.component.html',
  styleUrls: ['./list-privacy-policy.component.scss'],
})
export class ListPrivacyPolicyComponent {
  @Input() policies: any;
  @Output() onedit = new EventEmitter<any>();
  page = 1;
  size = 10;

  constructor(private modalService: NgbModal) {}

  viewContent(policy: any) {
    const modalRef = this.modalService.open(ViewPrivacyPolicyComponent, {
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.policy = policy;
  }

  tabSize($event: number): void {
    this.page = $event;
  }
}
