import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MaskPipe } from 'ngx-mask';
import { HelperService } from '../../../core/services/helper.service';
import CpfUtils from '../../../core/services/utils/cpf.utils';
import { GenerateLgpdReportRequest, LgpdReportResponse } from '../../../models/domain';
import { SendDataService } from '../../../core/services/send-data.service';

@Component({
  selector: 'app-send-data',
  templateUrl: './send-data.component.html',
  styleUrls: ['./send-data.component.scss']
})

export class SendDataComponent implements OnInit {

  size = 10;
  page = 1;

  sendData: LgpdReportResponse[] = [];

  constructor(private formBuilder: FormBuilder, private mask: MaskPipe,
              private helperService: HelperService, private sendDataService: SendDataService) {
  }

  generateLgpdReportRequest = this.formBuilder.group({
    document: ["", [Validators.required, Validators.min(11)]],
    emailToSendPubSub: ["", [Validators.required]],
    userEmail: [""],
    phone: [""],
  });

  ngOnInit(): void {
    this.getLastDayReports();
  }

  onSubmit(): void {
    const sendData = this.generateLgpdReportRequest.value;
    if (CpfUtils.isValid(sendData.document) && sendData.emailToSendPubSub != '') {
      sendData.document = this.mask.transform(sendData.document, '000.000.000-00');
      this.postSendParametersToAnubis(sendData);
    } else {
      this.helperService.showError(`Esse CPF: ${sendData.document} não é valido`);
    }
  }

  postSendParametersToAnubis(sendData: GenerateLgpdReportRequest): void {
    this.sendDataService.sendParametersToAnubis(sendData).subscribe(response => {
      this.helperService.showMessage("Adicionado a fila");
      this.getLastDayReports();
    }, err => {
      this.helperService.showError(err.error.message);
    });
  }

  getLastDayReports(): void {
    this.sendDataService.getLastDayReports().subscribe(sendDataToRowResponse => {
      this.sendData = sendDataToRowResponse;
    });
  }

  tabSize($event: number): void {
    this.page = $event;
  }
}