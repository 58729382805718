<div class="modal-header">
  <h4 class="modal-title">Políticas de Cálculo</h4>
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="InstallmentForm">
    <app-loading *ngIf="loading" [title]="loadingTitle" [backgroundBlue]="true"></app-loading>
    <div>
      <div>
        <div class="row">
          <div class="data-title-light">Parcela:</div>
          <div class="data-value-light" *ngIf="InstallmentForm.controls.parcela != null"><app-input-varchar [maxLength]="10" className="form-input-light" formControlName="parcela"></app-input-varchar></div>
          <div *ngIf="InstallmentForm.controls.parcela.invalid && (InstallmentForm.controls.parcela.dirty || InstallmentForm.controls.parcela.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="InstallmentForm.controls.parcela.value !== ''">Campo Parcela está inválido</li>
              <li *ngIf="InstallmentForm.controls.parcela.value === ''">Campo Parcela é obrigatório</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="data-title-light">Percentual Desconto Mínimo:</div>
          <div class="data-value-light" *ngIf="InstallmentForm.controls.percentualDescontoMinimo != null"><app-input-decimal [maxLength]="9" className="form-input-light" formControlName="percentualDescontoMinimo"></app-input-decimal></div>
          <div *ngIf="InstallmentForm.controls.percentualDescontoMinimo.invalid && (InstallmentForm.controls.percentualDescontoMinimo.dirty || InstallmentForm.controls.percentualDescontoMinimo.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="InstallmentForm.controls.percentualDescontoMinimo.value !== ''">Campo Percentual Desconto Mínimo está inválido</li>
              <li *ngIf="InstallmentForm.controls.percentualDescontoMinimo.value === ''">Campo Percentual Desconto Mínimo é obrigatório</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="data-title-light">Percentual Desconto Máximo:</div>
          <div class="data-value-light" *ngIf="InstallmentForm.controls.percentualDescontoMaximo != null"><app-input-decimal [maxLength]="9" className="form-input-light" formControlName="percentualDescontoMaximo"></app-input-decimal></div>
          <div *ngIf="InstallmentForm.controls.percentualDescontoMaximo.invalid && (InstallmentForm.controls.percentualDescontoMaximo.dirty || InstallmentForm.controls.percentualDescontoMaximo.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="InstallmentForm.controls.percentualDescontoMaximo.value !== ''">Campo Percentual Desconto Máximo está inválido</li>
              <li *ngIf="InstallmentForm.controls.percentualDescontoMaximo.value === ''">Campo Percentual Desconto Máximo é obrigatório</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="data-title-light">Percentual Desconto Principal:</div>
          <div class="data-value-light" *ngIf="InstallmentForm.controls.percentualDescontoPrincipal != null"><app-input-decimal [maxLength]="9" className="form-input-light" formControlName="percentualDescontoPrincipal"></app-input-decimal></div>
          <div *ngIf="InstallmentForm.controls.percentualDescontoPrincipal.invalid && (InstallmentForm.controls.percentualDescontoPrincipal.dirty || InstallmentForm.controls.percentualDescontoPrincipal.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="InstallmentForm.controls.percentualDescontoPrincipal.value !== ''">Campo Percentual Desconto Principal está inválido</li>
              <li *ngIf="InstallmentForm.controls.percentualDescontoPrincipal.value === ''">Campo Percentual Desconto Principal é obrigatório</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="data-title-light">Percentual Multa:</div>
          <div class="data-value-light" *ngIf="InstallmentForm.controls.percentualMulta != null"><app-input-decimal [maxLength]="9" className="form-input-light" formControlName="percentualMulta"></app-input-decimal></div>
          <div *ngIf="InstallmentForm.controls.percentualMulta.invalid && (InstallmentForm.controls.percentualMulta.dirty || InstallmentForm.controls.percentualMulta.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="InstallmentForm.controls.percentualMulta.value !== ''">Campo Percentual Multa está inválido</li>
              <li *ngIf="InstallmentForm.controls.percentualMulta.value === ''">Campo Percentual Multa é obrigatório</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="data-title-light">Percentual Juros em Atraso:</div>
          <div class="data-value-light" *ngIf="InstallmentForm.controls.percentualJurosAtraso != null"><app-input-decimal [maxLength]="9" className="form-input-light" formControlName="percentualJurosAtraso"></app-input-decimal></div>
          <div *ngIf="InstallmentForm.controls.percentualJurosAtraso.invalid && (InstallmentForm.controls.percentualJurosAtraso.dirty || InstallmentForm.controls.percentualJurosAtraso.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="InstallmentForm.controls.percentualJurosAtraso.value !== ''">Campo Percentual Juros em Atraso está inválido</li>
              <li *ngIf="InstallmentForm.controls.percentualJurosAtraso.value === ''">Campo Percentual Juros em Atraso é obrigatório</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="data-title-light">Percentual Juros Financiamento:</div>
          <div class="data-value-light" *ngIf="InstallmentForm.controls.percentualJurosFinanciamento != null"><app-input-decimal [maxLength]="9" className="form-input-light" formControlName="percentualJurosFinanciamento"></app-input-decimal></div>
          <div *ngIf="InstallmentForm.controls.percentualJurosFinanciamento.invalid && (InstallmentForm.controls.percentualJurosFinanciamento.dirty || InstallmentForm.controls.percentualJurosFinanciamento.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="InstallmentForm.controls.percentualJurosFinanciamento.value !== ''">Campo Percentual Juros Financiamento está inválido</li>
              <li *ngIf="InstallmentForm.controls.percentualJurosFinanciamento.value === ''">Campo Percentual Juros Financiamento é obrigatório</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="data-title-light">Percentual Comissão:</div>
          <div class="data-value-light" *ngIf="InstallmentForm.controls.percentualComissao != null"><app-input-decimal [maxLength]="9" className="form-input-light" formControlName="percentualComissao"></app-input-decimal></div>
          <div *ngIf="InstallmentForm.controls.percentualComissao.invalid && (InstallmentForm.controls.percentualComissao.dirty || InstallmentForm.controls.percentualComissao.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="InstallmentForm.controls.percentualComissao.value !== ''">Campo Percentual Comissão está inválido</li>
              <li *ngIf="InstallmentForm.controls.percentualComissao.value === ''">Campo Percentual Comissão é obrigatório</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="data-title-light">Percentual Entrada Mínima:</div>
          <div class="data-value-light" *ngIf="InstallmentForm.controls.percentualEntradaMinima != null"><app-input-decimal [maxLength]="9" className="form-input-light" formControlName="percentualEntradaMinima"></app-input-decimal></div>
          <div *ngIf="InstallmentForm.controls.percentualEntradaMinima.invalid && (InstallmentForm.controls.percentualEntradaMinima.dirty || InstallmentForm.controls.percentualEntradaMinima.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="InstallmentForm.controls.percentualEntradaMinima.value !== ''">Campo Percentual Entrada Mínima está inválido</li>
              <li *ngIf="InstallmentForm.controls.percentualEntradaMinima.value === ''">Campo Percentual Entrada Mínima é obrigatório</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="data-title-light">Valor Parcela Mínima:</div>
          <div class="data-value-light" *ngIf="InstallmentForm.controls.valorParcelaMinima != null"><app-input-decimal [maxLength]="9" className="form-input-light" formControlName="valorParcelaMinima"></app-input-decimal></div>
          <div *ngIf="InstallmentForm.controls.valorParcelaMinima.invalid && (InstallmentForm.controls.valorParcelaMinima.dirty || InstallmentForm.controls.valorParcelaMinima.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="InstallmentForm.controls.valorParcelaMinima.value !== ''">Campo Valor Parcela Mínima está inválido</li>
              <li *ngIf="InstallmentForm.controls.valorParcelaMinima.value === ''">Campo Valor Parcela Mínima é obrigatório</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="data-title-light">Dias Simulação Máximo:</div>
          <div class="data-value-light" *ngIf="InstallmentForm.controls.diasSimulacaoMaximo != null"><app-input-varchar [maxLength]="10" className="form-input-light" formControlName="diasSimulacaoMaximo"></app-input-varchar></div>
          <div *ngIf="InstallmentForm.controls.diasSimulacaoMaximo.invalid && (InstallmentForm.controls.diasSimulacaoMaximo.dirty || InstallmentForm.controls.diasSimulacaoMaximo.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="InstallmentForm.controls.diasSimulacaoMaximo.value !== ''">Campo Dias Simulação Máximo está inválido</li>
              <li *ngIf="InstallmentForm.controls.diasSimulacaoMaximo.value === ''">Campo Dias Simulação Máximo é obrigatório</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="data-title-light">Chave:</div>
          <div class="data-value-light" *ngIf="InstallmentForm.controls.chave != null"><app-input-varchar [maxLength]="254" className="form-input-light" formControlName="chave"></app-input-varchar></div>
          <div *ngIf="InstallmentForm.controls.chave.invalid && (InstallmentForm.controls.chave.dirty || InstallmentForm.controls.chave.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="InstallmentForm.controls.chave.value !== ''">Campo Chave está inválido</li>
              <li *ngIf="InstallmentForm.controls.chave.value === ''">Campo Chave é obrigatório</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <app-button-edit [inverted]="true" (onclick)="onSubmit()" [label]="labelSave" ></app-button-edit>
  <app-button-edit [inverted]="true" [leftBuffer]="true" [primary]="false"  (click)="activeModal.close('Close click')" [label]="labelCancel"></app-button-edit>
</div>
