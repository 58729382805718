import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SuspdDivida, SuspdDividaDetalhe, SuspdOpcaoPagamento} from '../../../../models/domain';
import {DebtService} from '../../../../core/services/debt.service';
import {sortBy} from 'sort-by-typescript';

@Component({
  selector: 'app-debt-payment-option',
  templateUrl: './debt-payment-options.component.html',
  styleUrls: ['./debt-payment-options.component.scss']
})
export class DebtPaymentOptionsComponent {

  debt?: SuspdDivida;
  debtPaymentOptionList?: SuspdOpcaoPagamento[];
  public debtPaymentOptionListSort: any;
  public sort: any;
  public desc = false;
  public setaSort: any = 'seta-baixo';
  loading = true;

  constructor(public activeModal: NgbActiveModal, private debtService: DebtService) {
  }

  @Input()
  public setDebt(debt: SuspdDivida): void {
    this.debt = debt;
    if (debt.id != null) {
      this.debtService.getPaymentOptionListList(debt.id).subscribe(x => {
        this.loading = false;
        this.debtPaymentOptionList = x;
        this.debtPaymentOptionListSort = this.debtPaymentOptionList;
      }, err => {
        this.loading = false;
      });
    }
  }

  sortTable(sort: string): void {
    if(this.sort === sort && this.desc === false){
      this.desc = true;
      this.setaSort = 'seta-cima';
    }else{
      this.desc = false;
      this.setaSort = 'seta-baixo';
    }
    this.sort = sort;
    if(this.desc){
      this.debtPaymentOptionListSort.sort(sortBy('-'.concat(sort)));
    }else{
      this.debtPaymentOptionListSort.sort(sortBy(sort));
    }
  }
}
