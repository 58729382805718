import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  private shouldHideTheSubMenu$ = new Subject<boolean>();

  constructor(private toastr: ToastrService) {
    toastr.toastrConfig.positionClass = 'toast-top-center';
    toastr.toastrConfig.preventDuplicates = true;
    toastr.toastrConfig.maxOpened = 2;
  }

  get shouldHideSubMenuObservable(): Observable<boolean> {
    return this.shouldHideTheSubMenu$.asObservable();
  }

  public hideSubMenu(): void {
    this.shouldHideTheSubMenu$.next(true);
  }

  public showSubMenu(): void {
    this.shouldHideTheSubMenu$.next(false);
  }

  date(date: any = new Date()): moment.Moment {
    return moment(date)
      .parseZone().utc()
      .locale(window.navigator.language);
  }

  public showError(msg: string): void {
    this.toastr.warning(msg, undefined, {timeOut: 10000, extendedTimeOut: 10000, progressBar: true});
  }

  public showMessage(msg: string): void {
    this.toastr.success(msg, undefined, {timeOut: 3500, extendedTimeOut: 3500, progressBar: true});
  }

  public normalizeStringU(str: string | null | undefined): string {
    return this.normalizeString(str).toUpperCase();
  }

  public normalizeString(str: string | null | undefined): string {
    if (str == null) {
      return '';
    }
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

}
