import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PartnerService } from 'src/app/core/services/partner.service';
import { FiltroPoliticasResponse } from 'src/app/models/response/filtro-politica-response.model';


@Component({
  selector: 'app-installment-filter',
  templateUrl: './installment-filter.component.html',
  styleUrls: ['./installment-filter.component.scss']
})
export class InstallmentFilterComponent implements OnInit {

  Isloading = false;
  credorId!: number;
  installmentFilterValue!: FiltroPoliticasResponse;
  installmentFilter!: FormGroup;
  isDisabled: boolean = true;


  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private partnerService: PartnerService
  ) { }



  ngOnInit() {
    this.installmentFilter = this.formBuilder.group({
      key: [''],
      daysOverdueIni: [],
      daysOverdueEnd: [],
      debtValueIni: [],
      debtValueEnd: [],
    })

    this.installmentFilter.valueChanges.subscribe(() => {
      this.isDisabled = this.isFormEmpty();
    });
  }

  isFormEmpty(): boolean {
    const formValues = this.installmentFilter.value;
    for (const field in formValues) {
      if (formValues.hasOwnProperty(field)) {
        if (formValues[field] !== null && formValues[field] !== '') {
          return false;
        }
      }
    }
    return true;
  }


  getButtonTitle(): string {
    if (this.Isloading === true) {
      return 'Carregando...'
    } else {
      return 'Aplicar filtros'
    }
  }

  @Input()
  public setCredorId(credorId: number) {
    this.credorId = credorId;
  }

  getFilteredPolicies(): void {

    const formValues = this.installmentFilter.value;
    this.installmentFilterValue = {
      key: formValues.key,
      daysOverdueIni: parseFloat(formValues.daysOverdueIni),
      daysOverdueEnd: parseFloat(formValues.daysOverdueEnd),
      debtValueIni: parseFloat(formValues.debtValueIni),
      debtValueEnd: parseFloat(formValues.debtValueEnd)
    };

    this.partnerService.filterPolicies(this.credorId, this.installmentFilterValue).subscribe(x => {
      this.Isloading = true;
      if (formValues.key === '') {
        this.activeModal.close(x);
        return;
      }

      if (x.length > 0) {
        this.activeModal.close(x);
      } else {
        this.installmentFilter.controls['key'].setErrors({ invalidKey: true });
        this.Isloading = false;
        this.isDisabled = true;
      }
    });

  }

  cleanFilter() {
    this.installmentFilter.reset()
    this.Isloading = false;
  }
}
