import { Component, Input, OnInit } from '@angular/core';
import { AuditLog, AuditDetailLog } from '../../../models/domain';
import { AuditLogService } from '../../../core/services/audit-log.service';
import { sortBy } from 'sort-by-typescript';
import CustomerUtils from '../../../core/services/utils/customes.utils';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit {
  @Input() isCustomer: boolean = false;

  auditLog?: AuditLog[];
  public auditLogSort: any;
  public sort: any;
  public desc = false;
  public setaSort: any = 'seta-baixo';

  @Input() size = 10;
  @Input() page = 1;

  labelPrevious = 'Ant.';
  labelNext = 'Próx.';

  constructor(public auditLogService: AuditLogService) {
  }

  ngOnInit(): void {
    this.auditLogService.auditLogEventObservable().subscribe(x => {
      if (x == null) {
        this.auditLog = undefined;
      } else {
        if (this.auditLog == null) {
          this.auditLog = x;
          this.auditLogSort = this.auditLog;
        } else {
          this.auditLog.push(...x);
        }
      }
    });
  }

  sortTable(sort: string, sort2: string): void {
    if (this.sort === sort && this.desc === false) {
      this.desc = true;
      this.setaSort = 'seta-cima';
    } else {
      this.desc = false;
      this.setaSort = 'seta-baixo';
    }
    this.sort = sort;
    if (this.desc) {
      if (sort2 === '') {
        this.auditLogSort.sort(sortBy('-'.concat(sort)));
      } else {
        this.auditLogSort.sort(sortBy('-'.concat(sort), '-'.concat(sort2)));
      }

    } else {
      if (sort2 === '') {
        this.auditLogSort.sort(sortBy(sort));
      } else {
        this.auditLogSort.sort(sortBy(sort, sort2));
      }
    }
  }

  tabSize($event: number): void {
    this.page = $event;
  }

  getDirtyAgreementId(log: AuditLog): string {
    if (log.entityName == 'SuspdAcordo') {
      return log.registryId;
    }
    const columnValue = this.getColumValueFromLogDetails(log.detail);
    if (columnValue != undefined && columnValue != "-") {
      return CustomerUtils.filterOnlyNumbers(columnValue);
    } else {
      return String(columnValue);
    }
  }


  getColumValueFromLogDetails(detail: AuditDetailLog[]): string | undefined {
    let resultValue;
    for (let values of detail) {
      if(values.columnName == 'acordo')
          resultValue = values.columnValue
      else
        resultValue = '-';
    }
    return resultValue;
  }

  getInstallment(log: AuditLog): string {
    if (log.entityName == 'SuspdAcordoParcela') {
      return log.registryId;
    } else {
      return '-';
    }
  }
}
