import {Component, Input} from '@angular/core';
import {ActivationsInfo} from '../../../models/domain';
import {sortBy} from 'sort-by-typescript';

@Component({
  selector: 'app-detalhe-activations',
  templateUrl: './detalhe-consulta-activations.component.html',
  styleUrls: ['./detalhe-consulta-activations.component.scss']
})

export class DetalheConsultaActivationsComponent {

  @Input() activationsList: ActivationsInfo[] | undefined;

  @Input() size = 10;
  @Input() page = 1;
  public sort: any;
  public desc = false;
  public setaSort: any = 'seta-baixo';

  labelPrevious = 'Ant.';
  labelNext = 'Próx.';

  tabSize($event: number): void {
    this.page = $event;
  }

  sortTable(sort: string): void {
    if (this.activationsList != null) {
      if (this.sort === sort && this.desc === false) {
        this.desc = true;
        this.setaSort = 'seta-cima';
      } else {
        this.desc = false;
        this.setaSort = 'seta-baixo';
      }
      this.sort = sort;
      if (this.desc) {
        this.activationsList.sort(sortBy('-'.concat(sort)));
      } else {
        this.activationsList.sort(sortBy(sort));
      }
    }
  }

}
