import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SuspdCredor, SuspdPoliticaParcelamento} from '../../../models/domain';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {sortBy} from 'sort-by-typescript';
import {InstallmentDetailListEditComponent} from '../modals/installment-detail-list-edit/installment-detail-list-edit.component';
import {InstallmentListComponent} from '../modals/installment-list/installment-list.component';
import { InstallmentFilterComponent } from '../modals/installment-filter/installment-filter.component';
import {PartnerService} from '../../../core/services/partner.service';
import {ActivatedRoute} from '@angular/router';
import {ConfirmComponent} from '../../shared/confirm/confirm.component';
import {HelperService} from '../../../core/services/helper.service';

@Component({
  selector: 'app-installment-calc-list',
  templateUrl: './installment-detail-list.component.html',
  styleUrls: ['./installment-detail-list.component.scss']
})
export class InstallmentDetailListComponent {

  public installmentCalcList?: Array<SuspdPoliticaParcelamento>;
  public installmentCalcListSort: any;
  public suspdCredor?: SuspdCredor;
  public sort: any;
  public desc = false;
  public setaSort: any = 'seta-baixo';
  @Output() onBatchUpdate = new EventEmitter<any>();
  @Output() onInstallmentPolicyUpdate = new EventEmitter<any>();
  loading = true;
  showResultFilter = false;
  oldInstallmentCalcList: any;

  size = 10;
  page = 1;

  labelPrevious = 'Ant.';
  labelNext = 'Próx.';

  constructor(private modalService: NgbModal,
              private partnerService: PartnerService,
              private activatedRoute: ActivatedRoute,
              private helperService: HelperService) {
  }

  @Output() onSaveSuspdPoliticaParcelamento = new EventEmitter<any>();

  @Input()
  set creditor(suspdCredor: SuspdCredor | null) {
    if (suspdCredor !=  null) {
      this.suspdCredor = suspdCredor;
    }else{
      this.suspdCredor = undefined;
    }
  }

  @Input()
  set politicCalcListValue(politicList: SuspdPoliticaParcelamento[]) {
    if (politicList !== undefined) {
      this.installmentCalcList = [];
      this.installmentCalcList = politicList;
      this.installmentCalcListSort = politicList;
      this.loading = false;
    }
  }

  showModalEdit(politicCalc: SuspdPoliticaParcelamento): void {
    const modalRef = this.modalService.open(InstallmentDetailListEditComponent);
    modalRef.shown.subscribe(x => {
      modalRef.componentInstance.setPoliticCalcListValue(politicCalc);
    });
    modalRef.closed.subscribe(x => {
      if (x === true){
        this.onSaveSuspdPoliticaParcelamento.emit();
      }
    });
  }

  showModalInstallment(politicCalc: SuspdPoliticaParcelamento): void {
    const modalRef = this.modalService.open(InstallmentListComponent, {windowClass: 'large-modal', backdrop: 'static'});
    modalRef.shown.subscribe(x => {
      modalRef.componentInstance.setPoliticCalcListValue(politicCalc);
    });
    modalRef.closed.subscribe(x => {

    });
  }

  showModalCreateNew(): void {
    const modalRef = this.modalService.open(InstallmentDetailListEditComponent);
    modalRef.shown.subscribe(x => {
      modalRef.componentInstance.setNewPoliticCalcListValue(this.suspdCredor);
    });
    modalRef.closed.subscribe(x => {
      if (x === true){
        this.onSaveSuspdPoliticaParcelamento.emit();
      }
    });
  }

  showModalFilter(): void {
    const modalRef = this.modalService.open(InstallmentFilterComponent);
    modalRef.shown.subscribe(() => {
      modalRef.componentInstance.setCredorId(this.suspdCredor?.id);
    });
    modalRef.result.then(
      (result) => {
        this.oldInstallmentCalcList = this.installmentCalcList;
        this.installmentCalcList = result;
        this.showResultFilter = true;
      }
    )
  }

  sortTable(sort: string): void {
    if (this.sort === sort && this.desc === false){
      this.desc = true;
      this.setaSort = 'seta-cima';
    }else{
      this.desc = false;
      this.setaSort = 'seta-baixo';
    }
    this.sort = sort;
    if (this.desc){
      this.installmentCalcListSort.sort(sortBy('-'.concat(sort)));
    }else{
      this.installmentCalcListSort.sort(sortBy(sort));
    }
  }

  tabSize($event: number): void {
    this.page = $event;
  }

  deleteClick(suspdPoliticaParcelamento: SuspdPoliticaParcelamento): void {
    if (this.loading) {
      return;
    }
    const modalRef = this.modalService.open(ConfirmComponent, {windowClass: 'large-modal'});
    modalRef.componentInstance.pergunta = `Confirma exclusão de Parcelamento ${suspdPoliticaParcelamento.chave}?`;
    modalRef.closed.subscribe(x => {
      if (x) {
        this.loading = true;
        if (suspdPoliticaParcelamento.id != null) {
          this.partnerService.deleteSuspdPoliticaParcelamento(suspdPoliticaParcelamento.id).subscribe(x => {
            this.loading = false;
            this.helperService.showMessage('Excluído com sucesso!');
            const credorId: number = Number(suspdPoliticaParcelamento?.suspdCredor?.id);
            this.onSaveSuspdPoliticaParcelamento.emit();
          });
        }
      }
    });
  }

  showBatchUpdate(): void {
    this.onBatchUpdate.emit();
  }

  showInstallmentPolicyUpdate(): void {
    this.onInstallmentPolicyUpdate.emit();
  }

  showButtonInstallmentPolicy(): boolean {
    if (this.suspdCredor?.identificador == "hoepers") {
      return true;
    } else {
      return false;
    }
  }

  removeFilter(): void {
    this.installmentCalcList = this.oldInstallmentCalcList;
    this.showResultFilter = false;
  }
}
