import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  @Output() searchSubmit: EventEmitter<string> = new EventEmitter();
  searchForm = this.formBuilder.group({
    value: ''
  });
  @Input() searchTitle = '';
  @Input() large = false;
  @Input() medium = false;
  @Input() searchPlaceholder = '';
  @Input() searchMask = '';
  @Input() canSubmitEmpty = false;

  constructor(
    private formBuilder: FormBuilder,
  ) {
  }

  onSubmit(): void {
    const form = this.searchForm.value;
    if (form.value !== '' || this.canSubmitEmpty) {
      this.searchSubmit.emit(form.value);
    }
  }

}
