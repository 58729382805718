import { Component, OnInit } from '@angular/core';
import { SuspdCredor, BlockConvert, Devedor, BlockRequest } from '../../../models/domain';
import { CreditorService } from '../../../core/services/creditor.service';
import { DebtBlockingService } from '../../../core/services/debt-blocking.service';
import { HelperService } from '../../../core/services/helper.service';
import { MaskPipe } from 'ngx-mask';
import { FormBuilder, Validators } from '@angular/forms';
import CpfUtils from '../../../core/services/utils/cpf.utils';

@Component({
  selector: 'app-form-debt-blocking',
  templateUrl: './form-debt-blocking.component.html',
  styleUrls: ['./form-debt-blocking.component.scss']
})
export class FormDebtBlockingComponent implements OnInit {

  size = 10;
  page = 1;

  creditors: SuspdCredor[] = [];
  blocks: BlockConvert[] = [];
  debtorValue: Devedor;

  constructor(private creditorService: CreditorService, private debtBlockingService: DebtBlockingService,
    private formBuilder: FormBuilder, private mask: MaskPipe, private helperService: HelperService) {
    this.debtorValue = new Devedor();
  }

  blockRequestForm = this.formBuilder.group({
    document: ["", [Validators.required, Validators.min(11)]],
    creditorId: ["", [Validators.required]],
    reason: ["", [Validators.required]],
  });

  ngOnInit(): void {
    this.getCreditors();
    this.getBlock();
  }

  onSubmit(): void {
    const blockRequest = this.blockRequestForm.value;
    if (CpfUtils.isValid(blockRequest.document)) {
      blockRequest.document = this.mask.transform(blockRequest.document, '000.000.000-00');
      this.postIncludeToBlock(blockRequest);
    } else {
      this.helperService.showError(`Esse CPF: ${blockRequest.document} não é valido`);
    }
  }

  postIncludeToBlock(blockRequest: BlockRequest): void {
    this.debtBlockingService.includeToBlock(blockRequest).subscribe(blockResponse => {
      this.getBlock();
      this.helperService.showMessage(`CPF: ${blockRequest.document} foi adicionado a lista de bloqueio`);
    });
  }

  getCreditors() {
    this.creditorService.getCreditors().subscribe(creditors => {
      this.creditors = creditors;
    });
  }

  removeFromBlock(block: BlockConvert) {
    this.debtBlockingService.removeFromBlock(block.id).subscribe(blockResponse => {
      this.helperService.showMessage(`CPF: ${blockResponse.customerId} foi removido da lista de bloqueio`);
      this.getBlock();
    })
  }

  getBlock() {
    this.debtBlockingService.getBlock().subscribe(response => {
      this.blocks = response;
    });
  }

  tabSize($event: number): void {
    this.page = $event;
  }
}
