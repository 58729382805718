<app-search-by-email [titleValue]="searchTitle" (searchByEmailSubmit)="setEmail($event)" *ngIf="debtor == undefined && showMenu"></app-search-by-email>

<div class="container" *ngIf="debtor != undefined">
  <div class="name-container">
    <div class="list-inline">
      <a href="javascript:void(0)" (click)="goBack()" class="link-back">
        <img class="white-icon icon-large" src="assets/svg/icons/arrow-left.svg" alt="Voltar para a pesquisa">
        <span class="text-white"><div class="link-back-text">Voltar para a pesquisa</div></span>
      </a>
    </div>
    <div class="name-title">{{ debtor.nome }}</div>
  </div>
</div>

<app-loading *ngIf="loading" [backgroundBlue]="true"></app-loading>
<app-debtor [debtor]="debtor" *ngIf="debtor != undefined"></app-debtor>
<app-deal-list [dealListValue]="dealList" *ngIf="dealList != undefined"></app-deal-list>
<app-debt-list [debtListValue]="debtList" [dealList]="dealList" *ngIf="debtList != undefined"></app-debt-list>
<app-audit-log *ngIf="debtor != undefined"></app-audit-log>
<app-pending-search *ngIf="debtor == undefined"></app-pending-search>

