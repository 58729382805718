<div class="container" *ngIf="selection">
  <div class="container-default middle-aligned-container align-items-center">
    <div class="title-search">Bloqueio de dívidas</div>
    <div class="inline-itens">
      <div class="row">
        <button (click)="onClickDebtBlocking()" class="button-default">Bloquear dívidas</button>
      </div>
      <div class="row">
        <button (click)="onClickSearchDebtBlocking()" class="button-default">Buscar dívidas bloqueadas</button>
      </div>
    </div>
  </div>
</div>

<app-form-debt-blocking *ngIf="debtBlocking"></app-form-debt-blocking>
<app-search-debt-blocking *ngIf="searchDebtBlocking"></app-search-debt-blocking>
<app-pending-search *ngIf="selection"></app-pending-search>
