<div class="modal-header">
  <tr>
    <th class="container-title" colspan="8">
      <div class="table-title">Detalhe do acordo #{{deal?.id}} | {{deal?.dividaId?.produto}}</div>
      <div class="table-sub-title" *ngIf="deal?.devedorId?.socialName != null">Realizado por
        {{deal?.devedorId?.socialName}}, no dia {{deal?.dividaId?.dataSimulacao | date:'dd/MM/yyyy' }}.</div>
      <div class="table-sub-title" *ngIf="deal?.devedorId?.socialName === null">Realizado por {{deal?.devedorId?.nome}},
        no dia {{deal?.dividaId?.dataSimulacao | date:'dd/MM/yyyy' }}.</div>
    </th>
  </tr>
  <h4 class="modal-title"></h4><br>
  <h4 class="modal-title"></h4>
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-loading [title]="modalTitle" [backgroundGreen]="true" *ngIf="loading"></app-loading>
  <table class="table-green" *ngIf="!loading">
    <thead>
      <tr>
        <th colspan="8"></th>
      </tr>
      <tr>
        <th class="cursor" (click)="sortTable('id')" scope="col">Parcela ID<span class="{{setaSort}}"></span></th>
        <th class="cursor" scope="col">Sistema</th>
        <th class="cursor" (click)="sortTable('parcela')" scope="col">Parcela<span class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('dataVencimento')" scope="col">Data Vencimento<span
            class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('dataPagamento')" scope="col">Data Pagamento<span
            class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('valorParcela')" scope="col">Valor<span class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('descricaoStatus')" scope="col">Status Parcela<span class="{{setaSort}}"></span></th>
        <th *ngIf="billet" scope="col">Boleto</th>
        <th scope="col">Email</th>
      </tr>
      <tr></tr>
    </thead>
    <tbody *ngIf="installmentList != undefined">
      <tr *ngFor="let installment of installmentList">
        <td>{{ installment.id }}</td>
        <td>{{ deal.sistemaId.identificador }}</td>
        <td>{{ installment.parcela }}</td>
        <td>{{ installment.dataVencimento | date:'dd/MM/yyyy' }}</td>
        <td *ngIf="installmentId != installment.id && installment.dataPagamento == null">
          <a class="lnk" (click)="paymentDateClick(installment)" href="javascript:void(0)">Pendente</a>
        </td>
        <td *ngIf="installmentId != installment.id && installment.dataPagamento != null">
          <a class="lnk" (click)="paymentDateClick(installment)" href="javascript:void(0)">{{ installment.dataPagamento
            | date:'dd/MM/yyyy' }}</a>
          <br>
          <a class="link-remove-payment-date" (click)="removePaymentDate(installment)"
            *ngIf="showRemovePayment(installment, installmentList)" href="javascript:void(0)">Remover Pagamento</a>
        </td>
        <td *ngIf="installmentId==installment.id">
          <app-calendar (dateSelect)="changePaymentDate(installment)" [(ngModel)]="installmentDate"
            [maxDate]="maxPaymentDate" [readOnly]="true"></app-calendar>
        </td>
        <td>{{ installment.valorParcela | currency:'R$' }}</td>
        <td>{{ installment.descricaoStatus }}</td>
        <td *ngIf="billet">
          <a class="lnk" (click)="generateBillet(installment)" href="javascript:void(0)">Boleto</a>
          <br>
          <a class="link-remove-payment-date" (click)="removeBilletCache(installment)"
            *ngIf="installmentId != installment.id && installment.status == statusPagamento"
            href="javascript:void(0)">Remover Cache</a>
        </td>
        <td><a class="lnk" (click)="sendBilletEmail(installment)" href="javascript:void(0)">Email</a></td>
      </tr>
      <tr *ngIf="installmentList.length == 0">
        <td colspan="8">Nenhum registro encontrado</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button (click)="activeModal.close('Close click')" class="btn btn-dark btn-secondary" type="button">Fechar</button>
</div>