import {Component, Input} from '@angular/core';
import {CipInfo} from '../../../models/domain';
import {sortBy} from 'sort-by-typescript';

@Component({
  selector: 'app-detalhe-cip',
  templateUrl: './detalhe-consulta-cip.component.html',
  styleUrls: ['./detalhe-consulta-cip.component.scss']
})

export class DetalheConsultaCipComponent {

  @Input() cip: CipInfo | undefined;
  public sort: any;
  public desc = false;
  public setaSort: any;
  public cips: any;


  sortTable(sort: string): void {
    this.cips = this.cip;
    if(this.sort === sort && this.desc === false){
      this.desc = true;
      this.setaSort = 'seta-cima';
    }else{
      this.desc = false;
      this.setaSort = 'seta-baixo';
    }
    this.sort = sort;
    if(this.desc){
      this.cips.sort(sortBy('-'.concat(sort)));
    }else{
      this.cips.sort(sortBy(sort));
    }
  }

}
