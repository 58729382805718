import { Component, OnInit } from '@angular/core';
import { DebtorService } from '../../../core/services/debtor.service';
import { HelperService } from '../../../core/services/helper.service';
import { ActivatedRoute } from '@angular/router';
import { AuditLogService } from '../../../core/services/audit-log.service';
import { NavigationService } from '../../../core/services/navigationService';
import { DevedorDetalheResponse, DevedorResponse, AcordoResponse, DividaResponse } from '../../../models/response/devedorDetalheResponse.model';
import CpfUtils from '../../../core/services/utils/cpf.utils';

@Component({
  selector: 'app-search-debtor-by-cpf',
  templateUrl: './search-debtor-by-cpf.component.html',
  styleUrls: ['./search-debtor-by-cpf.component.scss']
})
export class SearchDebtorByCpfComponent implements OnInit {

  debtor?: DevedorResponse;
  dealList?: AcordoResponse[];
  debtList?: DividaResponse[];
  searchTitle = 'Consulte os acordos e dívidas de um CPF';
  showMenu = false;
  loading = false;
  private selectedDate: any;

  constructor(private debtorService: DebtorService,
    private activatedRoute: ActivatedRoute,
    private auditLogService: AuditLogService,
    private navigationService: NavigationService,
    private helperService: HelperService) {
  }

  public setCpf(value: number) {
    let cpf: string = CpfUtils.convertToString(value);
    if (CpfUtils.isValid(cpf)) {
      this.loading = true;
      this.debtorService.searchByCpf(cpf).subscribe({
        next: (response) => {
          console.log(response);
          this.loading = false;
          this.readDebtorInfo(response);
        },
        error: () => {
          this.loading = false;
        }
      })
    } else {
      this.helperService.showError('CPF invalido');
    }
  }


  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      if (params.get('cpf') != null) {
        this.setCpf(Number(params.get('cpf')));
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    });

  }

  goBack(): void {
    this.debtor = undefined;
    this.dealList = undefined;
    this.debtList = undefined;
    this.auditLogService.clear();
    this.showMenu = true;
    this.helperService.showSubMenu();
    this.navigationService.back();

  }

  private readDebtorInfo(debtorInfo: DevedorDetalheResponse): void {
    this.debtor = debtorInfo.debtor;
    this.dealList = debtorInfo.deals;
    this.debtList = debtorInfo.debts;
    this.debtorService.loadAuditLogs(debtorInfo.debtor.id);
    this.helperService.hideSubMenu();
  }

}
