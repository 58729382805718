import {Component, EventEmitter, Output} from '@angular/core';
import {CreditorService} from '../../../../core/services/creditor.service';
import {SuspdCredor, SuspdCredorConfiguracao} from '../../../../models/domain';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {getAsDate} from '../../../../core/validator/date.validator';

@Component({
  selector: 'app-edit-partner-configuration',
  templateUrl: './edit-configuration.component.html',
  styleUrls: ['./edit-configuration.component.scss']
})

export class EditConfigurationComponent {

  creditorValue: SuspdCredor | null = null;
  configuration: SuspdCredorConfiguracao[] | null = null;
  loading = false;
  @Output() onsave = new EventEmitter<any>();
  @Output() oncancel = new EventEmitter<any>();
  isInvalid: boolean[] = [];

  constructor(public activeModal: NgbActiveModal, private creditorService: CreditorService) {
  }

  set creditor(creditor: SuspdCredor | null) {
    this.creditorValue = creditor;
    if (creditor != null && creditor.id != null) {
      this.loading = true;
      this.creditorService.loadConfiguration(creditor.id).subscribe(configuration => {
        this.configuration = configuration;
        this.loading = false;
      });
    }
  }

  insertClick(): void {
    if (this.configuration == null) {
      this.configuration = [];
    }
    this.configuration?.push({});
  }

  dateBlur(index: number, value: HTMLInputElement): void {
    if (this.configuration != null) {
      const asDate = getAsDate(value.value);
      if (asDate != null) {
        this.configuration[index].valorDate = asDate;
      } else {
        this.configuration[index].valorDate = undefined;
        value.value = '';
      }
    }
  }

  chaveBlur(index: number, value: HTMLInputElement): void {
    if (this.configuration != null) {
      this.configuration[index].chave = value.value;
    }
  }

  stringBlur(index: number, value: HTMLInputElement): void {
    if (this.configuration != null) {
      if (value.value === '') {
        this.configuration[index].valorString = undefined;
      } else {
        this.configuration[index].valorString = value.value;
      }
    }
  }

  intBlur(index: number, value: HTMLInputElement): void {
    if (this.configuration != null) {
      if (value.value !== '') {
        this.configuration[index].valorInt = parseInt(value.value, 10);
      } else {
        this.configuration[index].valorInt = undefined;
      }
    }
  }

  decimalBlur(index: number, value: HTMLInputElement): void {
    if (this.configuration != null) {
      if (value.value !== '') {
        this.configuration[index].valorDecimal = parseFloat(value.value.replace(/\./g, '').replace(/,/g, '.'));
      } else {
        this.configuration[index].valorDecimal = undefined;
      }
    }
  }

  booleanBlur(index: number, valorBoolean: HTMLInputElement): void {
    if (this.configuration != null) {
      if (valorBoolean.value !== '0' && valorBoolean.value !== '1') {
        this.configuration[index].valorBoolean = undefined;
        valorBoolean.value = '';
      } else {
        this.configuration[index].valorBoolean = parseInt(valorBoolean.value, 10);
      }
    }
  }

  delete(index: number): void {
    if (this.configuration != null) {
      this.configuration.splice(index, 1);
    }
  }

  saveClick(): void {
    if (this.creditorValue != null && this.creditorValue.id != null && this.configuration != null && this.isValid()) {
      this.loading = true;
      this.creditorService.createOrUpdate(this.creditorValue.id, this.configuration).subscribe(x => {
        this.loading = false;
        this.configuration = x;
      });
    }
  }

  isValid(): boolean {
    if (this.configuration != null) {
      this.isInvalid = [];
      this.configuration.forEach((val, index) => {
        this.isInvalid[index] = val.chave == null || val.chave === '' || (
          val.valorBoolean === undefined && val.valorInt === undefined && val.valorDate === undefined
          && val.valorString === undefined && val.valorDecimal === undefined
        );
      });
      return this.isInvalid.filter(x => x).length === 0;
    }
    return false;
  }
}
