import {Component, Input} from '@angular/core';
import {CheckboxControlValueAccessor} from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends CheckboxControlValueAccessor {

  input: any;
  isDisabled = false;

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: any): void {
    this.input = input;
  }

  @Input()
  set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  @Input()
  set checked(isChecked: boolean) {
    this.writeValue(isChecked);
  }

  setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    this.isDisabled = isDisabled;
  }

}
