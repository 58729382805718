import { Component } from '@angular/core';
import { DebtBlockingService } from '../../../core/services/debt-blocking.service';
import { BlockConvert } from '../../../models/domain';
import CpfUtils from '../../../core/services/utils/cpf.utils';

@Component({
  selector: 'app-search-debt-blocking',
  templateUrl: './search-debt-blocking.component.html',
})

export class SearchDebtBlocking {

  blocks: BlockConvert[] = [];
  showTable: boolean = false;
  loading: boolean = false;
  pendingSearch: boolean = true;

  constructor(private debtBlockingService: DebtBlockingService) { }

  getCpf(cpf: number): void {
    this.pendingSearch = false;
    this.loading = true;
    this.debtBlockingService.fetchExistingBlock(CpfUtils.format(CpfUtils.convertToString(cpf))).subscribe(blockResponse => {
      this.blocks = blockResponse;
      this.loading = false;
      this.showTable = true;
    }, error => {
      this.loading = false;
    });
  }
}
