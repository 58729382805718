import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CreditorService} from '../../../core/services/creditor.service';
import {SuspdCredor, SuspdSistema} from '../../../models/domain';
import {FormBuilder, Validators} from '@angular/forms';
import {HelperService} from '../../../core/services/helper.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {ListItem} from 'ng-multiselect-dropdown/multiselect.model';
import {SystemService} from '../../../core/services/system.service';

@Component({
  selector: 'app-edit-system',
  templateUrl: './edit-system.component.html',
  styleUrls: ['./edit-system.component.scss']
})

export class EditSystemComponent implements OnInit {

  systemForm = this.formBuilder.group({
    nome: ['', [Validators.required]],
    identificador: [''],
    bean: ['', [Validators.required]],
    status: ['', [Validators.required]],
    msUrl: [''],
    imageUrl: [''],
    creditorList: ['']
  });

  creditorList: SuspdCredor[] = [];
  systemValue: SuspdSistema | null = null;
  loading = false;
  @Output() onsave = new EventEmitter<SuspdCredor>();
  @Output() oncancel = new EventEmitter<any>();
  dropdownSettings: IDropdownSettings = {
    searchPlaceholderText: '',
    closeDropDownOnSelection: false,
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'nome',
    itemsShowLimit: 10,
    allowSearchFilter: true,
  };

  constructor(private formBuilder: FormBuilder, private creditorService: CreditorService, private systemService: SystemService,
              private helperService: HelperService) {
  }

  @Input()
  set system(system: SuspdSistema | null) {
    this.systemValue = system;
    if (system != null && system.creditorList) {
      this.systemForm.setValue({
        nome: system.nome,
        identificador: system.identificador,
        bean: system.bean,
        status: system.status,
        msUrl: system.msUrl,
        imageUrl: system.imageUrl,
        creditorList: system.creditorList
      });
    } else {
      this.systemForm.reset();
    }
  }

  ngOnInit(): void {
    this.creditorService.getCreditors().subscribe(creditors => {
      this.creditorList = creditors;
    });
  }

  onSubmit(): void {
    if (this.systemForm.valid) {
      const formVal = this.systemForm.value;
      if (formVal.creditorList == null || formVal.creditorList.length === 0) {
        this.helperService.showMessage('Favor selecionar pelo menos um parceiro');
      } else {
        this.loading = true;
        if (this.systemValue != null && this.systemValue.id != null) {
          this.systemService.update(this.systemValue.id, formVal).subscribe(system => {
            this.loading = false;
            this.onsave.emit(system);
          });
        } else {
          this.systemService.create(formVal).subscribe(system => {
            this.loading = false;
            this.onsave.emit(system);
          });
        }
      }
    } else {
      this.helperService.showMessage('Formulário contém erros no preenchimento. Favor verificar.');
    }
  }

  onCancel(): void {
    this.oncancel.emit();
  }

  onDeSelect(listItem: ListItem): void {
  }

  onSelect(listItem: ListItem): void {
  }

}
