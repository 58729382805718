<div class="container">
  <form (ngSubmit)="onSubmit()">
    <div class="search-box middle-aligned-container align-items-center">
      <span class="title-search">Consulte os acordos de uma data específica</span>
      <div class="top-buffer">
        <div class="input-group">
          <div class="input-group-prepend">
              <span class="input-group-text bg-white calendar-image">
                  <img class="icon" src="assets/svg/icons/calendar-date.svg" alt="Calendario">
              </span>
          </div>
          <app-calendar name="test" [fullSize]="true" class="date-input form-control" [(ngModel)]="model"></app-calendar>
          <button type="submit" class="button-search">CONSULTAR</button>
        </div>
      </div>
    </div>
  </form>

  <div class="top-buffer" *ngIf="!loading && customers != null">
    <table class="table-blue">
      <thead>
      <tr><th colspan="8"></th></tr>
      <tr>
        <th class="cursor" (click)="sortTable('id')"><a><div>ID Acordo<span class="{{setaSort}}"></span></div></a></th>
        <th class="cursor" (click)="sortTable('dataAcordo')"><div>Data<span class="{{setaSort}}"></span></div></th>
        <th class="cursor" (click)="sortTable('devedorId.nome')"><div>Consumidor<span class="{{setaSort}}"></span></div></th>
        <th class="cursor" (click)="sortTable('dividaId.cpf')"><div>CPF<span class="{{setaSort}}"></span></div></th>
        <th class="cursor" (click)="sortTable('credorId.representacao')"><div>Parceiro<span class="{{setaSort}}"></span></div></th>
        <th class="cursor" (click)="sortTable('qtdParcela')"><div>Parcelas<span class="{{setaSort}}"></span></div></th>
        <th class="cursor" (click)="sortTable('valorAcordo')"><div>Valor<span class="{{setaSort}}"></span></div></th>
        <th class="cursor" (click)="sortTable('descricaoStatus')"><div>Status Acordo<span class="{{setaSort}}"></span></div></th>
      </tr>
      <tr></tr>
      </thead>
      <tbody>
      <tr *ngFor="let customer of customers| paginate : {
                itemsPerPage: size,
                currentPage: page,
                totalItems: customers.length
              };">
        <td><div>{{ customer.id }}</div></td>
        <td><div>{{ customer.dataAcordo|date:'dd/MM/yyyy':'America/Sao_Paulo' }}</div></td>
        <td *ngIf="customer.socialName == null" class="text-capitalize" ><div>{{ customer.nome }}</div></td>
        <td *ngIf="customer.socialName != null" class="text-capitalize" ><div>{{ customer.socialName }}</div></td>
        <td><div><a class="lnk" href="javascript:void(0)" (click)="showModalDetail(customer.cpf)">{{customer.cpf | mask:'000.000.000-00'}}</a></div></td>
        <td><div class="text-capitalize">{{ customer.sistemaId.nome }}</div></td>
        <td><div>{{ customer.qtdParcela }}</div></td>
        <td ><div>{{ customer.valorAcordo  | currency:'BRL':'R$ ':'1.2-2' }}</div></td>
        <td *ngIf="customer.descricaoStatus === 'Cancelado'"><div><span class="badge badge-pill badge-inativo badge-lg">Quebrado</span></div></td>
        <td *ngIf="customer.descricaoStatus === 'Ativo'"><div><span class="badge badge-pill badge-ativo badge-lg">Ativo</span></div></td>
        <td *ngIf="customer.descricaoStatus === 'Finalizado'"><div><span class="badge badge-pill badge-concluido badge-lg">Quitado</span></div></td>
      </tr>
      <tr *ngIf="customers.length == 0">
        <td colspan="8">Não há resultados para a data {{ selectedDate|date:'dd/MM/yyyy' }}</td>
      </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-center top-buffer">
      <pagination-controls [responsive]="false" [previousLabel]="labelPrevious" [nextLabel]="labelNext"
                           (pageChange)="tabSize($event)">
      </pagination-controls>
    </div>
  </div>
</div>

<app-pending-search *ngIf="!loading && customers == null"></app-pending-search>
<app-loading *ngIf="loading" [title]="loadingTitle" [backgroundBlue]="true"></app-loading>
