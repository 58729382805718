import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HelperService } from 'src/app/core/services/helper.service';
import { JuridicalService } from 'src/app/core/services/juridical.service';
import { LoadService } from 'src/app/tools/load/load.service';
@Component({
  selector: 'app-edit-privacy-policy',
  templateUrl: './edit-privacy-policy.component.html',
  styleUrls: ['./edit-privacy-policy.component.scss'],
})
export class EditPrivacyPolicyComponent implements OnInit {
  @Output() onsave = new EventEmitter<any>();
  @Output() oncancel = new EventEmitter<any>();
  @Input() policy: any;
  @Input() policies: any;

  loading = false;

  form = new FormGroup({
    version: new FormControl('', Validators.required),
    content: new FormControl('', Validators.required),
  });

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '281px',
    width: '100%',
    minWidth: '100%',
    placeholder: 'Insira o conteúdo aqui!',
    translate: 'yeas',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['backgroundColor']],
  };

  constructor(
    private helperService: HelperService,
    private juridicalService: JuridicalService,
    private loadService: LoadService,
  ) {}

  ngOnInit(): void {
    this.generateVersion();
  }

  generateVersion() {
    this.form.get('version')?.disable();
    if (this.policy) {
      let policyParts = this.policy.version.split('.');
      let policiesFiltered = this.policies.filter((item: any) =>
        item.version.match('^' + policyParts[0] + '\\.', 'gm')
      );
      let lastVersion = policiesFiltered.reduce((prev: any, current: any) => {
        return parseFloat(prev.version) > parseFloat(current.version)? prev : current;
      });
      let lastVersionParts = String(lastVersion.version).split('.');
      this.form.get('version')?.setValue(`${Number(lastVersionParts[0])}.${Number(lastVersionParts[1]) + 1}`);
      this.form.get('content')?.setValue(this.policy.content);

    } else {

      if (this.policies.length) {
        let lastVersion = this.policies.reduce((prev: any, current: any) => {
          return parseFloat(prev.version) > parseFloat(current.version)? prev : current;
        });

        let lastVersionParts = String(lastVersion.version).split('.');

        this.form.get('version')?.setValue(`${Number(lastVersionParts[0]) + 1}.0`);

      } else {
        this.form.get('version')?.setValue('1.0');
      }
    }
  }

  onSave(): void {
    this.loadService.addLoad();
    this.juridicalService
      .createPrivacyPolicies(this.form.getRawValue())
      .subscribe(
        (ret) => {
          this.loadService.removeLoad();
          this.helperService.showMessage('Cadastrado com sucesso!');
          this.onsave.emit();
        },
        (err) => {
          this.loadService.removeLoad();
          this.helperService.showMessage('Erro ao cadastrar!');
        }
      );
  }

  onCancel(): void {
    this.oncancel.emit();
  }
}
