import {Component, OnInit} from '@angular/core';
import { PartnerService } from '../../../core/services/partner.service';
import { HelperService } from '../../../core/services/helper.service';
import { HistoryReport } from 'src/app/models/domain';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  public historyResporListSort: any;

  titleSearch = 'Selecione o tipo de arquivo';

  loading = false;
  cpfOrHash: String[] = ["CPF", "HASH"];
  typeFile: string = '';
  historyReport: HistoryReport[] = [];

  loadingTitle = 'Aguarde';
  labelSave = 'OK, CONFIRMAR';
  labelPrevious = 'Ant.';
  labelNext = 'Próx.';

  size = 10;
  page = 1;

  constructor(private partnerService: PartnerService, private helperService: HelperService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.getHistoryReport();
  }

  submitFile(uploadFile: File, userName: string, typeFile: string): void {
    if (uploadFile != null && userName != '' && typeFile != '') {
      const customerReportRequest = new FormData;
      customerReportRequest.append('file', uploadFile);
      customerReportRequest.append('username', userName);
      customerReportRequest.append('type', typeFile);
      this.partnerService.uploadFileReport(customerReportRequest).subscribe(x => {
          this.getHistoryReport();
          this.helperService.showMessage(`Arquivo enviado com sucesso - STATUS: ${x}`);
      }, err => {
          const message = JSON.parse(err.error);
          this.helperService.showError(message.message);
      })
    } else {
      this.helperService.showError(`Por favor preencher todos os campos`);
    }
  }

  selectedType(val: string): void {
    this.typeFile = val;
  }

  getHistoryReport() {
    this.partnerService.listReport().subscribe(reportResponse => {
      this.historyReport = reportResponse;
      this.historyReport.map((i) => {
        if (i.status == "PENDING") i.status = "Pendente";
        if (i.status == "APPROVED") i.status = "Aprovado";
        if (i.status == "DONE") i.status = "Concluído";
      });
    })
  }

  tabSize($event: number): void {
    this.page = $event;
  }

  downloadFileReport(id: number | undefined): void {
    if (id != null && id != undefined) {
      this.partnerService.downloadFileReport(id).subscribe(responseFileReport => {
        const blob = new Blob([responseFileReport.body], {type : "text/csv"});
        var fileName = `relatorio-${id}`;
        var link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        link.click();
      }, err => {
        const message = JSON.parse(err.error);
        this.helperService.showError(message.message)
    })
    }
  }

}
