import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SuspdCredor, SuspdSistema} from '../../../models/domain';
import {AuditLogService} from '../../../core/services/audit-log.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmComponent} from '../../shared/confirm/confirm.component';
import {HelperService} from '../../../core/services/helper.service';
import {EditSystemConfigurationComponent} from '../modals/edit-system-configuration/edit-system-configuration.component';
import {SystemService} from '../../../core/services/system.service';

@Component({
  selector: 'app-list-systems',
  templateUrl: './list-systems.component.html',
  styleUrls: ['./list-systems.component.scss']
})

export class ListSystemsComponent implements OnInit {

  filteredSystemList: SuspdSistema[] = [];
  originalSystemList: SuspdSistema[] = [];
  loading = false;
  @Output() onedit = new EventEmitter<SuspdSistema>();
  @Output() ondelete = new EventEmitter<SuspdSistema>();
  private searchedTerm: string | undefined;
  size = 10;
  page = 1;

  constructor(private systemService: SystemService, private auditService: AuditLogService, private modalService: NgbModal,
              private helperService: HelperService) {
  }

  @Input()
  set searchTerm(val: string | undefined) {
    this.searchedTerm = val;
    this.filter();
  }

  ngOnInit(): void {
    this.loadSystems();
  }

  loadSystems(): void {
    this.loading = true;
    this.systemService.listSystems().subscribe(x => {
      this.loading = false;
      this.originalSystemList = x;
      this.filter();
    });
  }

  filter(): void {
    if (this.searchedTerm != null && this.searchedTerm !== '') {
      const searchTerm = this.helperService.normalizeStringU(this.searchedTerm);
      this.filteredSystemList = this.originalSystemList.filter(system => {
        return this.helperService.normalizeStringU(system.nome).indexOf(searchTerm) > -1 || (system.creditorList != null &&
          system.creditorList.filter(creditor => this.helperService.normalizeStringU(creditor.nome).indexOf(searchTerm) > -1).length > 0) ||
          this.helperService.normalizeStringU(system.identificador).indexOf(searchTerm) > -1;
      });
    } else {
      this.filteredSystemList = this.originalSystemList;
    }
    this.page = 1;
  }

  editClick(system: SuspdSistema): void {
    if (this.loading) {
      return;
    }
    this.onedit.emit(system);
  }

  editConfigClick(system: SuspdSistema): void {
    if (this.loading) {
      return;
    }
    const modalRef = this.modalService.open(EditSystemConfigurationComponent, {windowClass: 'large-modal', backdrop: 'static'});
    modalRef.shown.subscribe(x => {
      modalRef.componentInstance.system = system;
    });
    modalRef.closed.subscribe(x => {
      console.log(x);
    });
  }

  deleteClick(system: SuspdSistema): void {
    if (this.loading) {
      return;
    }
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.pergunta = `Confirma exclusão do sistema ${system.nome}?`;
    modalRef.closed.subscribe(x => {
      if (x) {
        this.loading = true;
        if (system.id != null) {
          this.systemService.delete(system.id).subscribe(x => {
            this.loading = false;
            this.helperService.showMessage('Sistema excluído com sucesso!');
            this.ondelete.emit(system);
            this.loadSystems();
          });
        }
      }
    });
  }

  getCreditorName(creditorList: SuspdCredor[] | undefined): string {
    if (creditorList != null) {
      return creditorList.map(creditor => creditor.nome).join(', ');
    }
    return '';
  }

  tabSize($event: number): void {
    this.page = $event;
  }
}
