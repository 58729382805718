<app-loading *ngIf="loading" [backgroundBlue]="true"></app-loading>
<div class="container top-buffer" *ngIf="!loading">
  <table class="table-blue">
    <thead>
    <tr>
      <th class="container-title" colspan="11">
        <div class="table-title">Sistemas</div>
      </th>
    </tr>
    <tr>
      <th>Id</th>
      <th>Nome</th>
      <th>Bean</th>
      <th>Status</th>
      <th>Identificador</th>
      <th>MsUrl</th>
      <th>Parceiro</th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
    <tr></tr>
    </thead>
    <tbody>
    <tr *ngFor="let system of filteredSystemList | paginate : {
                id:'list-systems',
                itemsPerPage: size,
                currentPage: page,
                totalItems: filteredSystemList.length
              };">
      <td>{{ system.id }}</td>
      <td>{{ system.nome || '-' }}</td>
      <td>{{ system.bean || '-' }}</td>
      <td>{{ system.status == null ? '-' : system.status }}</td>
      <td>{{ system.identificador || '-' }}</td>
      <td>{{ system.msUrl || '-' }}</td>
      <td>{{ getCreditorName(system.creditorList) || '-' }}</td>
      <td><a href="javascript:void(0)" (click)="editClick(system)"><img class="icon-large" src="assets/svg/icons/editar.svg" alt="Editar"></a></td>
      <td><a href="javascript:void(0)" (click)="editConfigClick(system)"><img class="icon-large" src="assets/svg/icons/config.svg" alt="Configurar"></a></td>
      <td><a href="javascript:void(0)" (click)="deleteClick(system)"><img class="icon-large remove-icon" src="assets/svg/icons/remover.svg" alt="Excluir"></a></td>
    </tr>
    <tr *ngIf="filteredSystemList.length === 0">
      <td colspan="11">Nenhum sistema encontrado</td>
    </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-center top-buffer">
    <pagination-controls id="list-systems" [responsive]="false" previousLabel="Ant." nextLabel="Próx."
                         (pageChange)="tabSize($event)">
    </pagination-controls>
  </div>
</div>
