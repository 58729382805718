<div class="container top-buffer" *ngIf="cip != null">
  <table class="table-blue cip-table">
    <thead>
    <tr><th colspan="2"></th></tr>
    <tr>
      <th>Informações do boleto</th>
      <th>Valor</th>
    </tr>
    <tr></tr>
    </thead>
    <tbody *ngIf="cip != undefined">
    <tr>
      <td class="cursor" (click)="sortTable('wasPaid')">Foi pago?<span class="{{setaSort}}"></span></td>
      <td>{{ cip.wasPaid ? 'Sim' : 'Não' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('canBePaid')">Pode ser pago?<span class="{{setaSort}}"></span></td>
      <td>{{ cip.canBePaid ? 'Sim' : 'Não' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('paymentWasMade')">Pagamento foi realizado?<span class="{{setaSort}}"></span></td>
      <td>{{ cip.paymentWasMade ? 'Sim' : 'Não' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('drawer')">Sacado<span class="{{setaSort}}"></span></td>
      <td class="text-capitalize">{{ cip.drawer || '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('taxNumberBeneficiary')">Documento beneficiário<span class="{{setaSort}}"></span></td>
      <td>{{ cip.taxNumberBeneficiary || '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('taxNumberDrawee')">Documento Sacado<span class="{{setaSort}}"></span></td>
      <td>{{ cip.taxNumberDrawee || '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('paymentDate')">Data de pagamento<span class="{{setaSort}}"></span></td>
      <td>{{ (cip.paymentDate | date:'dd/MM/yyyy') || '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('dateDueRegistration')">Data de registro<span class="{{setaSort}}"></span></td>
      <td>{{ (cip.dateDueRegistration | date:'dd/MM/yyyy') || '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('beneficiaryName')">Nome do beneficiário<span class="{{setaSort}}"></span></td>
      <td class="text-capitalize">{{ cip.beneficiaryName || '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('draweeName')">Nome do pagador<span class="{{setaSort}}"></span></td>
      <td class="text-capitalize">{{ cip.draweeName || '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('discountValue')">Valor de desconto<span class="{{setaSort}}"></span></td>
      <td>{{ cip.discountValue ? (cip.discountValue | currency:'R$') : '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('interestValue')">Valor juros<span class="{{setaSort}}"></span></td>
      <td>{{ cip.interestValue ? (cip.interestValue | currency:'R$') : '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('maxPaymentValue')">Valor máximo para pagamento<span class="{{setaSort}}"></span></td>
      <td>{{ cip.maxPaymentValue ? (cip.maxPaymentValue | currency:'R$') : '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('minPaymentValue')">Valor mínimo para pagamento<span class="{{setaSort}}"></span></td>
      <td>{{ cip.minPaymentValue ?  (cip.minPaymentValue | currency:'R$') : '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('fineValue')">Valor da multa<span class="{{setaSort}}"></span></td>
      <td>{{ cip.fineValue ? (cip.fineValue | currency:'R$') : '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('nominalValue')">Valor nominal<span class="{{setaSort}}"></span></td>
      <td>{{ cip.nominalValue ? (cip.nominalValue | currency:'R$') : '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('updatedValue')">Valor atualizado<span class="{{setaSort}}"></span></td>
      <td>{{ cip.updatedValue ? (cip.updatedValue | currency:'R$') : '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('totalRebateValue')">Valor total do desconto<span class="{{setaSort}}"></span></td>
      <td>{{ cip.totalRebateValue ? (cip.totalRebateValue | currency:'R$') : '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('totalAddedValue')">Valor agregado total<span class="{{setaSort}}"></span></td>
      <td>{{ cip.totalAddedValue ? (cip.totalAddedValue | currency:'R$') : '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('settlementDate')">Data da liquidação<span class="{{setaSort}}"></span></td>
      <td>{{ cip.settlementDate ? (cip.settlementDate | date:'dd/MM/yyyy') : '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('dueDate')">Data de vencimento<span class="{{setaSort}}"></span></td>
      <td>{{ cip.dueDate ? (cip.dueDate | date:'dd/MM/yyyy') : '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('nextSettlement')">Próxima data de vencimento<span class="{{setaSort}}"></span></td>
      <td>{{ cip.nextSettlement ? 'Sim' : 'Não' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('value')">Valor<span class="{{setaSort}}"></span></td>
      <td>{{ cip.value ? (cip.value | currency:'R$') : '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('maxValue')">Valor máximo<span class="{{setaSort}}"></span></td>
      <td>{{ cip.maxValue ? (cip.maxValue | currency:'R$') : '-' }}</td>
    </tr>
    <tr>
      <td class="cursor" (click)="sortTable('minValue')">Valor mínimo<span class="{{setaSort}}"></span></td>
      <td>{{ cip.minValue ? (cip.minValue | currency:'R$') : '-' }}</td>
    </tr>
    </tbody>
  </table>
</div>
