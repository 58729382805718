import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-terms-of-use',
  templateUrl: './view-terms-of-use.component.html',
  styleUrls: ['./view-terms-of-use.component.scss'],
})
export class ViewTermsOfUseComponent {
  @Input() term: any;
  constructor(public activeModal: NgbActiveModal) {}

}
