<div class="container top-buffer">
  <table class="table-green">
    <thead>
    <tr>
      <th class="container-title" colspan="8">
        <div class="table-title">Acordos realizados</div>
        <div class="table-sub-title">Lista de todos os acordos ativos e inativos do consumidor consultado.</div>
      </th>
    </tr>
    <tr>
      <th class="cursor" scope="col" (click)="sortTable('id')">Acordo ID<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('dividaId.id')">Divida Id<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('dataAcordo')">Data Acordo<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('credorId')">Credor<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('contrato')">Contrato<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('identificador')">Identificador<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('qtdParcela')">Parcelas<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('valorAcordo')">Valor<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('status')">Status Acordo<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('status')">Forma de pagamento<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('dataCancelamento')">Data Cancelamento<span class="{{setaSort}}"></span></th>
      <th></th>
    </tr>
    <tr></tr>
    </thead>
    <tbody *ngIf="dealList != undefined">
    <tr *ngFor="let deal of dealList| paginate : {
                id:'deal-list',
                itemsPerPage: size,
                currentPage: page,
                totalItems: dealList.length
              };">
      <td>{{ deal.id }}</td>
      <td>{{ deal.dividaId?.id}}</td>
      <td>{{ deal.dataAcordo | date:'dd/MM/yyyy' }}</td>
      <td class="text-capitalize">{{ (deal.credorId || {}).nome }}</td>
      <td>{{ deal.contrato }}</td>
      <td>{{ deal.identificador }}</td>
      <td>{{ deal.qtdParcela }}</td>
      <td>{{ deal.valorAcordo | currency:'R$' }}</td>
      <td><a class="lnk" href="javascript:void(0)" (click)="showModalStatus(deal)"><span [ngClass]="{'badge-ativo': deal.status === 1, 'badge-concluido': deal.status === 2, 'badge-inativo': deal.status === 0}" class="badge badge-pill badge-lg">{{ deal.descricaoStatus }}</span></a></td>
      <td>{{ deal.paymentMethod }}</td>
      <td>{{ (deal.dataCancelamento | date:'dd/MM/yyyy') || "-" }}</td>
      <td><a class="lnk" href="javascript:void(0)" (click)="showModalDetail(deal)">Detalhes</a></td>
    </tr>
    <tr *ngIf="dealList.length == 0">
      <td colspan="10">Nenhum acordo encontrado</td>
    </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-center top-buffer">
    <pagination-controls id="deal-list" [responsive]="false" [previousLabel]="labelPrevious" [nextLabel]="labelNext"
                         (pageChange)="tabSize($event)">
    </pagination-controls>
  </div>
</div>
