<div class="container top-buffer" *ngIf="billetsInfoList != null">
  <table class="table-blue">
    <thead>
    <tr>
      <th colspan="10"></th>
    </tr>
    <tr>
      <th class="cursor" (click)="sortTable('action')">Ação<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('action')">Descrição<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('contact')">Contato<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('contact_type')">Tipo Contato<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('creation_time')">Data Criação<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('reference_date')">Data Referência<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('installment_id')">Contrato<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('billet_status')">Status Boleto<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('content')">Observação<span class="{{setaSort}}"></span></th>
    </tr>
    <tr></tr>
    </thead>
    <tbody>
    <tr *ngFor="let billetsInfoListInfo of billetsInfoList | paginate : {
                itemsPerPage: size,
                currentPage: page,
                totalItems: billetsInfoList.length
              };">
      <td>{{ billetsInfoListInfo.action }}</td>
      <td>{{ billetsInfoListInfo.actionDescription }}</td>
      <td>{{ getFormattedContact(billetsInfoListInfo) }}</td>
      <td class="text-capitalize">{{ billetsInfoListInfo.contact_type }}</td>
      <td>{{ billetsInfoListInfo.creation_time | date:'dd/MM/yyyy'  }}</td>
      <td>{{ billetsInfoListInfo.reference_date | date:'dd/MM/yyyy'  }}</td>
      <td>{{ billetsInfoListInfo.installment_id }}</td>
      <td><span [ngClass]="{'badge-concluido': billetsInfoListInfo.billet_status === 'EXPIRED', 'badge-ativo': billetsInfoListInfo.billet_status !== 'EXPIRED'}"
                class="badge badge-pill badge-lg">{{ billetsInfoListInfo.billetStatusDescription }}</span></td>
      <td>{{ billetsInfoListInfo.content}}</td>
    </tr>
    <tr *ngIf="billetsInfoList.length == 0">
      <td colspan="10">Nenhum registro encontrados</td>
    </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-center top-buffer">
    <pagination-controls [responsive]="false" [previousLabel]="labelPrevious" [nextLabel]="labelNext"
                         (pageChange)="tabSize($event)">
    </pagination-controls>
  </div>
</div>
