import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewTermsOfUseComponent } from '../view-terms-of-use/view-terms-of-use.component';

@Component({
  selector: 'app-list-terms-of-use',
  templateUrl: './list-terms-of-use.component.html',
  styleUrls: ['./list-terms-of-use.component.scss']
})
export class ListTermsOfUseComponent {
  @Input() terms: any;
  @Output() onedit = new EventEmitter<any>();
  page = 1;
  size = 10;
  constructor(private modalService: NgbModal) {}
  viewContent(term:any){
    const modalRef = this.modalService.open(ViewTermsOfUseComponent,  {centered: true,  size: "lg",});
    modalRef.componentInstance.term = term;
  }
  tabSize($event: number): void {
    this.page = $event;
  }
}