<div class="modal-header">
  <h4 class="modal-title">Opções de pagamento</h4>
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-loading [backgroundBlue]="true" *ngIf="loading"></app-loading>
  <table class="table-blue" *ngIf="!loading">
    <thead>
    <tr><th colspan="8"></th></tr>
    <tr>
      <th class="cursor" (click)="sortTable('id')">ID<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('qtdParcela')">Parcelas<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('valorAcordo')">Valor Acordo<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('valorParcela')">Valor Parcela<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('valorEntrada')">Valor Entrada<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('formaPagamento')">Forma de Pagamento<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('valorDesconto')">Valor Desconto<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('dataSimulacao')">Data Simulação<span class="{{setaSort}}"></span></th>
    </tr>
    <tr></tr>
    </thead>
    <tbody *ngIf="debtPaymentOptionList != undefined">
    <tr *ngFor="let paymentOption of debtPaymentOptionList">
      <td>{{ paymentOption.id }}</td>
      <td>{{ paymentOption.qtdParcela }}</td>
      <td>{{ paymentOption.valorAcordo | currency:'R$' }}</td>
      <td>{{ paymentOption.valorParcela | currency:'R$' }}</td>
      <td>{{ paymentOption.valorEntrada | currency:'R$' }}</td>
      <td>{{ paymentOption.formaPagamento || "-" }}</td>
      <td>{{ (paymentOption.valorDesconto | currency:'R$') || "-" }}</td>
      <td>{{ (paymentOption.divida?.dataSimulacao | date:'dd/MM/yyyy') || "-" }}</td>
    </tr>
    <tr *ngIf="debtPaymentOptionList.length == 0">
      <td colspan="8">Nenhum registro encontrado</td>
    </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button (click)="activeModal.close('Close click')" class="btn btn-outline-dark" type="button">Fechar</button>
</div>
