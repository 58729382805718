import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder} from '@angular/forms';
import {SuspdAcordo} from '../../../../models/domain';
import {DealService} from '../../../../core/services/deal.service';

@Component({
  selector: 'app-deal-list-status',
  templateUrl: './deal-list-status.component.html',
  styleUrls: ['./deal-list-status.component.scss']
})
export class DealListStatusComponent {

  dealStatusForm = this.formBuilder.group({
    status: ''
  });

  deal?: SuspdAcordo;
  saving = false;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private dealService: DealService) {
  }

  @Input()
  public setDealValue(deal: SuspdAcordo): void {
    this.deal = deal;
  }

  onSubmit(): void {
    if (this.deal == null || this.deal.id == null) {
      return;
    }
    this.saving = true;
    const form = this.dealStatusForm.value;
    if (form.status === '0') {
      this.dealService.deleteDeal(this.deal.id).subscribe(x => this.activeModal.close(x));
    } else if (form.status === '1') {
      this.dealService.reactivateDeal(this.deal.id).subscribe(x => this.activeModal.close(x));
    }
  }
}
