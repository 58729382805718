import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DealListStatusComponent } from '../modals/deal-list-status/deal-list-status.component';
import { DealDetailComponent } from '../modals/deal-detail/deal-detail.component';
import { sortBy } from 'sort-by-typescript';
import { AcordoResponse } from '../../../models/response/devedorDetalheResponse.model';
import { PaymentMethods } from 'src/app/core/services/utils/paymentMethods-enum';

@Component({
  selector: 'app-deal-list',
  templateUrl: './deal-list.component.html',
  styleUrls: ['./deal-list.component.scss']
})
export class DealListComponent {

  public dealList?: AcordoResponse[];
  public dealListSort: any;
  public sort: any;
  public desc = false;
  public setaSort: any = 'seta-baixo';

  size = 10;
  page = 1;

  labelPrevious = 'Ant.';
  labelNext = 'Próx.';

  constructor(private modalService: NgbModal) {
  }

  @Input()
  set dealListValue(dealList: AcordoResponse[] | undefined) {
    if (dealList !== undefined) {
      this.dealList = this.updatePaymentMethodName(dealList);
      this.dealListSort = dealList;
    }
  }

  showModalStatus(deal: AcordoResponse): void {
    const modalRef = this.modalService.open(DealListStatusComponent);
    modalRef.shown.subscribe(x => {
      modalRef.componentInstance.setDealValue(deal);
    });
    modalRef.closed.subscribe(x => {
      if (x.status != null) {
        deal.status = x.status;
        deal.descricaoStatus = x.descricaoStatus;
        deal.dataCancelamento = x.dataCancelamento;
      }
    });
  }

  showModalDetail(deal: AcordoResponse): void {
    const modalRef = this.modalService.open(DealDetailComponent, { windowClass: 'large-modal', backdrop: 'static' });
    modalRef.shown.subscribe(x => {
      modalRef.componentInstance.setDealValue(deal);
    });
    modalRef.closed.subscribe(x => {
      console.log(x);
    });
  }

  sortTable(sort: string): void {
    if (this.sort === sort && this.desc === false) {
      this.desc = true;
      this.setaSort = 'seta-cima';
    } else {
      this.desc = false;
      this.setaSort = 'seta-baixo';
    }
    this.sort = sort;
    if (this.desc) {
      this.dealListSort.sort(sortBy('-'.concat(sort)));
    } else {
      this.dealListSort.sort(sortBy(sort));
    }
  }

  tabSize($event: number): void {
    this.page = $event;
  }

  updatePaymentMethodName(agreements: AcordoResponse[]): AcordoResponse[] {
    return agreements.map((agreement) => {
      let paymentMethod = agreement.paymentMethod;
      if (paymentMethod == PaymentMethods.BILLET.value) {
        agreement.paymentMethod = PaymentMethods.BILLET.label;
      } else if (paymentMethod == PaymentMethods.CREDIT_CARD.value) {
        agreement.paymentMethod = PaymentMethods.CREDIT_CARD.label;
      }
      return agreement;
    });
  }
}
