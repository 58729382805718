<app-loading *ngIf="loading" [backgroundBlue]="true"></app-loading>

<app-publish-privacy-policy
*ngIf="!insert && !loading"
[policies]="policies"
[medium]="true"
(onPublish)="published()"
> 

</app-publish-privacy-policy>

<app-edit-privacy-policy
  *ngIf="insert && !loading"
  (onsave)="savePolicy()"
  (oncancel)="cancelEdit($event)"
  [policy]="policy"
  [policies]="policies"
></app-edit-privacy-policy>

<div class="container top-buffer" *ngIf="!insert && !loading">
  <div class="insert-container">
    <app-button-edit
      label="Incluir novo"
      [primary]="false"
      (onclick)="insertClick()"
    ></app-button-edit>
  </div>
</div>

<app-list-privacy-policy
  *ngIf="!insert && !loading"
  [policies]="policies"
  (onedit)="setPolicy($event)"
>
</app-list-privacy-policy>
