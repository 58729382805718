import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ActivationsInfo,
  AuditLog,
  AuthorityInfo,
  BilletAlertsInfo,
  Block,
  BlockListInfo,
  CipInfo,
  Devedor,
  Role,
  LgpdReportResponse,
  SuspdCredor,
  SuspdCredorConfiguracao,
  SuspdDividaDetalhe,
  SuspdOpcaoPagamento,
  SuspdPoliticaCalculo,
  SuspdPoliticaParcelamento,
  SuspdSistema,
  SuspdSistemaUsuario,
  UserRole
} from '../../models/domain';
import { CpfInfoResponse, BlockRequest, BlockConvert, GenerateLgpdReportRequest } from '../../models/domain';
import { DevedorDetalheResponse, DevedorResponse } from '../../models/response/devedorDetalheResponse.model';
import { DividaOcultaResponse } from '../../models/response/DividaOcultaResponse.model';
import { ReclameAquiResponse } from '../../models/response/ReclameAquiResponse.model';
import { AuthorityResponse } from '../../models/response/AuthorityResponse.mode';
import { AuthorityRequest } from '../../models/request/AuthorityRequest.model';
import { ParcelaResponse } from '../../models/response/parcela-response.model';
import { AcordoResponse } from '../../models/response/acordo-response.model';

@Injectable()
export class ApiServices {

  constructor(
    protected http: HttpClient
  ) { }

  getCustomerByDate(date: string): Observable<AcordoResponse[]> {
    return this.http.get<AcordoResponse[]>(`customer/${date}`);
  }

  loadAuditLogs(type: string, entityId: any): Observable<any> {
    return this.http.get<AuditLog[]>(`audit/updates?entity=${encodeURIComponent(type)}&entityId=${encodeURIComponent(entityId)}`);
  }

  deleteDeal(dealId: number): Observable<any> {
    return this.http.delete(`deal/${dealId}`);
  }

  reactivateDeal(dealId: number): Observable<any> {
    return this.http.post(`deal/${dealId}`, null);
  }

  getInstallments(dealId: number): Observable<ParcelaResponse[]> {
    return this.http.get<ParcelaResponse[]>(`deal/${dealId}/installment`);
  }

  changeInstallmentDate(dealId: number, installmentId: number, date: string | null): Observable<any> {
    return this.http.put(`deal/${dealId}/installment/${installmentId}?paymentDate=${date}`, null);
  }

  removeInstallmentDate(dealId: number, installmentId: number): Observable<any> {
    return this.http.put(`deal/${dealId}/installment/${installmentId}`, null);
  }

  getDetailList(dealId: number): Observable<SuspdDividaDetalhe[]> {
    return this.http.get<SuspdDividaDetalhe[]>(`debt/${dealId}/detail`);
  }

  getPaymentOptionListList(dealId: number): Observable<SuspdOpcaoPagamento[]> {
    return this.http.get<SuspdOpcaoPagamento[]>(`debt/${dealId}/paymentOption`);
  }

  searchByCpf(cpf: number | string): Observable<DevedorDetalheResponse> {
    return this.http.get<DevedorDetalheResponse>(`debtor/${cpf}`);
  }

  searchByEmail(email: String): Observable<DevedorDetalheResponse> {
    return this.http.get<DevedorDetalheResponse>(`debtor/email-user/${email}`);
  }

  updateDebtor(id: number, debtor: Devedor): Observable<DevedorResponse> {
    return this.http.put<DevedorResponse>(`debtor/${id}`, debtor);
  }

  searchHashUserCpf(cpf: number): Observable<String> {
    return this.http.get(`debtor/hash-user/${cpf}`, { responseType: 'text' });
  }

  loadDebtorAuditLogs(id: number): Observable<AuditLog[]> {
    return this.http.get<AuditLog[]>(`debtor/${id}/audit`);
  }

  loadCIP(digitableLine: string): Observable<CipInfo> {
    return this.http.get<CipInfo>(`cip/${digitableLine}`);
  }

  loadUserAuthorities(): Observable<AuthorityInfo> {
    return this.http.get<AuthorityInfo>(`auth/authorities`);
  }

  listAllRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`role`);
  }

  listUsers(): Observable<UserRole[]> {
    return this.http.get<UserRole[]>(`user`);
  }

  listUserRoles(userName: string): Observable<Role[]> {
    return this.http.get<Role[]>(`user/${userName}/roles`);
  }

  insertRole(userName: string, roleId: number): Observable<UserRole> {
    return this.http.post<UserRole>(`user/${userName}/roles?roleId=${roleId}`, null);
  }

  deleteUser(userName: string): Observable<string> {
    return this.http.delete<string>(`user/${userName}`);
  }

  loadUserRoleAuditLogs(userName: string): Observable<AuditLog[]> {
    return this.http.get<AuditLog[]>(`user/${userName}/roles/audit`);
  }

  getBilletURL(installmentId: number): Observable<string> {
    return this.http.get<string>(`/billet/${installmentId}`);
  }

  searchBlocklistByCpfOrPhoneNumber(cpfOrPhoneNumber: string): Observable<BlockListInfo[]> {
    return this.http.get<BlockListInfo[]>(`/blocklist?cpfOrPhoneNumber=${encodeURIComponent(cpfOrPhoneNumber)}`);
  }

  searchBlocklistByEmail(email: string): Observable<BlockListInfo[]> {
    return this.http.get<BlockListInfo[]>(`/blocklist?email=${encodeURIComponent(email)}`);
  }

  searchActivations(cpfOrContact: string): Observable<ActivationsInfo[]> {
    return this.http.get<ActivationsInfo[]>(`/activations?cpfOrContact=${encodeURIComponent(cpfOrContact)}`);
  }

  searchBillets(cpf: string): Observable<BilletAlertsInfo[]> {
    return this.http.get<BilletAlertsInfo[]>(`/billetAlerts?cpf=${encodeURIComponent(cpf)}`);
  }

  sendEmailBillet(installmentId: number): Observable<boolean> {
    return this.http.post<boolean>(`billet/${installmentId}/email`, null);
  }

  getCreditors(): Observable<SuspdCredor[]> {
    return this.http.get<SuspdCredor[]>(`creditor`);
  }

  updateStatus(creditor: SuspdCredor, status: number): Observable<SuspdCredor> {
    return this.http.patch<SuspdCredor>(`creditor/${creditor.id}?status=${status}`, null);
  }

  searchPoliticCalc(partnerId: number): Observable<SuspdPoliticaParcelamento[]> {
    return this.http.get<SuspdPoliticaParcelamento[]>(`/partner/${encodeURIComponent(partnerId)}`);
  }

  searchInstallment(installmentId: number): Observable<SuspdPoliticaCalculo[]> {
    return this.http.get<SuspdPoliticaCalculo[]>(`/partner/installment/${encodeURIComponent(installmentId)}`);
  }

  setInstallments(suspdPoliticaParcelamento: SuspdPoliticaParcelamento): Observable<SuspdPoliticaParcelamento> {
    return this.http.put<SuspdPoliticaParcelamento>(`partner/suspdPoliticaParcelamento`, suspdPoliticaParcelamento);
  }

  setPoliticCalc(suspdPoliticaCalculo: SuspdPoliticaCalculo): Observable<SuspdPoliticaCalculo> {
    return this.http.put<SuspdPoliticaCalculo>(`partner/suspdPoliticaCalculo`, suspdPoliticaCalculo);
  }

  updateCreditor(id: number, creditor: SuspdCredor): Observable<SuspdCredor> {
    return this.http.put<SuspdCredor>(`creditor/${id}`, creditor);
  }

  createCreditor(creditor: SuspdCredor): Observable<SuspdCredor> {
    return this.http.post<SuspdCredor>(`creditor`, creditor);
  }

  loadCreditorConfiguration(creditorId: number): Observable<SuspdCredorConfiguracao[]> {
    return this.http.get<SuspdCredorConfiguracao[]>(`creditor/${creditorId}/config`);
  }

  createOrUpdateConfiguration(creditorId: number, configuration: SuspdCredorConfiguracao[]): Observable<SuspdCredorConfiguracao[]> {
    return this.http.put<SuspdCredorConfiguracao[]>(`creditor/${creditorId}/config`, configuration);
  }

  deleteCreditor(id: number): Observable<SuspdCredor> {
    return this.http.delete<SuspdCredor>(`creditor/${id}`);
  }

  listSystems(): Observable<SuspdSistema[]> {
    return this.http.get<SuspdSistema[]>('system');
  }

  deleteSystem(id: number): Observable<SuspdSistema> {
    return this.http.delete<SuspdCredor>(`system/${id}`);
  }

  updateSystem(id: number, system: SuspdSistema): Observable<SuspdSistema> {
    return this.http.put<SuspdSistema>(`system/${id}`, system);
  }

  createSystem(system: SuspdSistema): Observable<SuspdSistema> {
    return this.http.post<SuspdSistema>(`system`, system);
  }

  listSystemUser(systemId: number): Observable<SuspdSistemaUsuario[]> {
    return this.http.get<SuspdSistemaUsuario[]>(`system/${systemId}/user`);
  }

  createOrUpdateSystemUsers(systemId: number, systemUserList: SuspdSistemaUsuario[]): Observable<SuspdSistemaUsuario[]> {
    return this.http.put<SuspdSistemaUsuario[]>(`system/${systemId}/user`, systemUserList);
  }

  loadPartnerAuditLogs(id: number): Observable<AuditLog[]> {
    return this.http.get<AuditLog[]>(`partner/${id}/audit`);
  }

  loadSystemAuditLogs(): Observable<AuditLog[]> {
    return this.http.get<AuditLog[]>(`system/audit`);
  }

  loadCreditorAuditLog(id: number): Observable<AuditLog[]> {
    return this.http.get<AuditLog[]>(`creditor/${id}/audit`);
  }

  deleteSuspdPoliticaParcelamento(id: number): Observable<SuspdPoliticaParcelamento> {
    return this.http.delete<SuspdPoliticaParcelamento>(`partner/suspdPoliticaParcelamento/${id}`);
  }

  deleteSuspdPoliticaCalculo(id: number): Observable<SuspdPoliticaCalculo> {
    return this.http.delete<SuspdPoliticaCalculo>(`partner/suspdPoliticaCalculo/${id}`);
  }

  downloadCalcPolicyCsv(policyId: number, policyKey: string): Observable<Blob> {
    return this.http.get(`partner/csv/${policyId}?installmentPolicyKey=${policyKey}`, { responseType: 'blob' });
  }

  batchUpdate(creditorId: number, installmentPolicyId: number, uploadFile: File): Observable<any> {
    return this.http.put(`partner/${creditorId}/suspdPoliticaCalculo?installmentPolicyId=${installmentPolicyId}`, uploadFile);
  }

  updateCalculationPolicy(uploadFile: File): Observable<any> {
    return this.http.post(`partner/hoepersPolicy`, uploadFile, { responseType: 'text' });
  }

  updateInstallmentPolicy(creditorId: number, uploadFile: File): Observable<any> {
    return this.http.put(`partner/${creditorId}/suspdPoliticaParcelamento`, uploadFile, { responseType: 'text' });
  }

  isBlocked(): Observable<any> {
    return this.http.get(`partner/uploadFlag`);
  }

  cpfToHash(content: string): Observable<CpfInfoResponse[]> {
    return this.http.get<CpfInfoResponse[]>(`/debtor/cpf-hash?content=${encodeURIComponent(content)}`);
  }

  uploadFileReport(customerReportRequest: FormData): Observable<any> {
    return this.http.post(`/report/save-customer-report`, customerReportRequest);
  }

  listReport(): Observable<any> {
    return this.http.get('/report', { responseType: 'json' });
  }

  downloadFileReport(id: number): Observable<any> {
    return this.http.post(`/report/download/id=${id}`, {}, { observe: 'response', responseType: 'text' });
  }

  includeToBlock(blockRequest: BlockRequest): Observable<BlockRequest> {
    return this.http.post<BlockRequest>(`/blocklist`, blockRequest);
  }

  getBlock(): Observable<BlockConvert[]> {
    return this.http.get<BlockConvert[]>(`/blocklist/list`);
  }

  removeFromBlock(block: number): Observable<Block> {
    return this.http.delete<Block>(`/blocklist/${block}`);
  }

  invalidateBilletCache(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`/billet/${id}/remove`);
  }

  sendParametersToAnubis(sendData: GenerateLgpdReportRequest): Observable<any> {
    return this.http.post(`/generateLgpdReport`, sendData);
  }

  getLastDayReports(): Observable<LgpdReportResponse[]> {
    return this.http.get<LgpdReportResponse[]>(`/generateLgpdReport`);
  }

  devedorAuthority(authorityRequest: AuthorityRequest): Observable<AuthorityResponse> {
    return this.http.post<AuthorityResponse>(`/user/marketplace`, authorityRequest);
  }

  putDevedorChangeAuthority(authorityRequest: AuthorityRequest): Observable<AuthorityResponse> {
    return this.http.put<AuthorityResponse>(`/user/marketplace/changeAuthority`, authorityRequest);
  }

  getSystemById(id: number): Observable<SuspdSistema> {
    return this.http.get<SuspdSistema>(`/system/${id}`);
  }

  getHiddenDebts(cpf: string): Observable<DividaOcultaResponse[]> {
    return this.http.get<DividaOcultaResponse[]>(`/debt/hidden?customerId=${encodeURIComponent(cpf)}`);
  }

  deleteHiddenDebt(hiddenDebtId: number): Observable<DividaOcultaResponse[]> {
    return this.http.delete<DividaOcultaResponse[]>(`/debt/hidden?hiddenDebtId=${encodeURIComponent(hiddenDebtId)}`);
  }

  fetchExistingBlock(cpf: string): Observable<BlockConvert[]> {
    return this.http.get<BlockConvert[]>(`/blocklist/existingBlocks?cpf=${encodeURIComponent(cpf)}`);
  }

  getCustomerEmailByEmail(email: String): Observable<ReclameAquiResponse[]> {
    return this.http.get<ReclameAquiResponse[]>(`/api/customer/email?email=${email}`);
  }

  getTermsOfUse(): Observable<any> {
    return this.http.get(`/site-policy/terms-of-use`);
  }

  getPrivacyPolicies(): Observable<any> {
    return this.http.get(`/site-policy/privacy-policy`);
  }

  createPrivacyPolicies(policy: any): Observable<any> {
    return this.http.post(`/site-policy/privacy-policy`, policy);
  }

  createTermsOfUse(term: any): Observable<any> {
    return this.http.post(`/site-policy/terms-of-use`, term);
  }

  publishSitePolicies(privacyAndTermId: any): Observable<any> {
    return this.http.post(`/site-policy/create-publish`, privacyAndTermId);
  }
  
  getCustomerByPhone(phoneNumber: string): Observable<ReclameAquiResponse[]> {
    return this.http.get<ReclameAquiResponse[]>(`/api/customer/phone?phone=${phoneNumber}`);
  }

  filterPolicies(id: number, filterPolicies: any ): Observable <any> {
    return this.http.post(`/installment-policy/${id}`, filterPolicies);
  }
}
