<app-search *ngIf="!insert" (searchSubmit)="searchSubmit($event)" [searchTitle]="titleSearch"
            [large]="true" [canSubmitEmpty]="true"></app-search>

<div class="container top-buffer" *ngIf="!insert">
  <div class="operation-container">
    <app-button-edit label="Incluir" [primary]="false" (onclick)="insertClick()"></app-button-edit>
  </div>
</div>

<app-list-systems *ngIf="!insert" [searchTerm]="searchTerm"
                  (onedit)="editSystem($event)" (ondelete)="deleteSystem()"></app-list-systems>

<app-edit-system *ngIf="insert" [system]="system" (oncancel)="editSystemCanceled()"
                 (onsave)="editSystemSaved()"></app-edit-system>

<app-audit-log></app-audit-log>
