<form [formGroup]="form">
    <div class="triangulo-para-cima"></div>
    <div class="container">
      <div class="edit-container">
        <div class="row">
          <div class="data-title">Versão:</div>
          <div class="data-value text-capitalize">
            <input
              class="form-control form-input"
              type="text"
              formControlName="version"
              maxlength="255"
            />
          </div>
        </div>
        <div class="row">
          <div class="data-title">Conteúdo:</div>
          <div class="angular-editor">
            <angular-editor formControlName="content" [config]="editorConfig">
            </angular-editor>
          </div>
        </div>
        <div class="row top-buffer btn-edit-container">
          <app-button-edit (onclick)="onSave()" label="Salvar" [disabled]="form.invalid"></app-button-edit>
          <app-button-edit [leftBuffer]="true" [primary]="false" (onclick)="onCancel()"
                           label="Cancelar"></app-button-edit>
        </div>
      </div>
    </div>
  </form>
  