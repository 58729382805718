<form [formGroup]="generateLgpdReportRequest">
  <div class="container">
    <div class="send-data-container middle-aligned-container align-items-center">
      <div class="title-search">LGPD</div>
      <div class="row">
        <div class="data-title">CPF:</div>
        <input type="text" class="form-control input-send-data" placeholder="000.000.000-00" mask="000.000.000-00"
          formControlName="document">
      </div>
      <div class="row">
        <div class="data-title">E-mail do juridico:</div>
        <input type="email" class="form-control input-send-data" formControlName="emailToSendPubSub">
      </div>
      <div class="row">
        <div class="data-title">E-mail do consumidor:</div>
        <input type="email" class="form-control input-send-data" formControlName="userEmail">
      </div>
      <div class="row">
        <div class="data-title">Telefone:</div>
        <input type="text" class="form-control input-send-data" formControlName="phone">
      </div>
      <div class="row button-submit-block">
        <app-button-edit (onclick)="onSubmit()" label="ENVIAR" [inverted]="true"></app-button-edit>
      </div>
    </div>
  </div>
</form>

<div class="container top-buffer">
  <table class="table-blue">
    <thead>
      <tr>
        <th class="container-title" colspan="11">
          <div class="table-title">Pedidos</div>
           <div class="table-sub-title">Pedidos realizados nas últimas 24h</div>
        </th>
      </tr>
      <tr>
        <th class="cursor">CPF</th>
        <th class="cursor">E-mail do juridico</th>
        <th class="cursor">E-mail do consumidor</th>
        <th class="cursor">Telefone</th>
        <th class="cursor">Data</th>

      </tr>
      <tr></tr>
    </thead>
    <tbody *ngIf="sendData != null && sendData != undefined">
      <tr *ngFor="let data of sendData| paginate : {
                    id:'data-list',
                    itemsPerPage: size,
                    currentPage: page,
                    totalItems: sendData.length
                  };">
        <td>{{ data.customerId }}</td>
        <td>{{ data.emailToSend }}</td>
        <td>{{ data.customerEmail }}</td>
        <td>{{ data.telephone }}</td>
        <td>{{ data.createdAt | date:'dd/MM/yyyy HH:mm:ss' }}</td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-center top-buffer">
    <pagination-controls id="data-list" [responsive]="false" previousLabel="Ant." nextLabel="Próx."
      (pageChange)="tabSize($event)">
    </pagination-controls>
  </div>
</div>