import {Observable} from 'rxjs';
import {CipInfo} from '../../models/domain';
import {Injectable} from '@angular/core';
import {ApiServices} from './api.services';

@Injectable()
export class CipService {

  constructor(private apiService: ApiServices) {
  }

  loadCIP(digitableLine: string): Observable<CipInfo> {
    digitableLine = digitableLine.replace(/[^0-9]/g, '');
    return this.apiService.loadCIP(digitableLine);
  }
}
