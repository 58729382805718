import {LOCALE_ID, NgModule} from '@angular/core';
import {DealDetailComponent} from './modals/deal-detail/deal-detail.component';
import {DealListComponent} from './deal-list/deal-list.component';
import {DealListStatusComponent} from './modals/deal-list-status/deal-list-status.component';
import {DebtDetailComponent} from './modals/debt-detail/debt-detail.component';
import {DebtListComponent} from './debt-list/debt-list.component';
import {DebtPaymentOptionsComponent} from './modals/debt-payment-option/debt-payment-options.component';
import {DebtorComponent} from './debtor/debtor.component';
import {SearchDebtorByCpfComponent} from './search-debtor-by-cpf/search-debtor-by-cpf.component';
import { SearchDebtorByEmailComponent } from './search-debtor-by-email/search-debtor-by-email.component';
import { SearchHashByCpfComponent } from './search-hash-by-cpf/search-hash-by-cpf.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor, ErrorInterceptor} from '../../core/security/auth-interceptor';
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule, DatePipe} from '@angular/common';
import {MaskPipe, NgxMaskModule} from 'ngx-mask';
import {DealService} from '../../core/services/deal.service';
import {DebtorService} from '../../core/services/debtor.service';
import {DebtService} from '../../core/services/debt.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastrModule} from 'ngx-toastr';
import {AcCustomerModule} from '../../ac-customer.module';
import {NgbDateBRParserFormatter} from '../../ngb-date-br-parser-formatter';
import {NgxPaginationModule} from 'ngx-pagination';
import { SearchCpfByHashComponent } from './search-cpf-by-hash/search-cpf-by-hash.component';
import { ReportComponent } from './report/report.component';

@NgModule({
  declarations: [
    DealDetailComponent,
    DealListComponent,
    DealListStatusComponent,
    DebtDetailComponent,
    DebtListComponent,
    DebtPaymentOptionsComponent,
    DebtorComponent,
    SearchDebtorByCpfComponent,
    SearchDebtorByEmailComponent,
    SearchHashByCpfComponent,
    SearchCpfByHashComponent,
    ReportComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AcCustomerModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    NgxPaginationModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: NgbDateParserFormatter, useClass: NgbDateBRParserFormatter},
    {provide: LOCALE_ID, useValue: 'pt'},
    DatePipe,
    MaskPipe,
    DealService,
    DebtorService,
    DebtService
  ]
})
export class SearchDebtorModule {
}
