import {Component} from '@angular/core';
import {BlockListInfo} from '../../../models/domain';
import {BlocklistService} from '../../../core/services/blocklist.service';
import {HelperService} from '../../../core/services/helper.service';

@Component({
  selector: 'app-consulta-blocklist',
  templateUrl: './consulta-blocklist.component.html',
  styleUrls: ['./consulta-blocklist.component.scss']
})

export class ConsultaBlocklistComponent {
  titleSearch = 'Consulte os bloqueios de um CPF, número de telefone ou e-mail';
  placeHolderSearch = 'CPF, número de telefone ou e-mail';
  cpfOrPhoneNumberOrEmail = '';
  loading = false;
  blocklist: BlockListInfo[] | undefined;

  constructor(private blocklistService: BlocklistService, private helperService: HelperService) {
  }

  searchSubmit(val: string): void {
    if (val.length >= 10) {
      this.cpfOrPhoneNumberOrEmail = val;
      this.loading = true;
      this.blocklistService.searchBlocklist(this.cpfOrPhoneNumberOrEmail).subscribe(blocklist => {
        this.loading = false;
        this.blocklist = blocklist;
      }, error => {
        this.loading = false;
      });
    } else {
      this.helperService.showMessage('Informe um CPF ou Número de telefone com DDD para consultar ou E-mail');
    }
  }
}
