import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {HelperService} from '../../../core/services/helper.service';
import {ActivatedRoute} from '@angular/router';
import {NavigationService} from '../../../core/services/navigationService';

@Component({
  selector: 'app-search-by-cpf',
  templateUrl: './search-by-cpf.component.html',
  styleUrls: ['./search-by-cpf.component.scss']
})
export class SearchByCpfComponent {
  @Output() searchByCpfSubmit: EventEmitter<number> = new EventEmitter();
  title = '';
  searchMask = '000.000.000-00';

  constructor(public helperService: HelperService,
              private navigationService: NavigationService) {
  }

  @Input()
  set titleValue(value: string) {
    this.title = value;
  }

  onSubmit(val: string): void {
    this.navigationService.populate('/search-debtor-by-cpf', '');
    val = val.replace(/[^0-9]/g, '');
    this.searchByCpfSubmit.emit(Number(val));
  }

}
