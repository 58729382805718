<form [formGroup]="debtorForm">
  <div class="triangulo-para-cima"></div>
  <app-loading *ngIf="loading" [title]="loadingTitle" [backgroundBlue]="true"></app-loading>
  <div *ngIf="!!debtor" class="container">
    <div class="debtor-container">
      <div class="row">
        <div class="data-title">ID da conta:</div>
        <div class="data-value">#{{ debtor.id }}</div>
      </div>
      <div class="row">
        <div class="data-title">Data cadastramento:</div>
        <div class="data-value">{{ debtor.dataCriacao|date:'dd/MM/yyyy' }}</div>
      </div>
      <div class="row">
        <div class="data-title">Status da conta:</div>
        <div class="data-value" *ngIf="!editable">{{ debtor.descricaoStatus }}</div>
        <div class="data-value" *ngIf="editable">
          <select class="form-control form-input" name="status" formControlName="status">
            <option value="0">Inativo</option>
            <option value="1">Ativo</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="data-title">Nome</div>
        <div class="data-value text-capitalize" *ngIf="!editable">{{ debtor.nome || '-' }}</div>
        <div class="data-value text-capitalize" *ngIf="editable"><input class="form-control form-input" type="text"
            formControlName="nome"></div>
      </div>
      <div class="row">
        <div class="data-title">Nome Social:</div>
        <div class="data-value text-capitalize" *ngIf="!editable">{{ debtor.socialName || '-' }}</div>
        <div class="data-value text-capitalize" *ngIf="editable"><input class="form-control form-input" type="text"
            formControlName="socialName"></div>
      </div>
      <div class="row">
        <div class="data-title">CPF:</div>
        <div class="data-value" *ngIf="!editable">{{ debtor.cpf }}</div>
        <div class="data-value" *ngIf="editable">{{ debtor.cpf }}</div>
        <div
          *ngIf="debtorForm.controls.cpf.invalid && (debtorForm.controls.cpf.dirty || debtorForm.controls.cpf.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="debtorForm.controls.cpf.value !== ''">Campo CPF está inválido</li>
            <li *ngIf="debtorForm.controls.cpf.value === ''">Campo CPF é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="data-title">E-mail:</div>
        <div class="data-value" *ngIf="!editable && email != null">{{ email.email }}</div>
        <div class="data-value" *ngIf="editable && email != null"><app-input-mail
            formControlName="email"></app-input-mail></div>
        <div
          *ngIf="debtorForm.controls.email.invalid && (debtorForm.controls.email.dirty || debtorForm.controls.email.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="debtorForm.controls.email.value !== ''">Campo E-mail está inválido</li>
            <li *ngIf="debtorForm.controls.email.value === ''">Campo E-mail é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="data-title">DDD:</div>
        <div class="data-value" *ngIf="!editable && telefone != null">{{ telefone.ddd }}</div>
        <div class="data-value" *ngIf="editable && telefone != null"><app-input-ddd
            formControlName="ddd"></app-input-ddd></div>
        <div
          *ngIf="debtorForm.controls.ddd.invalid && (debtorForm.controls.ddd.dirty || debtorForm.controls.ddd.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="debtorForm.controls.ddd.value !== ''">Campo DDD está inválido</li>
            <li *ngIf="debtorForm.controls.ddd.value === ''">Campo DDD é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="data-title">Telefone:</div>
        <div class="data-value"
          *ngIf="!editable && telefone != null && telefone.telefone != null && telefone.telefone.length > 8">{{
          telefone.telefone | mask: '0 0000-0000' }}</div>
        <div class="data-value"
          *ngIf="!editable && telefone != null && telefone.telefone != null && telefone.telefone.length <= 8">{{
          telefone.telefone | mask: '0000-0000' }}</div>
        <div class="data-value" *ngIf="editable && telefone != null && telefone.telefone != null"><app-input-phone
            formControlName="telefone"></app-input-phone></div>
        <div
          *ngIf="debtorForm.controls.telefone.invalid && (debtorForm.controls.telefone.dirty || debtorForm.controls.telefone.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="debtorForm.controls.telefone.value !== ''">Campo Telefone está inválido</li>
            <li *ngIf="debtorForm.controls.telefone.value === ''">Campo Telefone é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="data-title">Data de Nascimento:</div>
        <div class="data-value" *ngIf="!editable">{{ debtor.dataNascimento | date:'dd/MM/yyyy' }}</div>
        <div class="data-value" *ngIf="editable"><app-input-date formControlName="dataNascimento"></app-input-date>
        </div>
        <div
          *ngIf="debtorForm.controls.dataNascimento.invalid && (debtorForm.controls.dataNascimento.dirty || debtorForm.controls.dataNascimento.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="debtorForm.controls.dataNascimento.value !== ''">Campo Data de Nascimento está inválido</li>
            <li *ngIf="debtorForm.controls.dataNascimento.value === ''">Campo Data de Nascimento é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row top-buffer btn-edit-container">
        <app-button-edit *ngIf="!editable" (onclick)="edit()" [label]="labelEdit"></app-button-edit>
        <app-button-edit (onclick)="onSubmit()" [label]="labelSave" *ngIf="editable"></app-button-edit>
        <app-button-edit [leftBuffer]="true" [primary]="false" *ngIf="editable" (onclick)="onCancel()"
          [label]="labelCancel"></app-button-edit>
      </div>
    </div>
  </div>
</form>