import {Observable} from 'rxjs';
import {BilletAlertsInfo} from '../../models/domain';
import {Injectable} from '@angular/core';
import {ApiServices} from './api.services';

@Injectable()
export class BilletsService {

  constructor(private apiService: ApiServices) {
  }

  searchBillets(cpf: string): Observable<BilletAlertsInfo[]> {
    return this.apiService.searchBillets(cpf);
  }
}
