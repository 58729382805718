<div class="container top-buffer">
  <table class="table-blue">
    <thead>
    <tr>
      <th class="container-title" colspan="11">
        <div class="table-title">Dívidas</div>
        <div class="table-sub-title">Histórico de dívidas do consumidor consultado.</div>
      </th>
    </tr>
    <tr>
      <th class="cursor" (click)="sortTable('id')">Divida ID<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('dataPesquisa')">Data de pesquisa<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('produto')">Produto<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('sistema')">Sistema<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('contrato')">Contrato<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('valorDivida')">Valor<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('status')">Status<span class="{{setaSort}}"></span></th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
    <tr></tr>
    </thead>
    <tbody *ngIf="debtList != undefined">
    <tr *ngFor="let debt of debtList| paginate : {
                id:'debt-list',
                itemsPerPage: size,
                currentPage: page,
                totalItems: debtList.length
              };">
      <td>{{ debt.id }}</td>
      <td>{{ debt.dataPesquisa|date:'dd/MM/yyyy':'America/Sao_Paulo'}}</td>
      <td class="text-capitalize">{{ debt.produto }}</td>
      <td class="text-capitalize">{{ debt.sistemaId.identificador }}</td> 
      <td>{{ debt.contrato }}</td>
      <td>{{ debt.valorDivida | currency:'R$' }}</td>
      <td><span [ngClass]="{'badge-concluido': debt.status === 0, 'badge-ativo': debt.status !== 0}" class="badge badge-pill badge-lg">{{ debt.descricaoStatus }}</span></td>
      <td><a class="lnk" href="javascript:void(0)" (click)="showModalDetail(debt)">Detalhes</a></td>
      <td><a class="lnk" href="javascript:void(0)" (click)="showModalPayment(debt)">Opções de pagamentos</a></td>
      <td *ngIf="debt.ultimoAcordoId != null && debt.ultimoAcordoId !== 0"><a class="lnk" href="javascript:void(0)" (click)="showModalDeal(debt.ultimoAcordoId)">Acordo</a></td>
      <td *ngIf="debt.ultimoAcordoId == null || debt.ultimoAcordoId === 0">-</td>
    </tr>
    <tr *ngIf="debtList.length == 0">
      <td colspan="11">Nenhuma dívida encontrada</td>
    </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-center top-buffer">
    <pagination-controls id="debt-list" [responsive]="false" [previousLabel]="labelPrevious" [nextLabel]="labelNext"
                         (pageChange)="tabSize($event)">
    </pagination-controls>
  </div>
</div>
