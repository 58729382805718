<app-search-by-cpf [titleValue]="searchTitle" (searchByCpfSubmit)="setCpf($event)"></app-search-by-cpf>

<div class="container" *ngIf="hashCode != ''">
  <div class="name-container">
    <div class="name-title">customer_id_hash: </div>
    <div class="name-sub-title">{{ hashCode }}</div>
  </div>
</div>

<app-loading *ngIf="loading" [backgroundBlue]="true"></app-loading>
<app-pending-search *ngIf="hashCode == ''"></app-pending-search>

