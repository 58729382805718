import { Component, OnInit } from '@angular/core';
import { DebtorService } from '../../../core/services/debtor.service';
import { DebtorInfo, Devedor, SuspdAcordo, SuspdDivida } from '../../../models/domain';
import { HelperService } from '../../../core/services/helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditLogService } from '../../../core/services/audit-log.service';
import { NavigationService } from '../../../core/services/navigationService';
import { DevedorResponse } from 'src/app/models/response/devedorDetalheResponse.model';
import { DevedorDetalheResponse, AcordoResponse, DividaResponse } from '../../../models/response/devedorDetalheResponse.model';

@Component({
  selector: 'app-search-debtor-by-email',
  templateUrl: './search-debtor-by-email.component.html',
  styleUrls: ['./search-debtor-by-email.component.scss']
})
export class SearchDebtorByEmailComponent implements OnInit {

  debtor?: DevedorResponse;
  dealList?: AcordoResponse[];
  debtList?: DividaResponse[];
  searchTitle = 'Consulte os acordos e dívidas por E-mail';
  showMenu = false;
  loading = false;
  private email: String = '';

  constructor(private debtorService: DebtorService,
    private activatedRoute: ActivatedRoute,
    private auditLogService: AuditLogService,
    private navigationService: NavigationService,
    private helperService: HelperService) {
  }

  public setEmail(value: String) {
    this.email = value;
    this.loading = true;
    this.debtorService.searchByEmail(value).subscribe(x => {
      this.loading = false;
      this.readDebtorInfo(x);
    }, error => {
      this.loading = false;
    });
  }


  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      if (params.get('email') != null) {
        this.setEmail(String(params.get('email')));
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    });

  }

  goBack(): void {
    this.debtor = undefined;
    this.dealList = undefined;
    this.debtList = undefined;
    this.auditLogService.clear();
    this.showMenu = true;
    this.helperService.showSubMenu();
    this.navigationService.back();

  }

  private readDebtorInfo(debtorInfo: DevedorDetalheResponse): void {
    this.debtor = debtorInfo.debtor;
    this.dealList = debtorInfo.deals;
    this.debtList = debtorInfo.debts;
    this.debtorService.loadAuditLogs(debtorInfo.debtor.id);
    this.helperService.hideSubMenu();
  }

}
