import { Component } from "@angular/core";

import { ReclameAquiResponse } from '../../models/response/ReclameAquiResponse.model';
import { ReclameAquiService } from '../../core/services/reclame-aqui.service';
import { HelperService } from '../../core/services/helper.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-reclame-aqui',
    templateUrl: './reclame-aqui.component.html',
    styleUrls: ['./reclame-aqui.component.scss']
})

export class ReclameAquiComponent {
    public email: String = '';
    public reclameAquiResponse: ReclameAquiResponse[] = [];
    public loading: boolean = false;
    public pendingeSearch: boolean = true;
    public searchTitlePhoneNumber: string = 'Pesquise por número de celular';
    public type: string = '';

    constructor(private reclameAquiService: ReclameAquiService,
        private helperService: HelperService) { }

    public setEmail(value: String) {
        this.email = value;
        this.loading = true;
        this.pendingeSearch = false;
        this.getCustomerEmailByEmail(this.email);
    }

    private getCustomerEmailByEmail(email: String): void {
        this.reclameAquiService.getCustomerEmailByEmail(email).subscribe(response => {
            this.loading = false;
            this.reclameAquiResponse = response;
        }, error => {
            this.loading = false;
            this.pendingeSearch = true;
            this.helperService.showError(error.error.message);
        });
    }

    public searchByPhoneNumberSubmit(phoneNumber: string): void {
        this.loading = true;
        this.pendingeSearch = false;
        this.reclameAquiService.getCustomerByPhone(phoneNumber)
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe({
                next: (response) => {
                    this.reclameAquiResponse = response;
                },
                error: (error) => {
                    this.pendingeSearch = true;
                    this.helperService.showError(error.error.message);
                }
            });
    }

    public typeOfSearch(type: string): void {
        this.type = type;
    }
}