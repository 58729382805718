import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {HelperService} from '../services/helper.service';
import {SuspdPoliticaCalculo} from '../../models/domain';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  title = 'Acordo Certo';

  userName: string | null | undefined;
  role: string | null | undefined;

  userIsLoggedIn = false;

  lastMenu: Menu | null;
  lastSubMenu: Menu | null;
  submenu: Menu[] | null;
  shouldHideSubMenu = false;
  menu: Menu[] = [
    new Menu('Consumidor', null, [
      new Menu('Consumidores', '/search-debtor-by-cpf', null),
      new Menu('E-mail', '/search-debtor-by-email', null),
      new Menu('Acordos', '/customer', null),
      new Menu('Preventivos', '/billets', null),
      new Menu('Blocklist', '/blocklist', null),
      new Menu('Acionamentos', '/activations', null),
      new Menu('Bloqueio', '/debt-blocking', null),
      new Menu('Dívidas ocultas', '/hidden-debt', null),
      new Menu('Reclame aqui', '/reclame-aqui', null),
    ]),
    new Menu('Parceiro', null, [
      new Menu('Parceiros', '/integrations', null),
      new Menu('Sistemas', '/systems', null),
      new Menu('Políticas', '/installment', null),
    ]),
    new Menu('Administrador', null, [
      new Menu('Perfil', '/userRole', null),
      new Menu('Marketplace', '/marketplace-admin', null),
    ]),
    new Menu('PII', null, [
      new Menu('Gerar Hash', '/search-hash-by-cpf', null),
      new Menu('Consulta CPF', '/search-cpf-by-hash', null),
    ]),
    new Menu('Relatórios', null, [
      new Menu('Geral', '/report', null),
      new Menu('LGPD', '/send-data', null),
    ]),
    new Menu('Jurídico', null, [
      new Menu('Políticas', '/privacy-policy', null),
      new Menu('Termos de uso', '/terms-of-use', null),
    ]),
  ];

  constructor(private router: Router, private authenticationService: AuthenticationService,
              private helperService: HelperService) {

    this.submenu = null;
    this.lastMenu = null;
    this.lastSubMenu = null;
  }

  public menuClick(which: Menu): void {
    if (which.menu != null) {
      this.lastMenu = which;
      this.lastSubMenu = null;
      this.submenu = which.menu;
      if (which.menu.length > 0) {
        this.subMenuClick(which.menu[0]);
      }
    } else if (which.link != null) {
      this.lastMenu = which;
      this.submenu = null;
      this.router.navigateByUrl('/dummy', {skipLocationChange: true}).then(() =>
        this.router.navigate([which.link])
      );
    }
  }

  subMenuClick(which: Menu): void {
    if (which.link != null) {
      this.lastSubMenu = which;
      this.router.navigateByUrl('/dummy', {skipLocationChange: true}).then(() =>
        this.router.navigate([which.link])
      );
    }
  }

  userHasAuthority(uri: string | null, menu: Menu[] | null): boolean {
    if (menu != null) {
      return menu.filter(subMenu => this.userHasAuthority(subMenu.link, null)).length > 0;
    } else {
      return uri == null || this.authenticationService.hasMenuAuthorityFor(uri);
    }
  }

  ngOnInit(): void {
    const username = this.authenticationService.getUserName();
    this.userIsLoggedIn = username != null && username !== '';
    this.userName = username;
    this.role = this.authenticationService.getRole();
    this.authenticationService.userLoginObservable.subscribe(username => {
      this.userIsLoggedIn = username != null && username !== '';
      this.userName = username;
      this.role = this.authenticationService.getRole();
    });
    this.helperService.shouldHideSubMenuObservable.subscribe(shouldHide => {
      this.shouldHideSubMenu = shouldHide;
    });
    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.checkSelectedMenu(x.urlAfterRedirects);
      }
    });
  }

  private checkSelectedMenu(url: string): void {
    const semicolonIndex = url.indexOf(';');
    if (semicolonIndex > -1) {
      url = url.substring(0, semicolonIndex);
    }
    this.helperService.showSubMenu();
    this.lastMenu = null;
    this.lastSubMenu = null;
    this.submenu = null;
    this.menu.forEach(x => {
      if (x.link === url) {
        this.lastMenu = x;
      } else if (x.menu != null) {
        x.menu.forEach(sub => {
          if (sub.link === url) {
            this.lastMenu = x;
            this.submenu = x.menu;
            this.lastSubMenu = sub;
          }
        });
      }
    });
  }

  logout(): void {
    this.authenticationService.logout();
  }
}

class Menu {

  title!: string;
  link: string | null;
  menu: Menu[] | null;

  constructor(title: string, link: string | null, menu: Menu[] | null) {
    this.title = title;
    this.link = link;
    this.menu = menu;
  }

}

@Component({
  selector: 'app-dummy',
  templateUrl: './dummy.component.html',
  styleUrls: ['./menu.component.scss']
})
export class DummyComponent {

}
