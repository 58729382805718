import {LOCALE_ID, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor, ErrorInterceptor} from '../../core/security/auth-interceptor';
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule, DatePipe} from '@angular/common';
import {NgxMaskModule} from 'ngx-mask';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastrModule} from 'ngx-toastr';
import {AcCustomerModule} from '../../ac-customer.module';
import {NgbDateBRParserFormatter} from '../../ngb-date-br-parser-formatter';
import {NgxPaginationModule, PaginatePipe} from 'ngx-pagination';
import {SearchByPartnerComponent} from './search-by-partner/search-by-partner.component';
import {SearchDebtorModule} from '../search-debtor/search-debtor.module';
import {InstallmentListComponent} from './modals/installment-list/installment-list.component';
import {InstallmentDetailListComponent} from './installment-detail-list/installment-detail-list.component';
import {InstallmentListEditComponent} from './modals/installment-list-edit/installment-list-edit.component';
import {InstallmentDetailListEditComponent} from './modals/installment-detail-list-edit/installment-detail-list-edit.component';
import {InstallmentBatchUpdateComponent} from './installment-batch-update/installment-batch-update.component';
import {InstallmentPolicyUpdateComponent} from './installment-policy-update/installment-policy-update.component';
import { InstallmentFilterComponent } from './modals/installment-filter/installment-filter.component';

@NgModule({
  declarations: [
    SearchByPartnerComponent,
    InstallmentListComponent,
    InstallmentDetailListComponent,
    InstallmentListEditComponent,
    InstallmentDetailListEditComponent,
    InstallmentBatchUpdateComponent,
    InstallmentPolicyUpdateComponent,
    InstallmentFilterComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AcCustomerModule,
    NgxPaginationModule,
    SearchDebtorModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: NgbDateParserFormatter, useClass: NgbDateBRParserFormatter},
    {provide: LOCALE_ID, useValue: 'pt'},
    DatePipe,
    PaginatePipe
  ],
})
export class SearchPartnerModule {
}
