<div class="container top-buffer" *ngIf="activationsList != null ">
  <table class="table-blue">
    <thead>
    <tr>
      <th colspan="6"></th>
    </tr>
    <tr>
      <th class="cursor" (click)="sortTable('documento')">Documento<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('parceiro')">Parceiro<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('data_acionamento')">Data de Acionamento<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('canal_acionamento')">Canal de Acionamento<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('contato')">Contato<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('observacao')">Observação<span class="{{setaSort}}"></span></th>
    </tr>
    <tr></tr>
    </thead>
    <tbody>
    <tr *ngFor="let activationsListInfo of activationsList | paginate : {
                itemsPerPage: size,
                currentPage: page,
                totalItems: activationsList.length
              };">
      <td>{{ activationsListInfo.documento }}</td>
      <td class="text-capitalize">{{ activationsListInfo.parceiro }}</td>
      <td>{{ activationsListInfo.data_acionamento | date:'dd/MM/yyyy'  }}</td>
      <td class="text-capitalize">{{ activationsListInfo.canal_acionamento }}</td>
      <td>{{ activationsListInfo.contato }}</td>
      <td>{{ activationsListInfo.observacao}}</td>
    </tr>
    <tr *ngIf="activationsList.length == 0">
      <td colspan="6">Nenhum registro encontrados</td>
    </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-center top-buffer">
    <pagination-controls [responsive]="false" [previousLabel]="labelPrevious" [nextLabel]="labelNext"
                         (pageChange)="tabSize($event)">
    </pagination-controls>
  </div>
</div>
