<app-search-by-hash [titleValue]="searchTitle" (searchByHashSubmit)="setHash($event)"></app-search-by-hash>

<div class="container top-buffer" *ngIf="cpfInfoResponse.length > 0">
  <table class="table-blue">
    <thead>
    <tr>
      <th class="container-title" colspan="11">
      </th>
    </tr>
    <tr>
      <th class="cursor">CPF</th>
      <th class="cursor">HASH</th>
    </tr>
    <tr></tr>
    </thead>
    <tbody>
      <tr *ngFor="let cpfToHash of cpfInfoResponse| paginate : {
                  id: 'hash-by-cpf',
                  itemsPerPage: size,
                  currentPage: page,
                  totalItems: cpfInfoResponse.length
               };">
        <td>{{cpfToHash.cpf}}</td>
        <td>{{cpfToHash.hash}}</td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-center top-buffer">
    <pagination-controls id="hash-by-cpf" [responsive]="false" [previousLabel]="labelPrevious" [nextLabel]="labelNext" (pageChange)="tabSize($event)">
    </pagination-controls>
  </div>
</div>

<app-loading *ngIf="loading" [backgroundBlue]="true"></app-loading>
<app-pending-search *ngIf="cpfInfoResponse.length == 0"></app-pending-search>


