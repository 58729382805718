import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AuthInterceptor, ErrorInterceptor} from './core/security/auth-interceptor';

import './models/domain';
import {SearchDebtorModule} from './modules/search-debtor/search-debtor.module';
import {CustomersModule} from './modules/customers/customers.module';
import {RolesModule} from './modules/roles/roles.module';
import {AcCustomerModule} from './ac-customer.module';

import localePt from '@angular/common/locales/pt';
import {registerLocaleData} from '@angular/common';
import {NgbDateBRParserFormatter} from './ngb-date-br-parser-formatter';
import {CipModule} from './modules/cip/cip.module';
import {BlocklistModule} from './modules/blocklist/blocklist.module';
import {ActivationsModule} from './modules/activations/activations.module';
import {BilletsModule} from './modules/billets/billets.module';
import {IntegrationsModule} from './modules/integrations/integrations.module';
import {SearchPartnerModule} from './modules/partner/search-partner.module';
import {SystemsModule} from './modules/systems/systems.module';
import { SendDataModule } from './modules/send-data/send-data.component';
import { DebtBlockingModule } from './modules/debt-blocking/debt-blocking.component';
import { MarketplaceAdminModule } from './modules/marketplace-admin/marketplace-admin.module';
import { HiddenDebtModule } from './modules/hidden-debt/hidden-debt.module';
import { ReclameAquiModule } from './modules/reclame-aqui/reclame-aqui.module';
import { JuridicalModule } from './modules/juridical/juridical.module';
import { LoadComponent } from './tools/load/load.component';


registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    LoadComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AcCustomerModule,
    SearchDebtorModule,
    CustomersModule,
    RolesModule,
    CipModule,
    BlocklistModule,
    ActivationsModule,
    IntegrationsModule,
    SystemsModule,
    SearchPartnerModule,
    BilletsModule,
    SendDataModule,
    DebtBlockingModule,
    MarketplaceAdminModule,
    HiddenDebtModule,
    ReclameAquiModule,
    JuridicalModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: NgbDateParserFormatter, useClass: NgbDateBRParserFormatter},
    {provide: LOCALE_ID, useValue: 'pt'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
