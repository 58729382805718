import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiServices} from './api.services';
import {SuspdCredor, SuspdCredorConfiguracao} from '../../models/domain';
import {AuditLogService} from './audit-log.service';

@Injectable()
export class CreditorService {

  constructor(protected apiService: ApiServices, private auditLogService: AuditLogService) {
  }

  getCreditors(): Observable<SuspdCredor[]> {
    return this.apiService.getCreditors();
  }

  updateStatus(creditor: SuspdCredor, status: number): Observable<SuspdCredor> {
    return this.apiService.updateStatus(creditor, status);
  }

  update(id: number, creditor: SuspdCredor): Observable<SuspdCredor> {
    return this.apiService.updateCreditor(id, creditor);
  }

  create(creditor: SuspdCredor): Observable<SuspdCredor> {
    return this.apiService.createCreditor(creditor);
  }

  loadConfiguration(creditorId: number): Observable<SuspdCredorConfiguracao[]> {
    return this.apiService.loadCreditorConfiguration(creditorId);
  }

  createOrUpdate(creditorId: number, configuration: SuspdCredorConfiguracao[]): Observable<SuspdCredorConfiguracao[]> {
    return this.apiService.createOrUpdateConfiguration(creditorId, configuration);
  }

  delete(id: number): Observable<SuspdCredor> {
    return this.apiService.deleteCreditor(id);
  }

  loadAuditLogs(id: number): void {
    this.apiService.loadCreditorAuditLog(id).subscribe(logs => {
      this.auditLogService.emitLog(logs);
    });
  }
}
