import {Component, OnInit} from '@angular/core';
import {CreditorService} from '../../../core/services/creditor.service';
import {SuspdCredor} from '../../../models/domain';

@Component({
  selector: 'app-search-integrations',
  templateUrl: './search-integrations.component.html',
  styleUrls: ['./search-integrations.component.scss']
})

export class SearchIntegrationsComponent implements OnInit {
  titleSearch = 'Selecione o Parceiro';

  loading = false;
  insert = false;
  creditors: SuspdCredor[] = [];
  creditor: SuspdCredor | null = null;

  constructor(private creditorService: CreditorService) {
  }

  public getDescription(creditor: SuspdCredor): string {
    return `${creditor.nome} (${creditor.identificador})`;
  }

  searchSubmit(val: SuspdCredor): void {
    this.creditor = val;
  }

  ngOnInit(): void {
    this.getCreditorsList();
  }

  getCreditorsList(): void {
    this.loading = true;
    this.creditorService.getCreditors().subscribe(creditors => {
      this.loading = false;
      this.creditors = creditors;
    }, err => {
      this.loading = false;
    });
  }

  insertClick(): void {
    this.insert = true;
  }

  editPartnerCanceled(): void {
    this.insert = false;
  }

  editPartnerSaved(savedSuspdCredor: SuspdCredor): void {
    this.insert = false;
    this.getCreditorsList();
    this.searchSubmit(savedSuspdCredor);
  }

  editPartner(selectedSuspdCredor: SuspdCredor): void {
    this.creditor = selectedSuspdCredor;
    this.insert = true;
  }

  deletePartner(deletedSuspdCredor: SuspdCredor): void {
    this.insert = false;
    this.creditor = null;
    this.getCreditorsList();
  }
}
