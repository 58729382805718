import { Component, OnInit } from '@angular/core';
import { JuridicalService } from 'src/app/core/services/juridical.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

  terms = [];
  term: any;
  loading = false;
  insert = false;

  constructor(private juridicalService: JuridicalService) {}

  ngOnInit(): void {
    this.getTerms();
  }

  getTerms() {
    this.juridicalService.getTermsOfUse().subscribe(
      (ret) => {
        this.terms = ret.sort(function compare(a: any, b: any) {
          if (parseFloat(a.version) > parseFloat(b.version)) return -1;
          if (parseFloat(a.version) < parseFloat(b.version)) return 1;
          return 0;
      })
      },
      (err) => {
      }
    );
  }

  insertClick(): void {
    this.term = undefined;
    this.insert = true;
  }

  setTerm(term: any): void {
    this.term = term;
    this.insert = true;
  }

  cancelEdit(term: any): void {
    this.term = undefined;
    this.insert = false;
  }

  saveTerm() {
    this.term = undefined;
    this.insert = false;
    this.getTerms();
  }

  published() {
    this.getTerms();
  }
}
