import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SuspdDivida, SuspdDividaDetalhe} from '../../../../models/domain';
import {DebtService} from '../../../../core/services/debt.service';
import {sortBy} from 'sort-by-typescript';

@Component({
  selector: 'app-debt-detail-status',
  templateUrl: './debt-detail.component.html',
  styleUrls: ['./debt-detail.component.scss']
})
export class DebtDetailComponent {

  debt?: SuspdDivida;
  debtDetailList?: SuspdDividaDetalhe[];
  public debtDetailListSort: any;
  public sort: any;
  public desc = false;
  public setaSort: any;
  loading = true;

  constructor(public activeModal: NgbActiveModal, private debtService: DebtService) {
  }

  @Input()
  public setDebtValue(debt: SuspdDivida): void {
    this.debt = debt;
    if (debt.id != null) {
      this.debtService.getDetailList(debt.id).subscribe(x => {
        this.loading = false;
        this.debtDetailList = x;
        this.debtDetailListSort = this.debtDetailList;
      }, err => {
        this.loading = false;
      });
    }
  }

  sortTable(sort: string): void {
    if(this.sort === sort && this.desc === false){
      this.desc = true;
      this.setaSort = 'seta-cima';
    }else{
      this.desc = false;
      this.setaSort = 'seta-baixo';
    }
    this.sort = sort;
    if(this.desc){
      this.debtDetailListSort.sort(sortBy('-'.concat(sort)));
    }else{
      this.debtDetailListSort.sort(sortBy(sort));
    }
  }
}
