<form [formGroup]="blockRequestForm">
  <div class="container">
    <div class="block-register middle-aligned-container align-items-center">
      <div class="title-search">Bloqueio de dívidas</div>
      <div class="row">
        <div class="data-title">CPF:</div>
        <input type="text" class="form-control input-block" placeholder="000.000.000-00" mask="000.000.000-00"
          formControlName="document">
      </div>
      <div class="row">
        <div class="data-title">Razão:</div>
        <input type="text" class="form-control input-block" formControlName="reason">
      </div>
      <div class="row">
        <div class="data-title">Parceiro:</div>
        <select class="select-debt-blocking input-block" formControlName="creditorId">
          <option *ngFor="let option of creditors" [ngValue]="option.id">{{ option.nome }}</option>
        </select>
      </div>
      <div class="row button-submit-block">
        <app-button-edit (onclick)="onSubmit()" label="ENVIAR" [inverted]="true"></app-button-edit>
      </div>
    </div>
  </div>
</form>

<app-table-debt-blocking [blocks]="blocks"></app-table-debt-blocking>
