import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CreditorService} from '../../../core/services/creditor.service';
import {SuspdCredor} from '../../../models/domain';
import {AuditLogService} from '../../../core/services/audit-log.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EditConfigurationComponent} from '../modals/edit-configuration/edit-configuration.component';
import {ConfirmComponent} from '../../shared/confirm/confirm.component';
import {HelperService} from '../../../core/services/helper.service';
import {AuthenticationService} from '../../../core/services/authentication.service';

@Component({
  selector: 'app-edit-integrations',
  templateUrl: './edit-integrations.component.html',
  styleUrls: ['./edit-integrations.component.scss']
})

export class EditIntegrationsComponent {

  creditorValue: SuspdCredor | null = null;
  saving = false;
  @Output() onedit = new EventEmitter<SuspdCredor>();
  @Output() ondelete = new EventEmitter<SuspdCredor>();

  constructor(private creditorService: CreditorService, private auditService: AuditLogService, private modalService: NgbModal,
              private helperService: HelperService, private authService: AuthenticationService) {
  }

  @Input()
  set creditor(creditor: SuspdCredor) {
    this.creditorValue = creditor;
    if (creditor.id != null) {
      this.auditService.clear();
      this.creditorService.loadAuditLogs(creditor.id);
    }
  }

  creditorChange(creditor: SuspdCredor): void {
    if (this.saving) {
      return;
    }
    this.saving = true;
    this.creditorService.updateStatus(creditor, creditor.status === 1 ? 0 : 1).subscribe(updated => {
      this.saving = false;
      creditor.status = updated.status;
    });
  }

  editClick(creditorValue: SuspdCredor): void {
    if (this.saving) {
      return;
    }
    this.onedit.emit(creditorValue);
  }

  editConfigClick(creditorValue: SuspdCredor): void {
    if (this.saving) {
      return;
    }
    const modalRef = this.modalService.open(EditConfigurationComponent, {windowClass: 'large-modal', backdrop: 'static'});
    modalRef.shown.subscribe(x => {
      modalRef.componentInstance.creditor = creditorValue;
    });
    modalRef.closed.subscribe(x => {
      console.log(x);
    });
  }

  deleteClick(creditorValue: SuspdCredor): void {
    if (this.saving) {
      return;
    }
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.pergunta = `Confirma exclusão do parceiro ${creditorValue.nome}?`;
    modalRef.closed.subscribe(x => {
      if (x) {
        this.saving = true;
        if (creditorValue.id != null) {
          this.creditorService.delete(creditorValue.id).subscribe(x => {
            this.saving = false;
            this.helperService.showMessage('Parceiro excluído com sucesso!');
            this.ondelete.emit(creditorValue);
          });
        }
      }
    });
  }

  canEdit(): boolean {
    return this.authService.hasAuthority('/creditor/post') && this.authService.hasAuthority('/creditor/put');
  }

  canDelete(): boolean {
    return this.authService.hasAuthority('/creditor/delete');
  }
}
