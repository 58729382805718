import { Component, Input } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-privacy-policy',
  templateUrl: './view-privacy-policy.component.html',
  styleUrls: ['./view-privacy-policy.component.scss'],
})
export class ViewPrivacyPolicyComponent {
  @Input() policy: any;
  constructor(public activeModal: NgbActiveModal) {}
}
