<div class="view">
  <div class="view-title">
    <h2>Termos de Uso</h2>
  </div>
  <div class="view-body">
    <div class="row">
      <div class="col-6">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">Versão</span>
          </div>
          <input
            type="text"
            class="form-control"
            [value]="term.version"
            disabled
          />
        </div>
      </div>
      <div class="col-6">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">Data de Criação</span>
          </div>
          <input
            type="text"
            class="form-control"
            [value]="term.createdAt |  date : 'dd/MM/yyyy HH:mm:ss'"
            disabled
          />
        </div>
      </div>
      <div class="col-12 mt-4">
        <div class="view-content">
          <div [innerHTML]="term.content"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="view-footer">
    <button
      class="btn btn-primary btn-rounded"(click)="activeModal.close(false)">
      Fechar
    </button>
  </div>
</div>
