import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiServices } from './api.services';
import { DividaOcultaResponse } from 'src/app/models/response/DividaOcultaResponse.model';

@Injectable()
export class HiddenDebtService {

  constructor(private apiService: ApiServices) {
  }

  getHiddenDebts(cpf: string): Observable<DividaOcultaResponse[]> {
    return this.apiService.getHiddenDebts(cpf);
  }

  deleteHiddenDebt(hiddenDebtId: number): Observable<DividaOcultaResponse[]> {
    return this.apiService.deleteHiddenDebt(hiddenDebtId);
  }

}