import {Observable} from 'rxjs';
import {ActivationsInfo} from '../../models/domain';
import {Injectable} from '@angular/core';
import {ApiServices} from './api.services';

@Injectable()
export class ActivationsService {

  constructor(private apiService: ApiServices) {
  }

  searchActivations(cpfOrContact: string): Observable<ActivationsInfo[]> {
    return this.apiService.searchActivations(cpfOrContact);
  }
}
