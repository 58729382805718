import {Component, Input} from '@angular/core';
import {sortBy} from 'sort-by-typescript';
import {BilletAlertsInfo} from '../../../models/domain';
import {MaskPipe} from 'ngx-mask';

@Component({
  selector: 'app-detalhe-billets',
  templateUrl: './detalhe-consulta-billets.component.html',
  styleUrls: ['./detalhe-consulta-billets.component.scss']
})

export class DetalheConsultaBilletsComponent {

  @Input() billetsInfoList: BilletAlertsInfo[] | undefined;

  @Input() size = 10;
  @Input() page = 1;

  labelPrevious = 'Ant.';
  labelNext = 'Próx.';
  public sort: any;
  public desc = false;
  public setaSort = 'seta-cima';
  public BilletsInfos: any;

  constructor(private maskPipe: MaskPipe) {
  }

  tabSize($event: number): void {
    this.page = $event;
  }


  sortTable(sort: string): void {
    this.BilletsInfos = this.billetsInfoList;
    if(this.sort === sort && this.desc === false){
      this.desc = true;
      this.setaSort = 'seta-cima';
    }else{
      this.desc = false;
      this.setaSort = 'seta-baixo';
    }
    this.sort = sort;
    if(this.desc){
      this.BilletsInfos.sort(sortBy('-'.concat(sort)));
    }else{
      this.BilletsInfos.sort(sortBy(sort));
    }
  }

  getFormattedContact(billetsInfoListInfo: BilletAlertsInfo): string {
    if (billetsInfoListInfo.contact != null && billetsInfoListInfo.contact_type != null && billetsInfoListInfo.contact !== '') {
      if (billetsInfoListInfo.contact_type === 'SMS') {
        return this.maskPipe.transform(billetsInfoListInfo.contact, 
          billetsInfoListInfo.contact.length == 10 ? '(00) 0000-0000' : '(00) 00000-0000');
      } else if (billetsInfoListInfo.contact_type === 'EMAIL') {
        return billetsInfoListInfo.contact.toLowerCase();
      }
      return billetsInfoListInfo.contact;
    }
    return '-';
  }
}
