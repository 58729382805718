import { Observable } from 'rxjs';
import { Block, BlockRequest, BlockConvert } from '../../models/domain';
import { Injectable } from '@angular/core';
import { ApiServices } from './api.services';

@Injectable()
export class DebtBlockingService {

  constructor(private apiService: ApiServices) {
  }

  includeToBlock(blockRequest: BlockRequest): Observable<BlockRequest> {
    return this.apiService.includeToBlock(blockRequest);
  }

  getBlock(): Observable<BlockConvert[]> {
    return this.apiService.getBlock();
  }

  removeFromBlock(block: number): Observable<Block> {
    return this.apiService.removeFromBlock(block);
  }

  fetchExistingBlock(cpf: string): Observable<BlockConvert[]> {
    return this.apiService.fetchExistingBlock(cpf);
  }
}
