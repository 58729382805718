<div class="modal-header">
  <h4 class="modal-title">Detalhe da dívida</h4>
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-loading [backgroundBlue]="true" *ngIf="loading"></app-loading>
  <table class="table-blue" *ngIf="!loading">
    <thead>
    <tr><th colspan="9"></th></tr>
    <tr>
      <th class="cursor" (click)="sortTable('contrato')">Contrato<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('dataOriginalDivida')">Data Dívida<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('diasAtraso')">Atraso<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('parcela')">Parcela<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('valorOriginal')">Valor Original<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('valorJuros')">Juros<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('qtdParcelas')">Qtd Parcelas<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('descricao')">Descrição<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('origem')">Origem<span class="{{setaSort}}"></span></th>
    </tr>
    <tr></tr>
    </thead>
    <tbody *ngIf="debtDetailList != undefined">
    <tr *ngFor="let debtDetail of debtDetailList">
      <td>{{ debtDetail.contrato }}</td>
      <td>{{ debtDetail.dataOriginalDivida | date:'dd/MM/yyyy' }}</td>
      <td>{{ debtDetail.diasAtraso }}</td>
      <td>{{ debtDetail.parcela }}</td>
      <td>{{ debtDetail.valorOriginal | currency:'R$' }}</td>
      <td>{{ debtDetail.valorJuros | currency:'R$' }}</td>
      <td>{{ debtDetail.qtdParcelas }}</td>
      <td>{{ debtDetail.descricao }}</td>
      <td>{{ debtDetail.origem }}</td>
    </tr>
    <tr *ngIf="debtDetailList.length == 0">
      <td colspan="9">Nenhum registro encontrado</td>
    </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button (click)="activeModal.close('Close click')" class="btn btn-outline-dark" type="button">Fechar</button>
</div>
