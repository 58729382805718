<div class="large-modal">
  <div class="modal-header">
    <button (click)="activeModal.dismiss('Cross click')" class="button-close">
      <img src="../../../../../assets/svg/icons/close.svg" alt="icone branco em formato de X para indicar fechamento do modal" />
    </button>
    <h4>Dívidas | Filtrar resultados</h4>
  </div>
  <div class="modal-form">
    <form [formGroup]="installmentFilter">
      <div class="container-form">
        <div class="container-input">
          <h4 class="container-input-title">Nome da carteira</h4>
          <div *ngIf="installmentFilter.get('key')?.errors?.invalidKey">
            <p class="info-error">
              Ops, não encontramos nenhuma carteira com este nome. Verifique a
              escrita e tente novamente.
            </p>
          </div>
          <div>
            <label>Nome da carteira:</label>
            <app-input-varchar
              formControlName="key"
              className="form-input-text"
            ></app-input-varchar>
          </div>
        </div>
        <div class="border-dashed"></div>
        <div class="container-input">
          <h4 class="container-input-title">Dias de atraso</h4>
          <div class="container-input-range">
            <div>
              <label>De</label>
              <app-input-varchar
                [maxLength]="7"
                formControlName="daysOverdueIni"
                className="input-range"
              ></app-input-varchar>
            </div>
            <div>
              <label>Até</label>
              <app-input-varchar
                [maxLength]="7"
                formControlName="daysOverdueEnd"
                className="input-range"
              ></app-input-varchar>
            </div>
          </div>
        </div>
        <div class="border-dashed"></div>
        <div class="container-input">
          <h4 class="container-input-title">Valor da dívida</h4>
          <div class="container-input-range">
            <div>
              <label>De</label>
              <app-input-decimal
                formControlName="debtValueIni"
                className="input-range"
              ></app-input-decimal>
            </div>
            <div>
              <label>Até</label>
              <app-input-decimal
                formControlName="debtValueEnd"
                className="input-range"
              ></app-input-decimal>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <app-button-edit
      (onclick)="cleanFilter()"
      [secundaryInvert]="true"
      className="btn-aplication-filter"
      label="Limpar filtros"
    >
    </app-button-edit>
    <div class="container-button-apply">
      <div *ngIf="Isloading" class="spinner"></div>
      <app-button-edit
        (onclick)="getFilteredPolicies()"
        label="{{ getButtonTitle() }}"
        [disabled]="isDisabled"
        [secundary]="true"
        [secundaryDisabled]="isDisabled ? true : false"
        [secundaryLoading]="Isloading ? true : false"
      > </app-button-edit>
    </div>
  </div>
</div>
