<app-search-select *ngIf="!loading && !batchUpdate && !installmentPolicyUpdate" (searchSubmit)="searchSubmit($event)" [searchTitle]="titleSearch" [large]="true" [data]="creditors" [getDescriptionFn]="getDescription"></app-search-select>

<app-loading *ngIf="loading" [backgroundBlue]="true"></app-loading>
<app-politic-calc-batch-update *ngIf="!loading && batchUpdate" [installmentPolicyList]="installmentList" [creditor]="creditor"
                               (onsave)="onBatchUpdateSave()" (oncancel)="onBatchUpdateCancel()"></app-politic-calc-batch-update>


<app-installment-policy-update *ngIf="!loading && installmentPolicyUpdate" [installmentPolicyList]="installmentList" [creditor]="creditor"
                               (onsave)="onInstallmentPolicyUpdate()" (oncancel)="onInstallmentPolicyUpdateCancel()"></app-installment-policy-update>

<app-installment-calc-list [politicCalcListValue]="installmentList" [creditor]="creditor" 
(onSaveSuspdPoliticaParcelamento)="onSaveSuspdPoliticaParcelamento()"  
(onBatchUpdate)="onBatchUpdate()" 
(onInstallmentPolicyUpdate)="onInstallmentPolicyUpdate()" 
*ngIf="!loading && installmentList != undefined && !batchUpdate && !installmentPolicyUpdate"></app-installment-calc-list>
<app-audit-log [isCustomer]="true" *ngIf="!loading && installmentList != undefined && !batchUpdate && !installmentPolicyUpdate"></app-audit-log>
