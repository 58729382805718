import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiServices } from './api.services';
import { LgpdReportResponse, GenerateLgpdReportRequest } from '../../models/domain';

@Injectable()
export class SendDataService {

  constructor(private apiService: ApiServices) {
  }

  sendParametersToAnubis(sendData: GenerateLgpdReportRequest): Observable<any> {
    return this.apiService.sendParametersToAnubis(sendData);
  }

  getLastDayReports(): Observable<LgpdReportResponse[]> {
    return this.apiService.getLastDayReports();
  }
}