import {Component, EventEmitter, Input, Output} from '@angular/core';
import {HelperService} from '../../../core/services/helper.service';
import {NavigationService} from '../../../core/services/navigationService';

@Component({
  selector: 'app-search-by-hash',
  templateUrl: './search-by-hash.component.html'
})
export class SearchByHashComponent {
  @Output() searchByHashSubmit: EventEmitter<string> = new EventEmitter();
  title = '';
  searchMask = 'Adicione o hash';

  constructor(public helperService: HelperService,
              private navigationService: NavigationService) {
  }

  @Input()
  set titleValue(value: string) {
    this.title = value;
  }

  onSubmit(val: string): void {
    this.navigationService.populate('/search-debtor-by-cpf', '');
    this.searchByHashSubmit.emit(String(val));
  }

}
