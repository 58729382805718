<div class="container top-buffer">
  <table class="table-blue">
    <caption class="description"></caption>
    <thead>
      <tr>
        <th scope="col" class="container-title" colspan="7">
          <div class="table-title">Políticas de Privacidade</div>
        </th>
      </tr>
      <tr>
        <th scope="col" class="text-center">Id</th>
        <th scope="col" class="text-center">Versão</th>
        <th scope="col" class="text-center">Data de criação</th>
        <th scope="col" class="text-center">Publicado</th>
        <th scope="col" class="text-center">Data de Publicação</th>
        <th scope="col" colspan="2" class="text-center"></th>
      </tr>
      <tr></tr>
    </thead>
    <tbody *ngIf="policies.length > 0">
      <tr *ngFor="let policy of policies| paginate : {
        id:'policiesList',
        itemsPerPage: size,
        currentPage: page,
        totalItems: policies.length
      };" [ngClass]="{'published': policy.published}">
        <td class="text-center">{{ policy.id }}</td>
        <td class="text-center">{{ policy.version }}</td>
        <td class="text-center">{{ policy.createdAt | date : "dd/MM/yyyy HH:mm:ss" }}</td>
        <td class="text-center">{{ policy.published ? "Sim" : "" }}</td>
        <td class="text-center">{{ policy.publishedAt | date : "dd/MM/yyyy HH:mm:ss" }}</td>
        <td class="text-center">
          <a href="javascript:void(0)" (click)="viewContent(policy)">Visualizar</a>
        </td>
        <td class="text-center">
          <a href="javascript:void(0)" (click)="onedit.emit(policy)">Criar Derivado</a>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="policies.length === 0">
      <tr>
        <td colspan="7" class="text-center">Nenhum resultado</td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-center top-buffer">
    <pagination-controls id="policiesList" [responsive]="false" [previousLabel]="'Ant.'" [nextLabel]="'Prox.'"
                         (pageChange)="tabSize($event)">
    </pagination-controls>
  </div>
</div>
