import { Component, Input } from "@angular/core";
import { ReclameAquiResponse } from '../../../models/response/ReclameAquiResponse.model';

@Component({
    selector: 'app-table-reclame-aqui',
    templateUrl: './table-reclame-aqui.component.html',
    styleUrls: ['./table-reclame-aqui.component.scss']
})

export class TableReclameAquiComponent {
    @Input()
    public customers: ReclameAquiResponse[] = [];
    @Input()
    public type: string = '';

    size = 10;
    page = 1;

    tabSize($event: number): void {
        this.page = $event;
    }
}