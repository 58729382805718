import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-input-decimal',
  templateUrl: './decimal.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DecimalComponent)
    }
  ]
})
export class DecimalComponent implements ControlValueAccessor {
  input: any;

  @Input() className = 'form-input';
  @Input() maxLength = 24;

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: any): void {
    this.input = input;
  }

}
