import { Observable } from 'rxjs';
import { BlockListInfo } from '../../models/domain';
import { Injectable } from '@angular/core';
import { ApiServices } from './api.services';

@Injectable()
export class BlocklistService {

  constructor(private apiService: ApiServices) {
  }

  searchBlocklist(cpfOrPhoneNumberOrEmail: string): Observable<BlockListInfo[]> {
    var isEmail = this.checkIsEmail(cpfOrPhoneNumberOrEmail);
    if (isEmail) {
      return this.apiService.searchBlocklistByEmail(cpfOrPhoneNumberOrEmail);
    } else {
      return this.apiService.searchBlocklistByCpfOrPhoneNumber(cpfOrPhoneNumberOrEmail);
    }
  }

  checkIsEmail(value: string): Boolean {      
    let isMatch = value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);
    return !!isMatch && isMatch.length > 0;
  }

}
