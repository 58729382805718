import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DatePipe} from '@angular/common';

@Injectable()
export class NgbDateBRParserFormatter extends NgbDateParserFormatter {

  constructor(private datePipe: DatePipe) {
    super();
  }

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const m = this.parseDate(value);
      if (m) {
        return {year: m.year, month: m.month, day: m.day};
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    let value;
    if (date) {
      value = this.datePipe.transform(new Date(date.year, date.month - 1, date.day), 'dd/MM/yyyy');
    }
    return value || '';
  }

  private parseDate(val: string): any {
    const matcher = val.match(/^(([012]\d)|([3][01]))\/((0\d)|(1[012]))\/(\d{4})$/);
    if (matcher) {
      return {year: Number(matcher[7]), month: Number(matcher[4]), day: Number(matcher[1])};
    }
    return null;
  }
}
