import { Component, Input } from '@angular/core';
import { BlockConvert } from '../../../models/domain';
import { DebtBlockingService } from '../../../core/services/debt-blocking.service';
import { HelperService } from '../../../core/services/helper.service';

@Component({
  selector: 'app-table-debt-blocking',
  templateUrl: './table-debt-blocking.component.html',
})

export class TableDebtBlockingComponent {

  @Input() blocks: BlockConvert[] = [];

  size = 10;
  page = 1;

  constructor(private debtBlockingService: DebtBlockingService, private helperService: HelperService) { }

  tabSize($event: number): void {
    this.page = $event;
  }

  removeFromBlock(block: BlockConvert) {
    this.debtBlockingService.removeFromBlock(block.id).subscribe(blockResponse => {
      this.helperService.showMessage(`CPF: ${blockResponse.customerId} foi removido da lista de bloqueio`);
      this.getBlock();
    })
  }

  getBlock() {
    this.debtBlockingService.getBlock().subscribe(response => {
      this.blocks = response;
    });
  }
}
