import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiServices} from './api.services';
import { AcordoResponse } from '../../models/response/acordo-response.model';

@Injectable()
export class CustomerService {

  constructor(
    protected apiService: ApiServices
  ) {
  }

  getCustomerByDate(date: string): Observable<AcordoResponse[]> {
    return this.apiService.getCustomerByDate(date);
  }


}
