<div class="container top-buffer container-reclame-aqui">
    <table class="table-blue" [ngSwitch]="type">
        <caption class="description">Consumidor</caption>
        <thead>
            <tr>
                <th scope="col" class="container-title" colspan="11">
                    <div class="table-title">Dívidas bloqueadas</div>
                </th>
            </tr>
            <tr>
                <th scope="col" class="cursor">Nome</th>
                <th scope="col" class="cursor" *ngSwitchCase="'email'">E-mail</th>
                <th scope="col" class="cursor" *ngSwitchCase="'email'">E-mail hash</th>
                <th scope="col" class="cursor" *ngSwitchCase="'celular'">Celular</th>
                <th scope="col" class="cursor" *ngSwitchCase="'celular'">Celular hash</th>
                <th scope="col" class="cursor">Hash CPF</th>
                <th scope="col" class="cursor">Fonte</th>
                <th scope="col" class="cursor">Data de importação</th>
                <th scope="col" class="cursor">Parceiro com dívidas</th>
                <th scope="col" class="cursor">Ativações (ultimos 6 meses)</th>
            </tr>
            <tr></tr>
        </thead>
        <tbody *ngIf="customers != null && customers != undefined">
            <tr *ngFor="let customer of customers| paginate: {
                id: 'customer-list',
                itemsPerPage: size,
                currentPage: page,
                totalItems: customers.length
            } ">
                <td>{{ customer.fullName }}</td>
                <td *ngSwitchCase="'email'">{{ customer.email }}</td>
                <td *ngSwitchCase="'email'">{{ customer.emailHash }}</td>
                <td *ngSwitchCase="'celular'">{{ customer.phoneNumber }}</td>
                <td *ngSwitchCase="'celular'">{{ customer.phoneNumberHash }}</td>
                <td>{{ customer.customerHashId }}</td>
                <td>{{ customer.source }}</td>
                <td>{{ customer.sourceImportDate | date:'dd/MM/yyyy' }}</td>
                <td>{{ customer.partnerDebts }}</td>
                <td>{{ customer.countActivationsLastSixMonth }}</td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-center top-buffer">
        <pagination-controls id="customer-list" [responsive]="false" previousLabel="Ant." nextLabel="Próx."
            (pageChange)="tabSize($event)">
        </pagination-controls>
    </div>
</div>