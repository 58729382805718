import {Component, EventEmitter, Input, Output} from '@angular/core';

import {NavigationService} from '../../../core/services/navigationService';
import {HelperService} from '../../../core/services/helper.service';

@Component({
  selector: 'app-search-by-phone-number',
  templateUrl: './search-by-phone.component.html'
})
export class SearchByPhoneNumberComponent {
  @Output() searchByPhoneNumberSubmit: EventEmitter<string> = new EventEmitter();
  title = '';
  phoneNumberMaskPlaceholder: string = 'Digite um telefone';
  phoneNumberMask: string = '00 00000-0000';

  constructor(public helperService: HelperService,
              private navigationService: NavigationService) {
  }

  @Input()
  set titleValue(value: string) {
    this.title = value;
  }

  onSubmit(val: string): void {
    this.navigationService.populate('/reclame-aqui', '');
    this.searchByPhoneNumberSubmit.emit(String(val));
  }
}
