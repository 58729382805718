import { tap, finalize, catchError } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MaskPipe } from 'ngx-mask';
import * as moment from 'moment';
import { of } from 'rxjs';

import { DevedorResponse, EmailResponse, TelefoneResponse } from '../../../models/response/devedorDetalheResponse.model';
import { DebtorService } from '../../../core/services/debtor.service';
import { HelperService } from '../../../core/services/helper.service';
import { isDateBefore } from '../../../core/validator/date.validator';
import CpfUtils from '../../../core/services/utils/cpf.utils';

@Component({
  selector: 'app-debtor',
  templateUrl: './debtor.component.html',
  styleUrls: ['./debtor.component.scss']
})
export class DebtorComponent {

  labelEdit = 'Editar Dados';
  labelSave = 'OK, CONFIRMAR';
  labelCancel = 'CANCELAR EDIÇÕES';

  @Input()
  debtor: DevedorResponse = {} as DevedorResponse;

  editable = false;
  loadingTitle = 'Aguarde';
  loading = false;

  debtorForm = this.formBuilder.group({
    status: ['', [Validators.required]],
    cpf: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.min(1), Validators.email]],
    ddd: ['', [Validators.required, Validators.min(1)]],
    telefone: ['', [Validators.required, Validators.min(8)]],
    socialName: ['', ''],
    dataNascimento: ['', [Validators.required, isDateBefore(new Date())]],
    nome: ['', [Validators.required]],
  });

  constructor(private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private debtorService: DebtorService,
    private helperService: HelperService,
    private mask: MaskPipe
  ) { }

  get email(): EmailResponse {
    return this.debtor.emails.filter(x => x.principal === 1)[0];
  }

  get telefone(): TelefoneResponse {
    return this.debtor.telefones.filter(x => x.principal === 1)[0] || {} as TelefoneResponse;
  }

  edit(): void {
    this.loading = true;
    this.debtorForm.setValue({
      status: this.debtor.status,
      cpf: this.debtor.cpf,
      email: this.email?.email,
      ddd: this.telefone?.ddd,
      telefone: this.telefone?.telefone,
      socialName: this.debtor?.socialName,
      dataNascimento: this.datePipe.transform(this.debtor.dataNascimento, 'ddMMyyyy'),
      nome: this.debtor.nome,
    });
    this.loading = false;
    this.editable = true;
  }

  onSubmit(): void {
    this.loading = true;
    if (this.isFormValid() && this.cpfIsValid()) {
      this.createRequestDebtor();
    } else {
      this.loading = false;
      this.helperService.showError('Dados Invalidos');
    }
  }

  private isFormValid(): boolean {
    return this.debtorForm.valid;
  }

  private cpfIsValid(): boolean {
    return CpfUtils.isValid(this.debtorForm.value.cpf);
  }

  private createRequestDebtor(): void {
    const formDebtorValues = this.debtorForm.value;
    this.debtor.socialName = formDebtorValues.socialName;
    this.debtor.status = formDebtorValues.status;
    this.debtor.nome = formDebtorValues.nome;
    this.debtor.cpf = this.mask.transform(formDebtorValues.cpf, '000.000.000-00');
    if (this.email != null) {
      this.email.email = formDebtorValues.email;
    }
    if (this.telefone != null) {
      this.telefone.ddd = formDebtorValues.ddd;
      this.telefone.telefone = formDebtorValues.telefone;
    }
    this.debtor.dataNascimento = moment(formDebtorValues.dataNascimento, 'DDMMYYYY').toDate();
    this.updateDebtor();
  }

  private updateDebtor(): void {
    this.debtorService.update(this.debtor.id, this.debtor)
      .pipe(
        tap((response: DevedorResponse) => {
          this.debtor = { ...response };
          this.editable = false;
          this.helperService.showMessage('Os dados foram atualizados com sucesso');
        }),
        catchError(error => {
          this.loading = false;
          this.helperService.showMessage('Erro ao atualizar os dados');
          return of(error);
        }),
        finalize(() => this.loading = false)
      ).subscribe();
  }

  onCancel(): void {
    this.editable = false;
    this.debtorForm.reset();
  }
}
