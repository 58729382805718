<app-search-by-cpf [titleValue]="searchTitle" (searchByCpfSubmit)="setCpf($event)"></app-search-by-cpf>

<div class="container top-buffer" *ngIf="hiddenDebt.length > 0">
    <table class="table-blue">
        <caption class="description">Desocultação de dívida</caption>
        <thead>
            <tr>
                <th class="container-title" colspan="11" scope="col">
                    <div class="table-title">Resultado</div>
                </th>
            </tr>
            <tr>
                <th class="cursor" scope="col">CPF</th>
                <th class="cursor" scope="col">Parceiro</th>
                <th class="cursor" scope="col">Data da Ocultação</th>
                <th class="cursor" scope="col"></th>
            </tr>
            <tr></tr>
        </thead>
        <tbody *ngIf="!!hiddenDebt">
            <tr *ngFor="let debt of hiddenDebt| paginate : {
                id:'debt-list',
                itemsPerPage: size,
                currentPage: page,
                totalItems: hiddenDebt.length
            };">
                <td>{{ debt.customerId }}</td>
                <td>{{ debt.partnerName }}</td>
                <td>{{ debt.creationDate | date:'dd/MM/yyyy HH:mm:ss' }}</td>
                <td><a class="lnk-red" (click)="deleteHiddenDebt(debt)">Desocultar</a></td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-center top-buffer">
        <pagination-controls id="debt-list" [responsive]="false" previousLabel="Ant." nextLabel="Próx."
            (pageChange)="tabSize($event)">
        </pagination-controls>
    </div>
</div>

<app-loading *ngIf="loading" [backgroundBlue]="true"></app-loading>
<app-pending-search *ngIf="hiddenDebt.length == 0"></app-pending-search>