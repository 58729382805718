<div class="container top-buffer">
  <div class="operation-container">
    <app-button-edit label="Nova Chave" [primary]="false" (onclick)="showModalCreateNew()"></app-button-edit>
    <app-button-edit *ngIf="!showButtonInstallmentPolicy()" label="Carregar Políticas de parcelamento" [primary]="false" (onclick)="showInstallmentPolicyUpdate()" class="left-buffer"></app-button-edit>
    <app-button-edit label="Carregar Políticas de calculo" [primary]="false" (onclick)="showBatchUpdate()" class="left-buffer"></app-button-edit>
  </div>
</div>

<div class="container top-buffer" style="overflow: auto" >
  <app-loading [backgroundGreen]="true" *ngIf="loading"></app-loading>
  <table class="table-green" >
    <thead>
    <tr>
      <th class="container-title" colspan="8">
        <div class="div-container">
          <div>
            <div class="table-title">Políticas de parcelamento</div>
            <div class="table-sub-title">Lista de todas as políticas de parcelamento do {{suspdCredor?.nome}}.</div>
          </div>
            <div *ngIf="!showResultFilter" class="container-filter">
              <button class="button-filter" (click)="showModalFilter()">Filtrar Resultados</button>
              <p class="info-green">Novo!</p>
            </div>
            <div *ngIf="showResultFilter" class="container-result-filter">
              <div class="info-icon">
                <img src="../../../../assets/svg/icons/check.svg" alt="icone de confirmação verde para indicar sucesso na busca">
                <p class="info-result">Mostrando {{installmentCalcList?.length}} resultados que estão de acordo com os seus filtros</p>
              </div>
              <div class="container-buttons-filter">
                <button class="button-filter-result" (click)="removeFilter()">Remover Filtros</button>
                <button class="button-filter-result" (click)="showModalFilter()"> Escolher outros filtros</button>
              </div>
            </div>
        </div>
      </th>
    </tr>
    <tr>
      <th class="cursor" scope="col" (click)="sortTable('id')">Id<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('chave')">Chave<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('diasAtrasoDe')">Dias de atraso de <span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('diasAtrasoAte')">Dias de atraso até<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('valorDividaDe')">Valor da dívida de<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('valorDividaAte')">Valor da dívida até<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('dataCriacao')">Data Criação<span class="{{setaSort}}"></span></th>
      <th class="cursor" scope="col" (click)="sortTable('genericRangeStart')">Range score inicial</th>
      <th class="cursor" scope="col" (click)="sortTable('genericRangeEnd')">Range score final</th>
      <th class="cursor" scope="col" (click)="sortTable('genericRangeValue')">Range score valor</th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
    <tr></tr>
    </thead>
    <tbody *ngIf="installmentCalcList != undefined">
    <tr *ngFor="let installmentCalc of installmentCalcList| paginate : {
                id:'installmentCalc-list',
                itemsPerPage: size,
                currentPage: page,
                totalItems: installmentCalcList.length
              };">
      <td>{{ installmentCalc.id }}</td>
      <td>{{ installmentCalc.chave }}</td>
      <td>{{ installmentCalc.diasAtrasoDe}}</td>
      <td>{{ installmentCalc.diasAtrasoAte}}</td>
      <td>{{ installmentCalc.valorDividaDe  | currency:'BRL':'R$ ':'1.2-2'}}</td>
      <td>{{ installmentCalc.valorDividaAte  | currency:'BRL':'R$ ':'1.2-2'}}</td>
      <td>{{ (installmentCalc.dataCriacao | date:'dd/MM/yyyy') || "-" }}</td>
      <td>{{ installmentCalc.genericRangeStart }}</td>
      <td>{{ installmentCalc.genericRangeEnd }}</td>
      <td>{{ installmentCalc.genericRangeValue }}</td>
      <td><a class="lnk" href="javascript:void(0)" (click)="showModalInstallment(installmentCalc)">Detalhes</a></td>
      <td><a href="javascript:void(0)" (click)="showModalEdit(installmentCalc)"><img class="icon-large" src="assets/svg/icons/editar.svg" alt="Editar"></a></td>
      <td><a href="javascript:void(0)" (click)="deleteClick(installmentCalc)"><img class="icon-large remove-icon" src="assets/svg/icons/remover.svg" alt="Excluir"></a></td>      
    </tr>
    <tr *ngIf="installmentCalcList.length == 0">
      <td colspan="10">Nenhum acordo encontrado</td>
    </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-center top-buffer">
    <pagination-controls id="installmentCalc-list" [responsive]="false" [previousLabel]="labelPrevious" [nextLabel]="labelNext"
                         (pageChange)="tabSize($event)">
    </pagination-controls>
  </div>
</div>
