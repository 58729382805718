import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ConfirmComponent} from '../../shared/confirm/confirm.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserRoleService} from '../../../core/services/user-role.service';
import {HelperService} from '../../../core/services/helper.service';
import {UserRole} from '../../../models/domain';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent {

  @Input() usuarios: UserRole[];
  @Output() usuario: EventEmitter<string> = new EventEmitter();

  @Input() size = 10;
  @Input() page = 1;

  constructor(private modalService: NgbModal, private userService: UserRoleService, private helperService: HelperService) {
    this.usuarios = [];
  }

  setUsuario(usuario: UserRole): void {
    if (usuario.userName != null) {
      this.usuario.emit(usuario.userName);
    }
  }

  tabSize($event: number): void {
    this.page = $event;
  }

  deleteUser(usuario: UserRole): void {
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.pergunta = `Confirma exclusão do usuário ${usuario.userName}?`;
    modalRef.closed.subscribe(x => {
      if (x) {
        if (usuario.userName != null) {
          this.userService.deleteUser(usuario.userName).subscribe(x => {
            this.helperService.showMessage('Usuário excluído com sucesso!');
          });
        }
      }
    });
  }
}
