import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import { PartnerService } from '../../../core/services/partner.service';
import { HelperService } from '../../../core/services/helper.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { HistoryReport } from 'src/app/models/domain';
import { ReportComponent } from '../../search-debtor/report/report.component';

@Component({
  selector: 'app-select-report',
  templateUrl: './select-report.component.html',
  styleUrls: ['./select-report.component.scss']
})
export class SelectReportComponent {
  @Output() searchSubmit: EventEmitter<any> = new EventEmitter();
  searchForm = this.formBuilder.group({
    value: ''
  });
  @Input() searchTitle = '';
  @Input() label = 'CONSULTAR';
  @Input() large = false;
  @Input() medium = false;
  @Input() data: any[] = [];
  @Input() getDescriptionFn: any;
  @Input() loading = false;

  selected: any;

  labelSave = 'ENVIAR';
  uploadFile: File | null = null;
  userName: string = '';
  typeFile: string = '';
  historyReport: HistoryReport[] = [];

  constructor(private formBuilder: FormBuilder, private partnerService: PartnerService, private helperService: HelperService,
    private authenticationService: AuthenticationService, private reportComponent: ReportComponent) {
  }

  ngOnInit(): void {
    const username = this.authenticationService.getUserName();
    this.userName = username || '';
  }

  onSubmit(): void {
    const form = this.searchForm.value;
    if (form.value !== '') {
      this.searchSubmit.emit(form.value);
      this.typeFile = form.value;
    }
  }

  setValue(): void {
    this.typeFile = this.typeFile;
  }

  handleFile(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList) {
      this.uploadFile = fileList.item(0);
    } else {
      this.uploadFile = null;
    }
  }

  submitFile(): void {
    this.loading = true;
    if (this.uploadFile != null) {
      this.reportComponent.submitFile(this.uploadFile, this.userName, this.typeFile);
    }
  }
}
