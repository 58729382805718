import {Component, Input} from '@angular/core';
import {SuspdPoliticaCalculo} from '../../../../models/domain';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {sortBy} from 'sort-by-typescript';
import {FormBuilder, Validators} from '@angular/forms';
import {PartnerService} from '../../../../core/services/partner.service';

@Component({
  selector: 'app-politic-calc-list-edit',
  templateUrl: './installment-list-edit.component.html',
  styleUrls: ['./installment-list-edit.component.scss']
})
export class InstallmentListEditComponent {

  public politicCalcList?: SuspdPoliticaCalculo;
  public politicCalcListSort: any;
  public sort: any;
  public desc = false;
  public setaSort: any = 'seta-baixo';
  loadingTitle = 'Aguarde';
  loading = false;
  labelSave = 'OK, CONFIRMAR';
  labelCancel = 'CANCELAR EDIÇÕES';
  public objPoliticCalcList?: SuspdPoliticaCalculo;

  size = 10;
  page = 1;

  labelPrevious = 'Ant.';
  labelNext = 'Próx.';

  numberRegEx = /\-?\d*\.?\d{1,2}/;

  constructor(private modalService: NgbModal,
              private formBuilder: FormBuilder,
              private partnerService: PartnerService,
              public activeModal: NgbActiveModal) {
  }


  InstallmentForm = this.formBuilder.group({
    id: [],
    chave: [],
    parcela: ['', [Validators.required]],
    percentualDescontoMinimo: ['', [Validators.required, Validators.pattern(this.numberRegEx)]],
    percentualDescontoMaximo: ['', [Validators.required, Validators.pattern(this.numberRegEx)]],
    percentualDescontoPrincipal: ['', [Validators.required, Validators.pattern(this.numberRegEx)]],
    percentualMulta: ['', [Validators.required, Validators.pattern(this.numberRegEx)]],
    percentualJurosAtraso: ['', [Validators.required, Validators.pattern(this.numberRegEx)]],
    percentualJurosFinanciamento: ['', [Validators.required, Validators.pattern(this.numberRegEx)]],
    percentualComissao: ['', [Validators.required, Validators.pattern(this.numberRegEx)]],
    percentualEntradaMinima: ['', [Validators.required, Validators.pattern(this.numberRegEx)]],
    valorParcelaMinima: ['', [Validators.required, Validators.pattern(this.numberRegEx)]],
    dataCriacao: [],
    diasSimulacaoMaximo: ['', [Validators.required, Validators.pattern(this.numberRegEx)]],
    suspdPoliticaParcelamento: [],
  });

  @Input()
  public setPoliticCalcListValue(politicList: SuspdPoliticaCalculo): void {
    this.loading = true;
    if (politicList !== undefined) {
      this.politicCalcList = politicList;
      this.InstallmentForm.setValue({
        id: this.politicCalcList.id != null ? this.politicCalcList?.id: null,
        chave: this.politicCalcList.chave,
        parcela: this.politicCalcList.parcela,
        percentualDescontoMinimo: this.politicCalcList.percentualDescontoMinimo,
        percentualDescontoMaximo: this.politicCalcList?.percentualDescontoMaximo,
        percentualDescontoPrincipal: this.politicCalcList?.percentualDescontoPrincipal,
        percentualMulta: this.politicCalcList?.percentualMulta,
        percentualJurosAtraso: this.politicCalcList?.percentualJurosAtraso,
        percentualJurosFinanciamento: this.politicCalcList?.percentualJurosFinanciamento,
        percentualComissao: this.politicCalcList?.percentualComissao,
        percentualEntradaMinima: this.politicCalcList?.percentualEntradaMinima,
        valorParcelaMinima: this.politicCalcList?.valorParcelaMinima,
        diasSimulacaoMaximo: this.politicCalcList?.diasSimulacaoMaximo,
        suspdPoliticaParcelamento: this.politicCalcList?.suspdPoliticaParcelamento,
        dataCriacao: this.politicCalcList.dataCriacao,
      });
    }
    this.loading = false;
  }

  @Input()
  public setNewPoliticCalcListValue(politicList: SuspdPoliticaCalculo): void {
    this.loading = true;
    if (politicList !== undefined) {
      this.InstallmentForm.controls.suspdPoliticaParcelamento.setValue(politicList);
    }
    this.loading = false;
  }

  sortTable(sort: string): void {
    if(this.sort === sort && this.desc === false){
      this.desc = true;
      this.setaSort = 'seta-cima';
    }else{
      this.desc = false;
      this.setaSort = 'seta-baixo';
    }
    this.sort = sort;
    if (this.desc){
      this.politicCalcListSort.sort(sortBy('-'.concat(sort)));
    }else{
      this.politicCalcListSort.sort(sortBy(sort));
    }
  }

  tabSize($event: number): void {
    this.page = $event;
  }

  onSubmit(): void {
    this.loading = true;
    if (this.InstallmentForm != null && this.InstallmentForm.valid) {
      const objPoliticCalcList = this.InstallmentForm.value;
      if ( objPoliticCalcList.dataCriacao === undefined || objPoliticCalcList.dataCriacao == null){
        objPoliticCalcList.dataCriacao = new Date();
      }
      this.partnerService.setPoliticCalc(objPoliticCalcList).subscribe(newListSuspdPoliticaCalculo => {
        if (newListSuspdPoliticaCalculo !== undefined){
        }
        this.activeModal.close(true);
      });
    }
    this.loading = false;
  }

  onCancel(): void {
    this.InstallmentForm.reset();
  }


}
