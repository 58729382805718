<div class="modal-header">
  <h4 class="modal-title">Políticas de Cálculo</h4>
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-loading [backgroundGreen]="true" *ngIf="loading"></app-loading>
  <div style="overflow: auto;">
    <table class="table-green" *ngIf="!loading" style="width: 2800px">
      <thead>
      <tr>
        <th class="cursor" (click)="sortTable('id')">Politica ID<span class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('suspdPoliticaParcelamento.id')">Politica Parcelamento ID<span class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('parcela')">Parcela<span class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('percentualDescontoMinimo')">(%) Desconto Mínimo<span class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('percentualDescontoMaximo')">(%) Desconto Máximo<span class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('percentualDescontoPrincipal')">(%) Desconto Principal<span class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('percentualMulta')">(%) Multa<span class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('percentualJurosAtraso')">(%) Juros em Atraso<span class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('percentualJurosFinanciamento')">(%) Juros Financiamento<span class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('percentualComissao')">(%) Comissão<span class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('percentualEntradaMinima')">(%) Entrada Mínima<span class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('valorParcelaMinima')">Valor Parcela Mínima<span class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('dataCriacao')">Data Criação<span class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('diasSimulacaoMaximo')">Dias Simulação Máximo<span class="{{setaSort}}"></span></th>
        <th class="cursor" (click)="sortTable('diasSimulacaoMaximo')">Chave<span class="{{setaSort}}"></span></th>
        <th class="headcol"></th>
        <th class="cursor"></th>
      </tr>
      <tr></tr>
      </thead>
      <tbody *ngIf="politicCalcList != undefined">
      <tr *ngFor="let politicCalc of politicCalcList| paginate : {
                  id:'politicCalcList-list',
                  itemsPerPage: size,
                  currentPage: page,
                  totalItems: politicCalcList.length
                };">
        <td>{{ politicCalc.id }}</td>
        <td>{{ politicCalc.suspdPoliticaParcelamento?.id }}</td>
        <td>{{ politicCalc.parcela}}</td>
        <td>{{ politicCalc.percentualDescontoMinimo}}</td>
        <td>{{ politicCalc.percentualDescontoMaximo }}</td>
        <td>{{ politicCalc.percentualDescontoPrincipal }}</td>
        <td>{{ politicCalc.percentualMulta }}</td>
        <td>{{ politicCalc.percentualJurosAtraso }}</td>
        <td>{{ politicCalc.percentualJurosFinanciamento }}</td>
        <td>{{ politicCalc.percentualComissao }}</td>
        <td>{{ politicCalc.percentualEntradaMinima }}</td>
        <td>{{ politicCalc.valorParcelaMinima   | currency:'BRL':'R$ ':'1.2-2'}}</td>
        <td>{{ (politicCalc.dataCriacao | date:'dd/MM/yyyy') || "-" }}</td>
        <td>{{ politicCalc.diasSimulacaoMaximo }}</td>
        <td>{{ politicCalc.chave || '-' }}</td>
        <td class="headcol"><a href="javascript:void(0)" (click)="showModalEdit(politicCalc)"><img class="icon-large" src="assets/svg/icons/editar.svg" alt="Editar"></a></td>
        <td><a href="javascript:void(0)" (click)="deleteClick(politicCalc)"><img class="icon-large remove-icon" src="assets/svg/icons/remover.svg" alt="Excluir"></a></td>
      </tr>
      <tr *ngIf="politicCalcList.length == 0">
        <td colspan="17">Nenhum acordo encontrado</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center top-buffer">
    <pagination-controls id="politicCalcList-list" [responsive]="false" [previousLabel]="labelPrevious" [nextLabel]="labelNext"
                         (pageChange)="tabSize($event)">
    </pagination-controls>
  </div>
</div>
<div class="modal-footer">
  <app-button-edit  [inverted]="true" [primary]="false" (onclick)="showModalCreateNew()" label="Incluir"></app-button-edit>
  <app-button-edit  [inverted]="true" [primary]="false" (onclick)="downloadCsv()" label="Download"></app-button-edit>
  <app-button-edit  [inverted]="true" (onclick)="activeModal.close('Close click')" label="Fechar"></app-button-edit>
</div>
