<div class="container top-buffer">
  <table class="table-blue">
    <thead>
    <tr>
      <th class="container-title" colspan="8">
        <div class="table-title">Lista dos usuários cadastrados</div>
      </th>
    </tr>
    <tr>
      <th>Usuário</th>
      <th>Perfil</th>
      <th></th>
    </tr>
    <tr></tr>
    </thead>
    <tbody>
    <tr *ngFor="let usuario of usuarios | paginate : {
                itemsPerPage: size,
                currentPage: page,
                totalItems: usuarios.length
              };">
      <td><a href="javascript:void(0)" class="lnk" (click)="setUsuario(usuario)">{{ usuario.userName }}</a></td>
      <td>{{ usuario.role?.name }}</td>
      <td class="text-center"><a href="javascript:void(0)" (click)="deleteUser(usuario)"><img class="icon-large" src="assets/svg/icons/icone-removeruser.svg" alt="Excluir"></a></td>
    </tr>
    <tr *ngIf="usuarios.length == 0">
      <td colspan="3">Nenhum usuário encontrado</td>
    </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-center top-buffer">
    <pagination-controls [responsive]="false" previousLabel="Ant." nextLabel="Próx."
                         (pageChange)="tabSize($event)">
    </pagination-controls>
  </div>
</div>
