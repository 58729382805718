import {Component} from '@angular/core';
import {CipService} from '../../../core/services/cip.service';
import {CipInfo} from '../../../models/domain';

@Component({
  selector: 'app-consulta-cip',
  templateUrl: './consulta-cip.component.html',
  styleUrls: ['./consulta-cip.component.scss']
})

export class ConsultaCipComponent {
  titleSearch = 'Consulta o status do boleto na CIP';
  placeHolderSearch = 'Linha digitável do boleto';
  maskSearch = '00000.00000 00000.000000 00000.000000 0 0XXXXXXXXXXXXXX';

  digitableLine = '';
  loading = false;
  cip: CipInfo | undefined;


  constructor(private cipService: CipService) {
  }

  searchSubmit(val: string): void {
    this.digitableLine = val;
    this.loading = true;
    this.cipService.loadCIP(this.digitableLine).subscribe(cip => {
      this.loading = false;
      this.cip = cip;
    }, error => {
      this.loading = false;
    });
  }
}
