import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DebtorInfo, Devedor, Email, Role, Telefone, UserRole} from '../../../models/domain';
import {FormBuilder, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';
import {DebtorService} from '../../../core/services/debtor.service';
import {HelperService} from '../../../core/services/helper.service';
import {MaskPipe} from 'ngx-mask';
import {UserRoleService} from '../../../core/services/user-role.service';
import {RoleService} from '../../../core/services/role.service';

@Component({
  selector: 'app-user-role',
  templateUrl: './edit-role-user.component.html',
  styleUrls: ['./edit-role-user.component.scss']
})
export class EditRoleUserComponent {

  labelEdit = 'Editar Dados';
  labelSave = 'OK, CONFIRMAR';
  labelCancel = 'CANCELAR EDIÇÕES';

  editable = false;
  userRoleValue: Role | undefined;
  loadingTitle = 'Edit Usuário';
  loading = false;
  private userSelect: boolean | undefined;
  public roles: any;

  userRoleForm = this.formBuilder.group({
    roleSelect: ['', [Validators.required]]
  });

  constructor(private formBuilder: FormBuilder,
              private datePipe: DatePipe,
              private userRoleService: UserRoleService,
              private roleService: RoleService,
              private helperService: HelperService,
              private mask: MaskPipe
  ) {
    this.userRoleValue = new Role();
  }

  @Input()
  userName: string | undefined;
  @Input()
  set userRole(userRole: Role[] | undefined) {
    if (userRole !== undefined) {
      this.userRoleValue = userRole[0];
    }else{
      this.userRoleValue = undefined;
    }
    this.roleService.listAll().subscribe((response: any) => {
      this.loading = false;
      this.roles = response;
      if (this.userRoleValue !== undefined) {
        this.userRoleForm.setValue({
          roleSelect: this.userRoleValue.roleId
        });
      }
    });

  }

  onSubmit(): void {
    if (this.userName == null) {
      this.helperService.showError('User is null');
    } else {
      const formValue = this.userRoleForm.value;
      this.userRoleService.insertRole(this.userName, formValue.roleSelect).subscribe(x => {
        this.readUserRoleInfo(x);
        this.helperService.showMessage('Os dados foram atualizados com sucesso');
      });
    }
  }

  onCancel(): void {
    this.editable = false;
  }

  edit(): void {
    this.editable = true;
  }

  private readUserRoleInfo(userRole: UserRole): void {
    this.userRoleValue = userRole.role;
    this.userRoleForm.setValue({
      roleSelect: userRole.role?.roleId,
    });
    this.editable = false;
  }

}
