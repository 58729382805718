<div class="container top-buffer" *ngIf="creditorValue != null">
  <table class="table-blue">
    <thead>
    <tr>
      <th class="container-title" colspan="13">
        <div class="table-title">Parceiros</div>
      </th>
    </tr>
    <tr>
      <th>Id</th>
      <th>Nome</th>
      <th>Identificador</th>
      <th>Representação</th>
      <th>Show</th>
      <th>Order</th>
      <th>Segmento</th>
      <th>MS URL</th>
      <th>Nome Bean</th>
      <th>Status</th>
      <th *ngIf="canEdit()"></th>
      <th></th>
      <th *ngIf="canDelete()"></th>
    </tr>
    <tr></tr>
    </thead>
    <tbody>
    <tr>
      <td>{{ creditorValue.id }}</td>
      <td>{{ creditorValue.nome || '-' }}</td>
      <td>{{ creditorValue.identificador || '-' }}</td>
      <td>{{ creditorValue.representacao || '-' }}</td>
      <td>{{ creditorValue.show == null ? '-' : creditorValue.show }}</td>
      <td>{{ creditorValue.order == null ? '-' : creditorValue.order }}</td>
      <td>{{ creditorValue.segmento || '-' }}</td>
      <td>{{ creditorValue.msUrl || '-' }}</td>
      <td>{{ creditorValue.beanName || '-' }}</td>
      <td><app-checkbox (change)="creditorChange(creditorValue)" [checked]="creditorValue.status === 1" [disabled]="saving"></app-checkbox></td>
      <td *ngIf="canEdit()"><a href="javascript:void(0)" (click)="editClick(creditorValue)"><img class="icon-large" src="assets/svg/icons/editar.svg" alt="Editar"></a></td>
      <td><a href="javascript:void(0)" (click)="editConfigClick(creditorValue)"><img class="icon-large" src="assets/svg/icons/config.svg" alt="Configurar"></a></td>
      <td *ngIf="canDelete()"><a href="javascript:void(0)" (click)="deleteClick(creditorValue)"><img class="icon-large remove-icon" src="assets/svg/icons/remover.svg" alt="Excluir"></a></td>
    </tr>
    </tbody>
  </table>
</div>

<app-audit-log *ngIf="creditorValue != null"></app-audit-log>
