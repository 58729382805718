<app-loading *ngIf="loading" [backgroundBlue]="true"></app-loading>
<div class="container">
  <div class="batch-container">
    <h3 *ngIf="blocked" class="title-search">Upload em processamento</h3>
    <div class="row">
      <div class="data-title">Nome:</div>
      <div class="data-value"><input (change)="handleFile($event)" class="form-control form-input"
                                     type="file"></div>
    </div>

    <div class="row top-buffer btn-edit-container">
      <app-button-edit (onclick)="onSubmit()" [disabled]="loading || blocked" [primary]="!blocked" label="OK, Confirmar"></app-button-edit>
      <app-button-edit (onclick)="onCancel()" [leftBuffer]="true" [primary]="false"
                       label="Cancelar Edições"></app-button-edit>
      <app-button-edit (onclick)="downloadModel()" [leftBuffer]="true" [primary]="false"
                       label="Modelo de Arquivo"></app-button-edit>
    </div>
  </div>
</div>