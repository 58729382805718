import { Component } from '@angular/core';
import { HelperService } from '../../core/services/helper.service';
import { HiddenDebtService } from '../../core/services/hidden-debt.service';
import { DividaOcultaResponse } from '../../models/response/DividaOcultaResponse.model';
import CpfUtils from '../../core/services/utils/cpf.utils';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-hidden-debt',
    templateUrl: './hidden-debt.component.html',
    styleUrls: ['./hidden-debt.component.scss']
})

export class HiddenDebtComponent {

    searchTitle: string = 'Consultar débito ocultos por CPF';
    loading = false;

    size = 10;
    page = 1;

    hiddenDebt: DividaOcultaResponse[] = [];

    constructor(private helperService: HelperService, private hiddenDebtService: HiddenDebtService) {
    }

    public setCpf(cpf: number) {
        this.loading = true;
        let parsedCpf = CpfUtils.convertToString(cpf);
        if (this.isValidCpf(parsedCpf)) {
            this.getHiddenDebts(parsedCpf);
        } else {
            this.helperService.showError('CPF inválido');
            this.loading = false;
        }
    }

    private getHiddenDebts(cpf: string): void {
        this.hiddenDebtService.getHiddenDebts(cpf).pipe(finalize(() => this.loading = false))
            .subscribe(hiddenDebtResponse => {
                this.hiddenDebt = hiddenDebtResponse;
            });
    }

    public deleteHiddenDebt(hiddenDebt: DividaOcultaResponse): void {
        this.loading = true;
        this.hiddenDebtService.deleteHiddenDebt(hiddenDebt.id).pipe(finalize(() => this.loading = false))
            .subscribe(_hiddenDebtResponse => {
                this.getHiddenDebts(CpfUtils.unformat(hiddenDebt.customerId.toString()));
                this.helperService.showMessage(`Dívida desocultada!`);
            });
    }

    public isValidCpf(cpf: string): boolean {
        return CpfUtils.isValid(cpf);
    }

    tabSize($event: number): void {
        this.page = $event;
    }
}