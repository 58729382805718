import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-pending-search',
  templateUrl: './pending-search.component.html',
  styleUrls: ['./pending-search.component.scss']
})
export class PendingSearchComponent {

}
