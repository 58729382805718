import { Component, OnInit } from '@angular/core';
import { JuridicalService } from 'src/app/core/services/juridical.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  policies = [];
  policy: any;
  loading = false;
  insert = false;

  constructor(private juridicalService: JuridicalService) {}

  ngOnInit(): void {
    this.getPolicies();
  }

  getPolicies() {
    this.juridicalService.getPrivacyPolicies().subscribe(
      (ret) => {
        this.policies = ret.sort(function compare(a: any, b: any) {
          if (parseFloat(a.version) > parseFloat(b.version)) return -1;
          if (parseFloat(a.version) < parseFloat(b.version)) return 1;
          return 0;
      })
      },
      (err) => {
      }
    );
  }

  insertClick(): void {
    this.policy = undefined;
    this.insert = true;
  }

  setPolicy(policy: any): void {
    this.policy = policy;
    this.insert = true;
  }

  cancelEdit(policy: any): void {
    this.policy = undefined;
    this.insert = false;
  }

  savePolicy() {
    this.policy = undefined;
    this.insert = false;
    this.getPolicies();
  }

  published() {
    this.getPolicies();
  }
}
