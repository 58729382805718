import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {AuditLog, Devedor, Email, SuspdAcordo, SuspdAcordoParcela, Telefone, UserRole} from '../../models/domain';
import {ApiServices} from './api.services';

export const ENTITY_NAMES = new Map([
  ['Devedor', 'do Consumidor']
  , ['UserRole', 'do Perfil']
  , ['SuspdAcordo', 'do Acordo']
  , ['Telefone', 'do Consumidor']
  , ['Email', 'do Consumidor']
  , ['SuspdAcordoParcela', 'da Parcela']
  , ['SuspdCredor', 'do Credor']
  , ['SuspdPoliticaParcelamento', 'da Políticas de parcelamento']
  , ['SuspdPoliticaCalculo', 'da Politicas de cálculo']
  , ['SuspdSistema', 'do Sistema']
  , ['SuspdSistemaUsuario', 'do Usuário Sistema']
  , ['SuspdCredorConfiguracao', 'da Configuração Credor']
]);
export const COLUMN_NAMES = new Map([
  ['socialName', 'Nome Social']
  , ['status', 'Status']
  , ['cpf', 'Cpf']
  , ['email', 'e-mail']
  , ['ddd', 'ddd']
  , ['telefone', 'telefone']
  , ['dataNascimento', 'Data de Nascimento']
  , ['dataAtualizacao', '']
  , ['dataCancelamento', 'Data de Cancelamento']
  , ['devedorId', 'Devedor']
  , ['dataPagamento', 'Data do Pagamento']
  , ['chave', 'Chave']
  , ['diasAtrasoDe', 'Dias Atraso de']
  , ['diasAtrasoAte', 'Dias de atraso até']
  , ['valorDividaDe', 'Valor da dívida de']
  , ['parcela', 'da Parcela']
  , ['percentualDescontoMinimo', 'Percentual de Desconto Mínimo']
  , ['percentualDescontoMaximo', 'Percentual de Desconto Máximo']
  , ['percentualDescontoPrincipal', 'Percentual de Desconto Principal']
  , ['percentualMulta', 'Percentual de Multa']
  , ['percentualJurosAtraso', 'Percentual de Juros em Atraso']
  , ['percentualJurosFinanciamento', 'Percentual de Juros Financiamento']
  , ['percentualComissao', 'Percentual de Comissão']
  , ['percentualEntradaMinima', 'Percentual de Entrada Mínima']
  , ['valorParcelaMinima', 'Valor de Parcela Mínima']
  , ['suspdPoliticaCalculos', '']
  , ['suspdCredorId', '']
  , ['suspdPoliticaParcelamentoId ', '']
  , ['imageUrl', 'URL da Imagem']
  , ['sistemaId', 'ID do Sistema']
]);

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  private auditLogEvent = new Subject<AuditLog[] | null>();

  constructor(private apiServices: ApiServices) {
  }

  auditLogEventObservable(): Observable<AuditLog[] | null> {
    return this.auditLogEvent.asObservable();
  }

  clear(): void {
    this.auditLogEvent.next(null);
  }

  load(entityName: string, entityId: any): void {
    this.apiServices.loadAuditLogs(entityName, entityId).subscribe(auditLog => {
      this.emitLog(auditLog);
    });
  }

  emitLog(auditLog: AuditLog[]): void {
    this.auditLogEvent.next(auditLog);
  }

  getAuditLogDescription(auditLog: AuditLog): string {
    if (auditLog.operation === 'UPDATE') {
      return this.getUpdateDescription(auditLog);
    } else if (auditLog.operation === 'DELETE') {
      return this.getDeleteDescription(auditLog);
    } else {
      return this.getInsertDescription(auditLog);
    }
  }

  private getUpdateDescription(auditLog: AuditLog): string {
    if (auditLog.entityName !== undefined) {
      const fields: string[] = [];
      auditLog.detail?.forEach(detail => {
        if (detail != null && detail.columnName != null) {
          const columnName = COLUMN_NAMES.has(detail.columnName) ? COLUMN_NAMES.get(detail.columnName) : detail.columnName;
          if (columnName != null && columnName !== '') {
            fields.push(columnName);
          }
        }
      });
      const entityName = ENTITY_NAMES.has(auditLog.entityName) ? ENTITY_NAMES.get(auditLog.entityName) : auditLog.entityName;
      if (fields.length === 0) {
        return '';
      } else {
        return `Alteração ${fields.join(', ')} ${entityName}.`;
      }
    }
    return '';
  }

  private getInsertDescription(auditLog: AuditLog): string {
    if (auditLog.entityName !== undefined) {
      return `Inclusão ${ENTITY_NAMES.get(auditLog.entityName)}.`;
    }
    return '';
  }

  private getDeleteDescription(auditLog: AuditLog): string {
    if (auditLog.entityName !== undefined) {
      return `Exclusão ${ENTITY_NAMES.get(auditLog.entityName)}.`;
    }
    return '';
  }
}
