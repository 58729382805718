import { Injectable } from "@angular/core";
import { ApiServices } from './api.services';
import { Observable } from 'rxjs';
import { ReclameAquiResponse } from '../../models/response/ReclameAquiResponse.model';

@Injectable()
export class ReclameAquiService {
    
    constructor (private apiService: ApiServices) {}

    getCustomerEmailByEmail(email: String): Observable<ReclameAquiResponse[]> {
        return this.apiService.getCustomerEmailByEmail(email);
    }

    getCustomerByPhone(phoneNumber: string): Observable<ReclameAquiResponse[]> {
        return this.apiService.getCustomerByPhone(phoneNumber);
    }
}