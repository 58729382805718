<div class="container" *ngIf="!type">
    <div class="search-box middle-aligned-container align-items-center">
        <div class="title-search">
            Selecione o modo de pesquisa
        </div>
        <div class="row top-buffer">
            <button class="search-button" type="button" (click)="typeOfSearch('celular')">CELULAR</button>
            <button class="search-button right-search-button" type="button"
                (click)="typeOfSearch('email')">E-MAIL</button>
        </div>
    </div>
</div>

<div [ngSwitch]="type">
    <app-search-by-phone-number *ngSwitchCase="'celular'" [titleValue]="searchTitlePhoneNumber"
        (searchByPhoneNumberSubmit)="searchByPhoneNumberSubmit($event)"></app-search-by-phone-number>
    <app-search-by-email *ngSwitchCase="'email'" titleValue="Consulte por e-mail"
        (searchByEmailSubmit)="setEmail($event)"></app-search-by-email>
</div>
<app-loading *ngIf="loading" [backgroundBlue]="true"></app-loading>
<app-pending-search *ngIf="pendingeSearch"></app-pending-search>
<app-table-reclame-aqui *ngIf="reclameAquiResponse.length > 0"
    [customers]="reclameAquiResponse" [type]="type"></app-table-reclame-aqui>