<div class="modal-header">
  <h4 class="modal-title">Configurações do parceiro</h4>
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-loading [backgroundBlue]="true" *ngIf="loading"></app-loading>
  <table class="table-blue" *ngIf="!loading">
    <thead>
    <tr>
      <th colspan="7"></th>
    </tr>
    <tr>
      <th>Chave</th>
      <th>Valor String</th>
      <th>Valor Int</th>
      <th>Valor Decimal</th>
      <th>Valor Date</th>
      <th>Valor Boolean</th>
      <th></th>
    </tr>
    <tr></tr>
    </thead>
    <tbody *ngIf="configuration != null">
    <ng-container *ngFor="let config of configuration; let i = index">
      <tr>
        <td><input class="form-control" type="text" [value]="config.chave || ''" maxlength="255" #chave
                   (blur)="chaveBlur(i, chave)"></td>
        <td><input class="form-control" type="text" [value]="config.valorString || ''" maxlength="255" #valorString
                   (blur)="stringBlur(i, valorString)"></td>
        <td><input class="form-control" type="number" [value]="config.valorInt" #valorInt
                   (blur)="intBlur(i, valorInt)"></td>
        <td><input class="form-control" type="text" [value]="config.valorDecimal | currency:'BRL':'':'1.2-2'"
                   #valorDecimal (blur)="decimalBlur(i, valorDecimal)"></td>
        <td><input class="form-control" type="text" [value]="config.valorDate | date:'dd/MM/yyyy'"
                   #valorDate (blur)="dateBlur(i, valorDate)"></td>
        <td>
          <input class="form-control" type="number" [value]="config.valorBoolean"
                 #valorBoolean (blur)="booleanBlur(i, valorBoolean)">
        </td>
        <td><a href="javascript:void(0)" (click)="delete(i)"><img class="icon-large remove-icon" src="assets/svg/icons/remover.svg" alt="Excluir"></a></td>
      </tr>
      <tr *ngIf="isInvalid[i]">
        <td colspan="7" class="warn"><small>Dados inválidos</small></td>
      </tr>
    </ng-container>
    <tr *ngIf="configuration.length == 0">
      <td colspan="7">Nenhum registro encontrado</td>
    </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <app-button-edit [inverted]="true" (onclick)="saveClick()" label="Ok, confirmar"></app-button-edit>
  <app-button-edit [inverted]="true" [leftBuffer]="true" [primary]="false" (onclick)="insertClick()" label="Incluir chave"></app-button-edit>
</div>
