import {Observable} from 'rxjs';
import {BilletAlertsInfo, SuspdSistema, SuspdSistemaUsuario} from '../../models/domain';
import {Injectable} from '@angular/core';
import {ApiServices} from './api.services';
import {AuditLogService} from './audit-log.service';

@Injectable()
export class SystemService {

  constructor(private apiService: ApiServices, private auditLogService: AuditLogService) {
  }

  listSystems(): Observable<SuspdSistema[]> {
    return this.apiService.listSystems();
  }

  delete(id: number): Observable<SuspdSistema> {
    return this.apiService.deleteSystem(id);
  }

  update(id: number, system: SuspdSistema): Observable<SuspdSistema> {
    return this.apiService.updateSystem(id, system);
  }

  create(system: SuspdSistema): Observable<SuspdSistema> {
    return this.apiService.createSystem(system);
  }

  listSystemUser(systemId: number): Observable<SuspdSistema[]> {
    return this.apiService.listSystemUser(systemId);
  }

  createOrUpdate(systemId: number, systemUserList: SuspdSistemaUsuario[]): Observable<SuspdSistemaUsuario[]> {
    return this.apiService.createOrUpdateSystemUsers(systemId, systemUserList);
  }

  loadAuditLogs(): void {
    this.apiService.loadSystemAuditLogs().subscribe(logs => {
      this.auditLogService.emitLog(logs);
    });
  }
}
