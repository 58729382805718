<div class="modal-header">
  <h4 class="modal-title">Política de Parcelamento</h4>
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="politicForm">
    <app-loading *ngIf="loading" [title]="loadingTitle" [backgroundBlue]="true"></app-loading>
    <div>
      <div>
        <div class="row">
          <div class="data-title-light">Chave:</div>
          <div class="data-value-light" *ngIf="politicForm.controls.chave != null">
            <app-input-varchar [maxLength]="254" className="form-input-light" formControlName="chave"></app-input-varchar></div>
          <div *ngIf="politicForm.controls.chave.invalid && (politicForm.controls.chave.dirty || politicForm.controls.chave.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="politicForm.controls.chave.value !== ''">Campo chave está inválido</li>
              <li *ngIf="politicForm.controls.chave.value === ''">Campo chave é obrigatório</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="data-title-light">Dias de Atraso de:</div>
          <div class="data-value-light" *ngIf="politicForm.controls.diasAtrasoDe != null"><app-input-varchar [maxLength]="9" className="form-input-light" formControlName="diasAtrasoDe"></app-input-varchar></div>
          <div *ngIf="politicForm.controls.diasAtrasoDe.invalid && (politicForm.controls.diasAtrasoDe.dirty || politicForm.controls.diasAtrasoDe.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="politicForm.controls.diasAtrasoDe.value !== ''">Campo Dias de Atraso de está inválido</li>
              <li *ngIf="politicForm.controls.diasAtrasoDe.value === ''">Campo Dias de Atraso de é obrigatório</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="data-title-light">Dias de atraso até:</div>
          <div class="data-value-light" *ngIf="politicForm.controls.diasAtrasoAte != null"><app-input-varchar [maxLength]="9" className="form-input-light" formControlName="diasAtrasoAte"></app-input-varchar></div>
          <div *ngIf="politicForm.controls.diasAtrasoAte.invalid && (politicForm.controls.diasAtrasoAte.dirty || politicForm.controls.diasAtrasoAte.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="politicForm.controls.diasAtrasoAte.value !== ''">Campo Dias de atraso até está inválido</li>
              <li *ngIf="politicForm.controls.diasAtrasoAte.value === ''">Campo Dias de atraso até é obrigatório</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="data-title-light">Valor dívida de:</div>
          <div class="data-value-light" *ngIf="politicForm.controls.valorDividaDe != null"><app-input-decimal [maxLength]="11" className="form-input-light" formControlName="valorDividaDe"></app-input-decimal></div>
          <div *ngIf="politicForm.controls.valorDividaDe.invalid && (politicForm.controls.valorDividaDe.dirty || politicForm.controls.valorDividaDe.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="politicForm.controls.valorDividaDe.value !== ''">Campo Valor dívida de está inválido</li>
              <li *ngIf="politicForm.controls.valorDividaDe.value === ''">Campo Valor dívida de é obrigatório</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="data-title-light">Valor dívida até:</div>
          <div class="data-value-light" *ngIf="politicForm.controls.valorDividaAte != null"><app-input-decimal [maxLength]="11" className="form-input-light" formControlName="valorDividaAte"></app-input-decimal></div>
          <div *ngIf="politicForm.controls.valorDividaAte.invalid && (politicForm.controls.valorDividaAte.dirty || politicForm.controls.valorDividaAte.touched)"   class="itemValidator">
            <ul>
              <li *ngIf="politicForm.controls.valorDividaAte.value !== ''">Campo Valor dívida até está inválido</li>
              <li *ngIf="politicForm.controls.valorDividaAte.value === ''">Campo Valor dívida até é obrigatório</li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="data-title-light">Range score inicial:</div>
          <div class="data-value-light" *ngIf="politicForm.controls.genericRangeStart != null"><app-input-varchar className="form-input-light" formControlName="genericRangeStart"></app-input-varchar></div>
        </div>
        <div class="row">
          <div class="data-title-light">Range score final:</div>
          <div class="data-value-light" *ngIf="politicForm.controls.genericRangeEnd != null"><app-input-varchar className="form-input-light" formControlName="genericRangeEnd"></app-input-varchar></div>
        </div>
        <div class="row">
          <div class="data-title-light">Range score valor:</div>
          <div class="data-value-light" *ngIf="politicForm.controls.genericRangeValue != null"><app-input-varchar className="form-input-light" formControlName="genericRangeValue"></app-input-varchar></div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <app-button-edit [inverted]="true" (onclick)="onSubmit()" [label]="labelSave" [disabled]="loading"></app-button-edit>
  <app-button-edit [inverted]="true" [leftBuffer]="true" [primary]="false"  (click)="activeModal.close('Close click')" [label]="labelCancel"></app-button-edit>
</div>
