import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AcCustomerModule} from '../../ac-customer.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { ListPrivacyPolicyComponent } from './privacy-policy/list-privacy-policy/list-privacy-policy.component';
import { EditPrivacyPolicyComponent } from './privacy-policy/edit-privacy-policy/edit-privacy-policy.component';
import { EditTermsOfUseComponent } from './terms-of-use/edit-terms-of-use/edit-terms-of-use.component';
import { ListTermsOfUseComponent } from './terms-of-use/list-terms-of-use/list-terms-of-use.component';
import { PublishPrivacyPolicyComponent } from './privacy-policy/publish-privacy-policy/publish-privacy-policy.component';
import { PublishTermsOfUseComponent } from './terms-of-use/publish-terms-of-use/publish-terms-of-use.component';
import { ViewPrivacyPolicyComponent } from './privacy-policy/view-privacy-policy/view-privacy-policy.component';
import { ViewTermsOfUseComponent } from './terms-of-use/view-terms-of-use/view-terms-of-use.component';
import {NgxPaginationModule, PaginatePipe} from 'ngx-pagination';



@NgModule({
  declarations: [PrivacyPolicyComponent, TermsOfUseComponent, ListPrivacyPolicyComponent, EditPrivacyPolicyComponent, EditTermsOfUseComponent, ListTermsOfUseComponent, PublishPrivacyPolicyComponent, PublishTermsOfUseComponent, ViewPrivacyPolicyComponent, ViewTermsOfUseComponent ],
  imports: [
    CommonModule,
    FormsModule,
    AngularEditorModule,
    ReactiveFormsModule,
    AcCustomerModule,
    NgxPaginationModule
  ],
  providers: [
    PaginatePipe
  ],
})
export class JuridicalModule { }
