import {Component, EventEmitter, Output} from '@angular/core';
import {SuspdSistema, SuspdSistemaUsuario} from '../../../../models/domain';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SystemService} from '../../../../core/services/system.service';

@Component({
  selector: 'app-edit-system-configuration',
  templateUrl: './edit-system-configuration.component.html',
  styleUrls: ['./edit-system-configuration.component.scss']
})

export class EditSystemConfigurationComponent {

  systemValue: SuspdSistema | null = null;
  systemUserList: SuspdSistemaUsuario[] | null = null;
  loading = false;
  @Output() onsave = new EventEmitter<any>();
  @Output() oncancel = new EventEmitter<any>();
  isInvalid: boolean[] = [];

  constructor(public activeModal: NgbActiveModal, private systemService: SystemService) {
  }

  set system(system: SuspdSistema | null) {
    this.systemValue = system;
    if (system != null && system.id != null) {
      this.loading = true;
      this.systemService.listSystemUser(system.id).subscribe(systemUser => {
        this.systemUserList = systemUser;
        this.loading = false;
      });
    }
  }

  insertClick(): void {
    if (this.systemUserList == null) {
      this.systemUserList = [];
    }
    this.systemUserList?.push({});
  }

  chaveBlur(index: number, value: HTMLInputElement): void {
    if (this.systemUserList != null) {
      if (value.value !== '') {
        this.systemUserList[index].chave = value.value;
      } else {
        this.systemUserList[index].chave = undefined;
      }
    }
  }

  usuarioBlur(index: number, value: HTMLInputElement): void {
    if (this.systemUserList != null) {
      if (value.value !== '') {
        this.systemUserList[index].usuario = value.value;
      } else {
        this.systemUserList[index].usuario = undefined;
      }
    }
  }

  senhaBlur(index: number, value: HTMLInputElement): void {
    if (this.systemUserList != null) {
      if (value.value !== '') {
        this.systemUserList[index].senha = value.value;
      } else {
        this.systemUserList[index].senha = undefined;
      }
    }
  }

  urlBlur(index: number, value: HTMLInputElement): void {
    if (this.systemUserList != null) {
      if (value.value !== '') {
        this.systemUserList[index].url = value.value;
      } else {
        this.systemUserList[index].url = undefined;
      }
    }
  }

  delete(index: number): void {
    if (this.systemUserList != null) {
      this.systemUserList.splice(index, 1);
    }
  }

  saveClick(): void {
    if (this.systemValue != null && this.systemValue.id != null && this.systemUserList != null && this.isValid()) {
      this.loading = true;
      this.systemService.createOrUpdate(this.systemValue.id, this.systemUserList).subscribe(x => {
        this.loading = false;
        this.systemUserList = x;
      });
    }
  }

  isValid(): boolean {
    if (this.systemUserList != null) {
      this.isInvalid = [];
      this.systemUserList.forEach((val, index) => {
        this.isInvalid[index] = val.chave == null || val.chave === '' ||
          val.usuario == null || val.usuario === '' ||
          val.senha == null || val.senha === '';
      });
      return this.isInvalid.filter(x => x).length === 0;
    }
    return false;
  }
}
