<app-select-report [searchTitle]="titleSearch" [data]="cpfOrHash" (searchSubmit)="selectedType($event)" label="SELECIONAR"></app-select-report>

<div class="container top-buffer">
    <table class="table-blue">
      <thead>
      <tr>
        <th class="container-title" colspan="11">
          <div class="table-title">Histórico</div>
        </th>
      </tr>
      <tr>
        <th class="cursor">Usuário</th>
        <th class="cursor">Nome do arquivo</th>
        <th class="cursor">Tipo do relatório</th>
        <th class="cursor">Total de linhas</th>
        <th class="cursor">Linhas processadas</th>
        <th class="cursor">Status</th>
        <th class="cursor">Solicitou em</th>
        <th class="cursor">Atualizado em</th>
        <th class="cursor">Download</th>
      </tr>
      <tr></tr>
      </thead>
      <tbody *ngIf="historyReport != null && historyReport != undefined">
      <tr *ngFor="let history of historyReport| paginate : {
                  id:'history-report',
                  itemsPerPage: size,
                  currentPage: page,
                  totalItems: historyReport.length
                };">
        <td>{{ history.username }}</td>
        <td>{{ history.fileName }}</td>
        <td>{{ history.reportName }}</td>
        <td>{{ history.numberLines }}</td>
        <td>{{ history.processedLines }}</td>
        <td>{{ history.status }}</td>
        <td>{{ history.createdAt | date:'dd/MM/yyyy HH:mm:ss' }}</td>
        <td>{{ history.updateAt | date:'dd/MM/yyyy HH:mm:ss' }}</td>
        <td>
          <a *ngIf="history.status == 'Concluído'" href="javascript:void(0)" (click)="downloadFileReport(history.id)">Baixar</a>
        </td>
      </tr>
      <tr *ngIf="historyReport == null || historyReport == undefined">
        <td colspan="11">Nenhum histórico encontrado</td>
      </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-center top-buffer">
      <pagination-controls id="history-report" [responsive]="false" [previousLabel]="labelPrevious" [nextLabel]="labelNext" (pageChange)="tabSize($event)">
      </pagination-controls>
    </div>
  </div>

