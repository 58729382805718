<app-loading *ngIf="loading" [backgroundBlue]="true"></app-loading>

<app-publish-terms-of-use
  *ngIf="!insert && !loading"
  [terms]="terms"
  [medium]="true"
  (onPublish)="published()"
>

</app-publish-terms-of-use>

<app-edit-terms-of-use
  *ngIf="insert && !loading"
  (onsave)="saveTerm()"
  (oncancel)="cancelEdit($event)"
  [term]="term"
  [terms]="terms"
></app-edit-terms-of-use>

<div class="container top-buffer" *ngIf="!insert && !loading">
  <div class="insert-container">
    <app-button-edit
      label="Incluir novo"
      [primary]="false"
      (onclick)="insertClick()"
    ></app-button-edit>
  </div>
</div>

<app-list-terms-of-use
  *ngIf="!insert && !loading"
  [terms]="terms"
  (onedit)="setTerm($event)"
>
</app-list-terms-of-use>
