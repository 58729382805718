import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiServices} from './api.services';
import { SuspdCredor, SuspdPoliticaCalculo, SuspdPoliticaParcelamento, SuspdSistema} from '../../models/domain';
import {AuditLogService} from './audit-log.service';

@Injectable()
export class PartnerService {

  constructor(protected apiService: ApiServices, private auditLogService: AuditLogService) {
  }

  getInstallments(partnerId: number): Observable<SuspdPoliticaParcelamento[]> {
      return this.apiService.searchPoliticCalc(partnerId);
  }

  getPoliticCalc(installmentId: number): Observable<SuspdPoliticaCalculo[]> {
      return this.apiService.searchInstallment(installmentId);
  }

  setInstallments(suspdPoliticaParcelamento: SuspdPoliticaParcelamento): Observable<SuspdPoliticaParcelamento> {
      return this.apiService.setInstallments(suspdPoliticaParcelamento);
  }

  setPoliticCalc(suspdPoliticaCalculo: SuspdPoliticaCalculo): Observable<SuspdPoliticaCalculo> {
      return this.apiService.setPoliticCalc(suspdPoliticaCalculo);
  }

  loadAuditLogs(id: number): void {
    this.apiService.loadPartnerAuditLogs(id).subscribe(logs => {
      this.auditLogService.emitLog(logs);
    });
  }

  deleteSuspdPoliticaParcelamento(id: number): Observable<SuspdPoliticaParcelamento> {
    return this.apiService.deleteSuspdPoliticaParcelamento(id);
  }

  deleteSuspdPoliticaCalculo(id: number): Observable<SuspdPoliticaCalculo> {
    return this.apiService.deleteSuspdPoliticaCalculo(id);
  }

  downloadCalcPolicyCsv(policyId: number, policyKey: string): Observable<Blob> {
    return this.apiService.downloadCalcPolicyCsv(policyId, policyKey);
  }

  batchUpdate(creditorId: number, installmentPolicyId: number, uploadFile: File): Observable<any> {
    return this.apiService.batchUpdate(creditorId, installmentPolicyId, uploadFile);
  }

  updateCalculationPolicy(uploadFile: File): Observable<any> {
    return this.apiService.updateCalculationPolicy(uploadFile);
  }

  updateInstallmentPolicy(creditorId: number, uploadFile: File): Observable<any> {
    return this.apiService.updateInstallmentPolicy(creditorId, uploadFile);
  }

  isBlocked(): Observable<any> {
    return this.apiService.isBlocked();
  }

  uploadFileReport(customerReportRequest: FormData): Observable<any> {
    return this.apiService.uploadFileReport(customerReportRequest);
  }

  listReport(): Observable<any> {
    return this.apiService.listReport();
  }

  downloadFileReport(id: number): Observable<any> {
    return this.apiService.downloadFileReport(id);
  }

  filterPolicies(id: number, filterPolicies: any): Observable<any> {
    return this.apiService.filterPolicies(id, filterPolicies);
  }
}
