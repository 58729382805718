<div class="container">
  <form [formGroup]="form">
    <div class="search-box middle-aligned-container align-items-center">
      <div class="title-search">Políticas de Privacidade</div>
      <div class="row top-buffer">
        <div class="publish-input-container">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="publish-image">VERSÃO</div>
            </div>
            <select
              [ngClass]="{
                'publish-lg': large,
                'publish-md': medium,
                'publish-sm': !large && !medium
              }"
              class="publish-input form-control"
              formControlName="policy"
            >
              <option value="" [disabled]="true"></option>
              <option *ngFor="let policy of policies" [ngValue]="policy">
                {{ policy.version }}
              </option>
            </select>
          </div>
        </div>
        <button class="publish-button" (click)="publish()" [disabled]="form.invalid">PUBLICAR</button>
      </div>
    </div>
  </form>
</div>
