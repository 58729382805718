import {Component, Input} from '@angular/core';
import {SuspdCredor, SuspdPoliticaParcelamento} from '../../../../models/domain';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {sortBy} from 'sort-by-typescript';
import {FormBuilder, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {PartnerService} from '../../../../core/services/partner.service';
import {NavigationService} from '../../../../core/services/navigationService';
import {isDateBefore} from '../../../../core/validator/date.validator';

@Component({
  selector: 'app-installment-calc-list-edit',
  templateUrl: './installment-detail-list-edit.component.html',
  styleUrls: ['./installment-detail-list-edit.component.scss']
})
export class InstallmentDetailListEditComponent {

  public installmentCalcList?: SuspdPoliticaParcelamento;
  public installmentCalcListSort: any;
  public sort: any;
  public desc = false;
  public setaSort: any = 'seta-baixo';
  loadingTitle = 'Aguarde';
  loading = false;
  labelSave = 'OK, CONFIRMAR';
  labelCancel = 'CANCELAR EDIÇÕES';

  size = 10;
  page = 1;

  labelPrevious = 'Ant.';
  labelNext = 'Próx.';
  numberRegEx = /\-?\d*\.?\d{1,2}/;

  constructor(private modalService: NgbModal,
              private formBuilder: FormBuilder,
              private datePipe: DatePipe,
              private router: Router,
              private navigationService: NavigationService,
              private partnerService: PartnerService,
              public activeModal: NgbActiveModal) {
  }


  politicForm = this.formBuilder.group({
    id: [],
    chave: ['', [Validators.required]],
    diasAtrasoDe: ['', [Validators.required, Validators.pattern(this.numberRegEx)]],
    diasAtrasoAte: ['', [Validators.required, Validators.pattern(this.numberRegEx)]],
    valorDividaDe: ['', [Validators.required, Validators.pattern(this.numberRegEx)]],
    valorDividaAte: ['', [Validators.required, Validators.pattern(this.numberRegEx)]],
    dataCriacao: [],
    suspdCredor: [],
    genericRangeStart: [],
    genericRangeEnd: [],
    genericRangeValue: [],
  });

  @Input()
  public setPoliticCalcListValue(installmentList: SuspdPoliticaParcelamento): void {
    this.loading = true;
    if (installmentList !== undefined) {
      this.installmentCalcList = installmentList;
      this.politicForm.setValue({
        id: this.installmentCalcList?.id,
        chave: this.installmentCalcList?.chave,
        diasAtrasoDe: this.installmentCalcList?.diasAtrasoDe,
        diasAtrasoAte: this.installmentCalcList?.diasAtrasoAte,
        valorDividaDe: this.installmentCalcList?.valorDividaDe,
        valorDividaAte: this.installmentCalcList?.valorDividaAte,
        dataCriacao: this.installmentCalcList?.dataCriacao,
        suspdCredor: this.installmentCalcList?.suspdCredor,
        genericRangeStart: this.installmentCalcList?.genericRangeStart,
        genericRangeEnd: this.installmentCalcList?.genericRangeEnd,
        genericRangeValue: this.installmentCalcList?.genericRangeValue,
      });
    }
    this.loading = false;
  }

  @Input()
  public setNewPoliticCalcListValue(suspdCredor: SuspdCredor): void {
    this.loading = true;
    if (suspdCredor !== undefined) {
      this.politicForm.controls.suspdCredor.setValue(suspdCredor);
    }
    this.loading = false;
  }

  sortTable(sort: string): void {
    if(this.sort === sort && this.desc === false){
      this.desc = true;
      this.setaSort = 'seta-cima';
    }else{
      this.desc = false;
      this.setaSort = 'seta-baixo';
    }
    this.sort = sort;
    if (this.desc){
      this.installmentCalcListSort.sort(sortBy('-'.concat(sort)));
    }else{
      this.installmentCalcListSort.sort(sortBy(sort));
    }
  }

  tabSize($event: number): void {
    this.page = $event;
  }

  onSubmit(): void {
    this.loading = true;
    if (this.politicForm != null && this.politicForm.valid) {
      const objPoliticCalcList = this.politicForm.value;
      if ( objPoliticCalcList.dataCriacao === undefined || objPoliticCalcList.dataCriacao == null){
        objPoliticCalcList.dataCriacao = new Date();
      }
      this.partnerService.setInstallments(objPoliticCalcList).subscribe(newListSuspdPoliticaParcelamento => {
        this.activeModal.close(true);
      }, err => {
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }

  onCancel(): void {
    this.politicForm.reset();
  }

}
