<div class="container">
  <form (ngSubmit)="onSubmit()" [formGroup]="searchForm">
    <div class="search-box middle-aligned-container align-items-center">
      <div class="title-search">{{ searchTitle }}</div>
      <div class="row top-buffer">
        <div class="search-input-container">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text bg-white search-image">
                  <img class="icon" src="assets/svg/icons/search.svg" alt="Pesquisa">
              </span>
            </div>
            <select [ngClass]="{'search-lg': large, 'search-md': medium, 'search-sm': !large && !medium}" class="search-input form-control" formControlName="value">
              <option *ngIf="data.length === 0" value="">Vazio</option>
              <option *ngIf="data.length > 0" value="">Selecione</option>
              <option *ngFor="let option of data" [ngValue]="option">{{ getDescriptionFn != null ? getDescriptionFn(option) : option.toString() }}</option>
            </select>
          </div>
        </div>
        <button class="search-button" type="submit">{{label}}</button>
      </div>
    </div>
  </form>
</div>
