<div class="container top-buffer">
  <table class="table-blue">
    <caption class="description">Bloqueio de dívida</caption>
    <thead>
      <tr>
        <th scope="col" class="container-title" colspan="11">
          <div class="table-title">Dívidas bloqueadas</div>
        </th>
      </tr>
      <tr>
        <th scope="col" class="cursor">CPF</th>
        <th scope="col" class="cursor">Razão</th>
        <th scope="col" class="cursor">Parceiro</th>
        <th scope="col" class="cursor"></th>
      </tr>
      <tr></tr>
    </thead>
    <tbody *ngIf="blocks != null && blocks != undefined">
      <tr *ngFor="let block of blocks| paginate : {
                    id:'block-list',
                    itemsPerPage: size,
                    currentPage: page,
                    totalItems: blocks.length
                  };">
        <td>{{ block.customerId }}</td>
        <td>{{ block.reason }}</td>
        <td>{{ block.credor }}</td>
        <td><a href="javascript:void(0)" (click)="removeFromBlock(block)"><img class="icon-large remove-icon"
              src="assets/svg/icons/remover.svg" alt="Excluir"></a></td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-center top-buffer">
    <pagination-controls id="block-list" [responsive]="false" previousLabel="Ant." nextLabel="Próx."
      (pageChange)="tabSize($event)">
    </pagination-controls>
  </div>
</div>
