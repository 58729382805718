<form (ngSubmit)="onSubmit()" [formGroup]="dealStatusForm" *ngIf="deal != undefined">
  <div class="modal-header modal-green">
    <h4 class="modal-title">Alterar status do acordo</h4>
    <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label for="status">Status</label>
    <select class="form-control" formControlName="status" id="status">
      <option value="0" [selected]="deal.status === 0">Cancelado</option>
      <option value="1" [selected]="deal.status === 1">Ativo</option>
    </select>
  </div>
  <div class="modal-footer modal-green">
    <button class="btn btn-primary btn-rounded" type="submit" [disabled]="saving">Salvar</button>
    <button (click)="activeModal.close('Close click')" class="btn btn-rounded btn-secondary" type="button">Cancelar</button>
  </div>
</form>
