import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

  @Input() backgroundBlue = false;
  @Input() backgroundDarkBlue = false;
  @Input() backgroundGreen = false;
  @Input() title?: string;

}
