import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = [];
  private param: string[] = [];

  constructor(private router: Router, private location: Location) {
  }

  back(): void {
    if (this.history.length > 0) {
      if(this.param != null && this.param.length > 0) {
        this.router.navigate([this.history[0], this.param[0]]);
      }else{
        this.router.navigate([this.history[0]]);
      }
    } else {
      this.location.back();
    }
  }

  populate(url: string, params: any): void {
    this.history = [];
    this.history.push(url);
    this.param.push(params);
  }

}
