<app-search-by-cpf [titleValue]="searchTitle" (searchByCpfSubmit)="setCpf($event)"></app-search-by-cpf>

<div class="container top-buffer" *ngIf="!!authorityResponse && !loading">
  <table class="table-blue">
    <caption class="description">Resultados da busca</caption>
    <thead>
    <tr>
      <th class="container-title" colspan="11" scope="col">
        <div class="table-title">Resultado</div>
      </th>
    </tr>
    <tr>
      <th class="cursor" scope="col">Usuário</th>
      <th class="cursor" scope="col">CPF</th>
      <th class="cursor" scope="col">Administrador</th>
      <th class="cursor" scope="col"></th>
    </tr>
    <tr></tr>
    </thead>
    <tbody>
        <tr>
        <td>{{ authorityResponse.devedor.nome }}</td>
        <td>{{ authorityResponse.devedor.cpf }}</td>
        <td *ngIf="isAdmin">Sim</td>
        <td *ngIf="isNotAdmin">Não</td>
        <td *ngIf="isAdmin"><a class="lnk-red" (click)="onClickRemoveAdminDevedor(authorityResponse)">Remover</a></td>
        <td *ngIf="isNotAdmin"><a class="lnk" href="javascript:void(0)" (click)="onClickAddAdminDevedor(authorityResponse)">Adicionar</a></td>
      </tr>
    </tbody>
  </table>
</div>

<app-loading *ngIf="loading" [backgroundBlue]="true"></app-loading>
<app-pending-search *ngIf="!authorityResponse"></app-pending-search>

<app-audit-log></app-audit-log>


