import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServices } from './api.services';

@Injectable({
  providedIn: 'root',
})
export class JuridicalService {
  constructor(private http: HttpClient, private apiServices: ApiServices) {}

  getTermsOfUse(): Observable<any> {
    return this.apiServices.getTermsOfUse();
  }

  getPrivacyPolicies(): Observable<any> {
    return this.apiServices.getPrivacyPolicies();
  }

  createPrivacyPolicies(policy: any): Observable<any> {
    return this.apiServices.createPrivacyPolicies(policy);
  }

  createTermsOfUse(term: any): Observable<any> {
    return this.apiServices.createTermsOfUse(term);
  }

  publishSitePolicies(privacyPolicyId?: any, termsOfUseId?: any): Observable<any> {
    let privacyAndTermId = {"privacyPolicyId": privacyPolicyId, "termsOfUseId": termsOfUseId}
    return this.apiServices.publishSitePolicies(privacyAndTermId);
  }
}
