export const PaymentMethods = {
  BILLET: {
    value: "BILLET",
    label: "Boleto"
  },
  CREDIT_CARD: {
    value: "CREDIT_CARD",
    label: "Cartão de crédito"
  }
};
