import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {

  saving = false;
  @Input() pergunta = '';

  constructor(public activeModal: NgbActiveModal) {
  }

  confirmar(): void {
    this.saving = true;
    this.activeModal.close(true);
  }

}
