import {Component} from '@angular/core';
import {BilletsService} from '../../../core/services/billets.service';
import {BilletAlertsInfo} from '../../../models/domain';
import {HelperService} from '../../../core/services/helper.service';

@Component({
  selector: 'app-consulta-billets',
  templateUrl: './consulta-billets.component.html',
  styleUrls: ['./consulta-billets.component.scss']
})

export class ConsultaBilletsComponent {
  titleSearch = 'Consulte os Preventivos de um CPF';
  placeHolderSearch = '000.000.000-00';

  cpf = '';
  loading = false;
  billetsInfo: BilletAlertsInfo[] | undefined;

  constructor(private billetsService: BilletsService, private helperService: HelperService) {
  }

  searchSubmit(val: string): void {
    this.cpf = val;
    this.loading = true;
    this.billetsService.searchBillets(this.cpf).subscribe(billetsList => {
      this.loading = false;
      this.billetsInfo = billetsList;
    }, error => {
      this.loading = false;
    });
  }
}
