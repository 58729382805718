import { Component } from '@angular/core';
import CpfUtils from '../../core/services/utils/cpf.utils';
import { HelperService } from '../../core/services/helper.service';
import { MartketplaceAdminService } from '../../core/services/marketplace-admin.service';
import { AuthorityResponse } from '../../models/response/AuthorityResponse.mode';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from '../shared/confirm/confirm.component';
import { finalize } from 'rxjs/operators';
import { AuthorityRequest } from '../../models/request/AuthorityRequest.model';

@Component({
  selector: 'app-marketplace-admin',
  templateUrl: './marketplace-admin.component.html',
  styleUrls: ['./marketplace-admin.component.scss']
})

export class MarketplaceAdminComponent {
    
  loading = false;
  searchTitle = 'Consulte um CPF';
  authorityResponse?: AuthorityResponse;
  idAuthorityRemove: number = 1;
  idAuthorityAdd: number = 2;
  authorityResquest: AuthorityRequest = {} as AuthorityRequest ;

  constructor(private marketplaceAdminService: MartketplaceAdminService, private helperService: HelperService, 
              private modalService: NgbModal) {}

  public setCpf(cpf: number) {
    this.loading = true;
    if (this.isValidCpf(CpfUtils.convertToString(cpf))) {
      this.authorityResquest.cpf = CpfUtils.convertToString(cpf);
      this.devedorAuthority(this.authorityResquest);
    } else {
      this.loading = false;
      this.helperService.showError('CPF invalido');
    }
  }

  private devedorAuthority(authorityRequest: AuthorityRequest): void {
    this.marketplaceAdminService.devedorAuthority(authorityRequest).pipe(finalize(() => this.loading = false))
    .subscribe(authorityResponse => {
      this.authorityResponse = authorityResponse;
    });
  }

  public onClickAddAdminDevedor(authority: AuthorityResponse) {
    this.loading = true;
    this.showModal('adicionar', this.idAuthorityAdd, authority);
  }

  public onClickRemoveAdminDevedor(authority: AuthorityResponse) {
    this.loading = true;    
    this.showModal('remover', this.idAuthorityRemove, authority);
  }

  public showModal(message: string, authorityId: number, authority: AuthorityResponse): void {
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.pergunta = `Deseja ${message} ${authority.devedor.cpf} como administrador do Marketplace?`;
    modalRef.closed.subscribe(response => {
      if (response) {
        this.handleModalResponse(authorityId, authority);
      } else {
        this.loading = false;
      }
    });
  }

  private putDevedorChangeAuthority(authorityRequest: AuthorityRequest): void {
    this.marketplaceAdminService.putDevedorChangeAuthority(authorityRequest).subscribe(authorityResponse => {
      this.loading = false;
      this.authorityResponse = authorityResponse;
    }, () => {
      this.loading = false;
    });
  }

  public isValidCpf(cpf: string): boolean {
    return CpfUtils.isValid(cpf);
  }

  handleModalResponse(authorityId: number, authority: AuthorityResponse): void {
    if (this.isValidCpf(authority.devedor.cpf)) {
      this.authorityResquest.cpf = CpfUtils.unformat(authority.devedor.cpf);
      this.authorityResquest.authorityId = authorityId;
      this.putDevedorChangeAuthority(this.authorityResquest);
    } else {
      this.helperService.showError('CPF invalido');
    }
  }

  get isAdmin(): boolean {
    return !!this.authorityResponse?.admin;
  }

  get isNotAdmin(): boolean {
    return !this.isAdmin;
  }
}
