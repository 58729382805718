import {LOCALE_ID, NgModule} from '@angular/core';
import {EditRoleUserComponent} from './edit-user-role/edit-role-user.component';
import {EditRoleHeaderComponent} from './edit-role-header/edit-role-header.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaskPipe, NgxMaskModule} from 'ngx-mask';
import {ToastrModule} from 'ngx-toastr';
import {AuthInterceptor, ErrorInterceptor} from '../../core/security/auth-interceptor';
import {CommonModule, DatePipe} from '@angular/common';
import {AcCustomerModule} from '../../ac-customer.module';
import {NgbDateBRParserFormatter} from '../../ngb-date-br-parser-formatter';
import {SearchDebtorModule} from '../search-debtor/search-debtor.module';
import {ListUserComponent} from './list-user/list-user.component';
import {NgxPaginationModule} from 'ngx-pagination';


@NgModule({
  declarations: [
    EditRoleUserComponent,
    EditRoleHeaderComponent,
    ListUserComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AcCustomerModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    SearchDebtorModule,
    NgxPaginationModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: NgbDateParserFormatter, useClass: NgbDateBRParserFormatter},
    {provide: LOCALE_ID, useValue: 'pt'},
    DatePipe,
    MaskPipe
  ],
})
export class RolesModule {
}
