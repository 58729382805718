import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiServices } from './api.services';
import { AuthorityResponse } from 'src/app/models/response/AuthorityResponse.mode';
import { AuthorityRequest } from '../../models/request/AuthorityRequest.model';

@Injectable()
export class MartketplaceAdminService {

  constructor(private apiService: ApiServices) {
  }

  devedorAuthority(authorityRequest: AuthorityRequest): Observable<AuthorityResponse> {
    return this.apiService.devedorAuthority(authorityRequest);
  }

  putDevedorChangeAuthority(authorityRequest: AuthorityRequest): Observable<AuthorityResponse> {
    return this.apiService.putDevedorChangeAuthority(authorityRequest);
  }

}