/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.30.840 on 2021-07-30 01:17:28.

export interface AuditLog {
  auditLogId?: number;
  userName?: string;
  userIp?: string;
  dateTime?: Date;
  entityName?: string;
  operation?: string;
  registryId: string;
  detail: AuditDetailLog[];
}

export interface AuditDetailLog {
  auditLogDetailId: number;
  columnName: string;
  columnValue: string;
}

export class DebtorInfo {
  debtor?: Devedor;
  deals?: SuspdAcordo[];
  debts?: SuspdDivida[];
}

export class CipInfo {
  wasPaid?: boolean;
  canBePaid?: boolean;
  drawer?: string;
  taxNumberBeneficiary?: string;
  taxNumberDrawee?: string;
  paymentDate?: Date;
  dateDueRegistration?: Date;
  beneficiaryName?: string;
  draweeName?: string;
  discountValue?: number;
  interestValue?: number;
  maxPaymentValue?: number;
  minPaymentValue?: number;
  fineValue?: number;
  nominalValue?: number;
  updatedValue?: number;
  totalRebateValue?: number;
  totalAddedValue?: number;
  settlementDate?: Date;
  dueDate?: Date;
  nextSettlement?: boolean;
  digitableLine?: string;
  value?: number;
  maxValue?: number;
  minValue?: number;
  paymentWasMade?: boolean;
}

export class UserInfo {
  username?: string;
  creationDate?: Date;
  token?: string;
}

export class BlockListInfo {
  source?: string;
  type?: string;
  value?: string;
  reason?: string;
  partner?: string;
  creationDate?: Date;
}

export class ActivationsInfo {
  documento?: string;
  parceiro?: string;
  data_acionamento?: Date;
  canal_acionamento?: string;
  contato?: string;
  observacao?: string;
}

export class BilletAlertsInfo {
  action?: string;
  actionDescription?: string;
  document?: string;
  contact?: string;
  contact_type?: string;
  content?: string;
  installment_id?: string;
  reference_date?: string;
  creation_time?: Date;
  billet_status?: string;
  billetStatusDescription?: string;
}

export class AuthorityInfo {
  role?: string;
  grantedAuthorities?: GrantedAuthority[];
}

export class Authority {
  id?: number;
  name?: string;
}

export class Devedor {
  id?: number;
  cpf?: string;
  nome?: string;
  dataNascimento?: Date;
  status?: number;
  descricaoStatus?: string;
  dataCriacao?: Date;
  signInProvider?: string;
  activationKey?: string;
  loginFailedAttempts?: number;
  ip?: string;
  token?: string;
  sexo?: string;
  imageUrl?: string;
  socialId?: string;
  newsletter?: number;
  socialName?: string;
  emails?: Email[];
  telefones?: Telefone[];
  acordos?: SuspdAcordo[];
}

export class DevedorPropriedade {
  id?: number;
  chave?: string;
  valorLong?: number;
  valorBoolean?: number;
  valorString?: string;
  valorDecimal?: number;
  valorDate?: Date;
}

export class Email {
  id?: number;
  tipoEmail?: TipoEmail;
  email?: string;
  dataCriacao?: Date;
  principal?: number;
  status?: number;
  categoria?: string;
  score?: number;
  origemValidacao?: string;
  dataAtualizacao?: Date;
}

export class SuspdAcordo {
  id?: number;
  devedorId?: Devedor;
  sistemaId?: SuspdSistema;
  credorId?: SuspdCredor;
  dividaId?: SuspdDivida;
  dataAcordo?: Date;
  contrato?: string;
  identificador?: string;
  valorAcordo?: number;
  qtdParcela?: number;
  dataCancelamento?: Date;
  status?: number;
  descricaoStatus?: string;
  dataCriacao?: Date;
  dataAtualizacao?: Date;
  canalNegociacao?: string;
  parcelas?: SuspdAcordoParcela[];
}

export class SuspdAcordoParcela {
  id?: number;
  acordo?: SuspdAcordo;
  parcela?: number;
  valorParcela?: number;
  dataVencimento?: Date;
  dataPagamento?: Date;
  status?: number;
  descricaoStatus?: string;
  statusSms?: number;
  statusEmail?: number;
  statusImport?: number;
  dataCriacao?: Date;
  dataAtualizacao?: Date;
  propriedades?: SuspdAcordoParcelaPropriedade[];
  pendingPayment?: boolean;
}

export class SuspdAcordoPropriedade {
  id?: number;
  acordoId?: number;
  acordo?: SuspdAcordo;
  chave?: string;
  valorLong?: number;
  valorBoolean?: number;
  valorString?: string;
  valorDecimal?: number;
  valorBlob?: string;
}

export class SuspdCredor {
  id?: number;
  nome?: string;
  identificador?: string;
  representacao?: string;
  status?: number;
  show?: number;
  order?: number;
  segmento?: string;
  msUrl?: string;
  logoUrl?: string;
  credorTipo?: string;
  beanName?: string;
}

export class SuspdCredorConfiguracao {
  id?: number;
  credorId?: number;
  suspdCredor?: SuspdCredor;
  chave?: string;
  valorString?: string;
  valorInt?: number;
  valorDecimal?: number;
  valorDate?: Date;
  valorBoolean?: number;
  dataCriacao?: Date;
}

export class SuspdCredorDetalhe {
  id?: number;
  documento?: string;
  nomeFantasia?: string;
  banco?: string;
  agencia?: string;
  agenciaDigito?: string;
  conta?: string;
  contaDigito?: string;
  carteira?: string;
  endereco?: string;
  bairro?: string;
  cidade?: string;
  estado?: string;
  complemento?: string;
  cep?: string;
  locaisPagamento?: string;
  instrucoes?: string;
  especieDocumento?: string;
  status?: number;
  url?: string;
  telefonePrincipal?: string;
  telefoneSecundario?: string;
}

export class SuspdDivida {
  id?: number;
  politicaParcelamentoId?: number;
  dataPesquisa?: Date;
  dataSimulacao?: Date;
  dataCarregamento?: Date;
  contrato?: string;
  identificador?: string;
  cpf?: string;
  valor?: number;
  status?: number;
  descricaoStatus?: string;
  diasAtraso?: number;
  valorDivida?: number;
  valorMinimo?: number;
  valorAcrescimo?: number;
  dividaAuxId?: number;
  tipoDivida?: string;
  tags?: string;
  canalNegociacao?: string;
  produto?: string;
  codigoTitulo?: string;
  origem?: string;
  ultimoAcordoId?: number;
}

export class SuspdDividaDetalhe {
  id?: number;
  divida?: SuspdDivida;
  dividaId?: number;
  produto?: string;
  contrato?: string;
  dataOriginalDivida?: Date;
  diasAtraso?: number;
  parcela?: number;
  valorOriginal?: number;
  valorJuros?: number;
  qtdParcelas?: number;
  descricao?: string;
  origem?: string;
}

export class SuspdDividaOculta {
  id?: number;
  codigoTitulo?: string;
  cpf?: string;
  dataCriacao?: Date;
}

export class SuspdDividaPropriedade {
  id?: number;
  chave?: string;
  valorLong?: number;
  valorBoolean?: number;
  valorString?: string;
  valorDecimal?: number;
  valorDate?: Date;
}

export class SuspdOpcaoPagamento {
  id?: number;
  divida?: SuspdDivida;
  dividaId?: number;
  qtdParcela?: number;
  valorAcordo?: number;
  valorParcela?: number;
  valorEntrada?: number;
  formaPagamento?: string;
  politicaCalculoId?: number;
  dataSimulacao?: Date;
  valorDesconto?: number;
  externalId?: string;
}

export class SuspdOpcaoPagamentoPropriedade {
  id?: number;
  chave?: string;
  valorLong?: number;
  valorBoolean?: number;
  valorString?: string;
  valorDecimal?: number;
}

export class SuspdPagamento {
  id?: number;
  dataPagamento?: Date;
  valorPago?: number;
  dataBaixa?: Date;
  status?: number;
}

export class SuspdPoliticaCalculo {
  id?: number;
  parcela?: number;
  percentualDescontoMinimo?: number;
  percentualDescontoMaximo?: number;
  percentualDescontoPrincipal?: number;
  percentualMulta?: number;
  percentualJurosAtraso?: number;
  percentualJurosFinanciamento?: number;
  percentualComissao?: number;
  percentualEntradaMinima?: number;
  valorParcelaMinima?: number;
  dataCriacao?: Date;
  diasSimulacaoMaximo?: number;
  chave?: string;
  suspdPoliticaCalculos?: SuspdPoliticaCalculo[];
  suspdPoliticaParcelamento?: SuspdPoliticaParcelamento;
  suspdPoliticaParcelamentoId?: number;
}

export class SuspdPoliticaParcelamento {
  id?: number;
  chave?: string;
  diasAtrasoDe?: number;
  diasAtrasoAte?: number;
  valorDividaDe?: number;
  valorDividaAte?: number;
  dataCriacao?: Date;
  suspdCredor?: SuspdCredor;
  suspdCredorId?: number;
  genericRangeStart?: number;
  genericRangeEnd?: number;
  genericRangeValue?: number;
}

export class SuspdSistema {
  id?: number;
  nome?: string;
  bean?: string;
  status?: number;
  identificador?: string;
  msUrl?: string;
  imageUrl?: string;
  creditorList?: SuspdCredor[];
}

export class SuspdSistemaUsuario {
  id?: number;
  sistemaId?: number;
  sistema?: SuspdSistema;
  chave?: string;
  usuario?: string;
  senha?: string;
  url?: string;
  status?: number;
  dataAtualizacao?: Date;
}

export class Telefone {
  id?: number;
  tipoTelefone?: TipoTelefone;
  ddd?: string;
  telefone?: string;
  dataCriacao?: Date;
  principal?: number;
}

export class TipoEmail {
  id?: number;
  tipo?: string;
}

export class TipoEndereco {
  id?: number;
  tipo?: string;
}

export class TipoTelefone {
  id?: number;
  tipo?: string;
}

export class UserRole {
  userName?: string;
  role?: Role;
}

export class Role {
  roleId?: number;
  name?: string;
  functions?: Function[];
}

export interface GrantedAuthority extends Serializable {
  authority?: string;
}

export class SuspdAcordoParcelaPropriedade {
  id?: number;
  acordoParcelaId?: number;
  parcela?: SuspdAcordoParcela;
  chave?: string;
  valorLong?: number;
  valorBoolean?: number;
  valorString?: string;
  valorDecimal?: number;
  valorBlob?: any;
  dataCriacao?: Date;
  dataAlteracao?: Date;
}

export class Function {
  name?: string;
}

export interface Serializable {
}

export class CpfInfoResponse {
  hash?: string;
  cpf?: string;
}

export class HistoryReport {
  id?: number;
  reportName?: string;
  username?: string;
  status?: string;
  storagePath?: string;
  createdAt?: Date;
  updateAt?: Date;
  numberLines?: number;
  processedLines?: number;
  fileName?: string;
}

export interface GenerateLgpdReportRequest {
  emailToSendPubSub: string;
  userEmail: string;
  document: string;
  phoneString: string;
}

export interface LgpdReportResponse {
  id: number;
  customerId: string;
  customerEmail: string;
  emailToSend: string;
  telephone: string;
  createdAt: Date;
}
export interface BlockRequest {
  document: string;
  creditorId: number;
  reason: string;
}

export interface Block {
  id: number;
  customerId: string;
  partnerId: number;
  reason: string;
  updatedAt: Date;
  createdAt: Date;
}

export interface BlockConvert {
  id: number;
  customerId: string;
  reason: string;
  credor: string;
  partnerId: number;
}
