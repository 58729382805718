import { LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptor, ErrorInterceptor } from '../../core/security/auth-interceptor';
import { CommonModule, DatePipe } from '@angular/common';
import { AcCustomerModule } from '../../ac-customer.module';
import { NgbDateBRParserFormatter } from '../../ngb-date-br-parser-formatter';
import { DebtBlockingComponent } from './debt-blocking/debt-blocking.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SearchDebtBlocking } from './search-debt-blocking/search-debt-blocking.component';
import { TableDebtBlockingComponent } from './table-debt-blocking/table-debt-blocking.component';
import { FormDebtBlockingComponent } from './form-debt-blocking/form-debt-blocking.component';


@NgModule({
  declarations: [
    DebtBlockingComponent,
    SearchDebtBlocking,
    TableDebtBlockingComponent,
    FormDebtBlockingComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AcCustomerModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    NgxPaginationModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: NgbDateParserFormatter, useClass: NgbDateBRParserFormatter },
    { provide: LOCALE_ID, useValue: 'pt' },
    DatePipe,
    MaskPipe
  ],
})
export class DebtBlockingModule {
}
