<nav *ngIf="userIsLoggedIn" class="navbar navbar-expand-lg">
  <a class="navbar-brand" href="#"><img alt="Customer - acordo certo" src="assets/svg/logo.svg"></a>
  <div id="navbarNav">
    <ul class="navbar-nav">
      <li *ngFor="let myMenu of menu" [ngClass]="{'selected-item': lastMenu == myMenu}"
          class="menu-item middle-aligned-container">
        <a (click)="menuClick(myMenu)" *ngIf="userHasAuthority(myMenu.link, myMenu.menu)"
           [ngClass]="{'selected-link': lastMenu == myMenu}" class="menu-link text-center"
           href="javascript:void(0)">{{ myMenu.title }}
          <img *ngIf="myMenu.menu != null" [ngClass]="{'white-icon': lastMenu == myMenu}" alt="Menu"
               class="icon"
               src="assets/svg/icons/chevron-down.svg"></a>
      </li>
    </ul>
  </div>
  <div class="small header-menu-user-container">
    <div class="header-menu-user-inner-container">
      <div>
        <div class="header-menu-user">{{ userName != null ? (userName) : "" }}</div>
        <div class="header-menu-role">{{ userName != null ? (role) : "" }}</div>
      </div>
      <div class="header-menu-user-logout-container">
        <a (click)="logout()" class="header-menu-bottum-exit" href="javascript:void(0)">Sair</a>
        <a (click)="logout()" href="javascript:void(0)">
          <img alt="Sair" class="icon-menu-large" src="assets/svg/icons/logout.svg">
        </a>
      </div>
    </div>
  </div>
</nav>
<div *ngIf="userIsLoggedIn && !shouldHideSubMenu" class="container">
  <div *ngIf="submenu != null" class="menu-ext middle-aligned-container align-items-center">
    <div class="menu-btn-container">
      <ng-container *ngFor="let mySubMenu of submenu; let last = last">
        <div *ngIf="userHasAuthority(mySubMenu.link, null)" class="menu-btn">
          <a (click)="subMenuClick(mySubMenu)" [ngClass]="{'submenu-item-selected': lastSubMenu == mySubMenu}"
             class="submenu-item"
             href="javascript:void(0)">{{ mySubMenu.title }}</a>
          <div [ngClass]="{'invisible': lastSubMenu != mySubMenu, 'visible': lastSubMenu == mySubMenu}"
               class="triangulo-para-cima-container">
            <div class="triangulo-para-cima"></div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
