import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {NgbDateStruct, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {HelperService} from '../../../core/services/helper.service';
import {CustomerService} from '../../../core/services/customer.service';
import {ActivatedRoute, Router} from '@angular/router';
import {sortBy} from 'sort-by-typescript';
import {NavigationService} from '../../../core/services/navigationService';
import { AcordoResponse } from '../../../models/response/acordo-response.model';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})

export class CustomerComponent {
  public customers: AcordoResponse[] =[];

  model: NgbDateStruct | undefined;
  public sort: any;
  public selectedDate: any;
  public desc = false;
  public setaSort: any = 'seta-baixo';
  loadingTitle = 'acordos';
  loading = false;

  @Input() size = 10;
  @Input() page = 1;

  labelPrevious = 'Ant.';
  labelNext = 'Próx.';

  constructor(private helper: HelperService, private router: Router,
              private customerService: CustomerService,
              private activatedRoute: ActivatedRoute,
              private navigationService: NavigationService
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      const dateReturn = params.get('selectedDate');
      if (dateReturn != null) {
        this.selectedDate = this.helper.date(dateReturn.toString()).toDate();
        this.loading = true;
        this.customerService.getCustomerByDate(dateReturn.toString()).subscribe({
          next: (response) => {
            this.loading = false;
            this.customers = response;
          }
        })
      } else {
      }
    });
  }

  onSubmit(): void {
    if (this.model != null) {
      this.selectedDate = new Date(this.model.year, this.model.month - 1, this.model.day);
      this.loading = true;
      this.sort = 'id';
      this.customerService.getCustomerByDate(this.helper.date(this.selectedDate).format('YYYY-MM-DD')).subscribe({
        next: (response) => {
          this.loading = false;
          this.customers = response;
        }
      })
    }
  }

  showModalDetail(cpf: string): void {
    this.navigationService.populate('/customer', {selectedDate:this.helper.date(this.selectedDate).format('YYYY-MM-DD')});
    this.router.navigate(['/search-debtor-by-cpf', {cpf: cpf.replace(/[-./]/g, '')}]);
  }

  sortTable(sort: string): void {
    if(this.sort === sort && this.desc === false){
      this.desc = true;
      this.setaSort = 'seta-cima';
    }else{
      this.desc = false;
      this.setaSort = 'seta-baixo';
    }
    this.sort = sort;
    if(this.desc){
      this.customers.sort(sortBy('-'.concat(sort)));
    }else{
      this.customers.sort(sortBy(sort));
    }
  }

  tabSize($event: number): void {
    this.page = $event;
  }

}
