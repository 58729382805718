import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from 'src/app/core/services/helper.service';
import { JuridicalService } from 'src/app/core/services/juridical.service';
import { ConfirmComponent } from '../../../shared/confirm/confirm.component';
import { LoadService } from 'src/app/tools/load/load.service';

@Component({
  selector: 'app-publish-terms-of-use',
  templateUrl: './publish-terms-of-use.component.html',
  styleUrls: ['./publish-terms-of-use.component.scss'],
})
export class PublishTermsOfUseComponent implements OnChanges {
  @Input() terms: any;
  @Input() large = false;
  @Input() medium = false;
  @Output() onPublish: EventEmitter<any> = new EventEmitter();

  form = this.formBuilder.group({
    term: new FormControl('', Validators.required),
  });

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private helperService: HelperService,
    private juridicalService: JuridicalService,
    private loadService: LoadService
  ) {}

  ngOnChanges(): void {
    this.terms.find((element: any) => {
      if (element.published === 1) {
        this.form.get('term')?.setValue(element);
      }
    });
  }

  publish() {
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.pergunta = `Publicar termos versão (${this.form.get('term')?.value?.version}) ?`;
    modalRef.closed.subscribe((x) => {
      if (x) {
        this.loadService.addLoad();
        this.juridicalService.publishSitePolicies('', this.form.get('term')?.value?.id).subscribe((ret: any) => {
          this.loadService.removeLoad();
              this.helperService.showMessage('Publicado com sucesso!');
              this.onPublish.emit();
            },
            (err) => {
              this.loadService.removeLoad();
            }
          );
      }
    });
  }
}
