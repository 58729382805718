import {LOCALE_ID, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaskPipe, NgxMaskModule} from 'ngx-mask';
import {ToastrModule} from 'ngx-toastr';
import {AuthInterceptor, ErrorInterceptor} from '../../core/security/auth-interceptor';
import {CommonModule, DatePipe} from '@angular/common';
import {AcCustomerModule} from '../../ac-customer.module';
import {NgbDateBRParserFormatter} from '../../ngb-date-br-parser-formatter';
import {ConsultaBlocklistComponent} from './consulta-blocklist/consulta-blocklist.component';
import {DetalheConsultaBlocklistComponent} from './detalhe-blocklist/detalhe-consulta-blocklist.component';


@NgModule({
  declarations: [
    ConsultaBlocklistComponent,
    DetalheConsultaBlocklistComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AcCustomerModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot()
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: NgbDateParserFormatter, useClass: NgbDateBRParserFormatter},
    {provide: LOCALE_ID, useValue: 'pt'},
    DatePipe,
    MaskPipe
  ],
})
export class BlocklistModule {
}
