import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/security/auth-guard';
import { LoginComponent } from './core/login/login.component';
import { SearchDebtorByCpfComponent } from './modules/search-debtor/search-debtor-by-cpf/search-debtor-by-cpf.component';
import { SearchHashByCpfComponent } from './modules/search-debtor/search-hash-by-cpf/search-hash-by-cpf.component';
import { SearchDebtorByEmailComponent } from './modules/search-debtor/search-debtor-by-email/search-debtor-by-email.component';
import { CustomerComponent } from './modules/customers/customer/customer.component';
import { EditRoleHeaderComponent } from './modules/roles/edit-role-header/edit-role-header.component';
import { ConsultaCipComponent } from './modules/cip/consulta-cip/consulta-cip.component';
import { ConsultaBlocklistComponent } from './modules/blocklist/consulta-blocklist/consulta-blocklist.component';
import { ConsultaActivationsComponent } from './modules/activations/consulta-activations/consulta-activations.component';
import { ConsultaBilletsComponent } from './modules/billets/consulta-billets/consulta-billets.component';
import { DummyComponent } from './core/menu/menu.component';
import { SearchIntegrationsComponent } from './modules/integrations/search-integrations/search-integrations.component';
import { SearchByPartnerComponent } from './modules/partner/search-by-partner/search-by-partner.component';
import { SearchSystemsComponent } from './modules/systems/search-systems/search-systems.component';
import { SearchCpfByHashComponent } from './modules/search-debtor/search-cpf-by-hash/search-cpf-by-hash.component';
import { ReportComponent } from './modules/search-debtor/report/report.component';
import { SendDataComponent } from './modules/send-data/send-data/send-data.component';
import { DebtBlockingComponent } from './modules/debt-blocking/debt-blocking/debt-blocking.component';
import { MarketplaceAdminComponent } from './modules/marketplace-admin/marketplace-admin.component';
import { HiddenDebtComponent } from './modules/hidden-debt/hidden-debt.component';
import { ReclameAquiComponent } from './modules/reclame-aqui/reclame-aqui.component';
import { PrivacyPolicyComponent } from './modules/juridical/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './modules/juridical/terms-of-use/terms-of-use.component';

const routes: Routes = [
  { path: '', redirectTo: 'search-debtor-by-cpf', pathMatch: 'full' },
  { path: 'dummy', component: DummyComponent },
  { path: 'login', component: LoginComponent },
  { path: 'search-debtor-by-cpf', component: SearchDebtorByCpfComponent, canActivate: [AuthGuard] },
  { path: 'search-debtor-by-email', component: SearchDebtorByEmailComponent, canActivate: [AuthGuard] },
  { path: 'search-hash-by-cpf', component: SearchHashByCpfComponent, canActivate: [AuthGuard] },
  { path: 'customer', component: CustomerComponent, canActivate: [AuthGuard] },
  { path: 'search-cip', component: ConsultaCipComponent, canActivate: [AuthGuard] },
  { path: 'billets', component: ConsultaBilletsComponent, canActivate: [AuthGuard] },
  { path: 'userRole', component: EditRoleHeaderComponent, canActivate: [AuthGuard] },
  { path: 'blocklist', component: ConsultaBlocklistComponent, canActivate: [AuthGuard] },
  { path: 'activations', component: ConsultaActivationsComponent, canActivate: [AuthGuard] },
  { path: 'integrations', component: SearchIntegrationsComponent, canActivate: [AuthGuard] },
  { path: 'installment', component: SearchByPartnerComponent, canActivate: [AuthGuard] },
  { path: 'systems', component: SearchSystemsComponent, canActivate: [AuthGuard] },
  { path: 'search-cpf-by-hash', component: SearchCpfByHashComponent, canActivate: [AuthGuard] },
  { path: 'report', component: ReportComponent, canActivate: [AuthGuard] },
  { path: 'send-data', component: SendDataComponent, canActivate: [AuthGuard] },
  { path: 'debt-blocking', component: DebtBlockingComponent, canActivate: [AuthGuard] },
  { path: 'marketplace-admin', component: MarketplaceAdminComponent, canActivate: [AuthGuard] },
  { path: 'hidden-debt', component: HiddenDebtComponent, canActivate: [AuthGuard] },
  { path: 'reclame-aqui', component: ReclameAquiComponent, canActivate: [AuthGuard] },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, canActivate: [AuthGuard] },
  { path: 'terms-of-use', component: TermsOfUseComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
