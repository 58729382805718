<app-search-select *ngIf="!insert && !loading" (searchSubmit)="searchSubmit($event)" [searchTitle]="titleSearch"
                   [large]="true"
                   [data]="creditors" [getDescriptionFn]="getDescription"></app-search-select>

<div class="container top-buffer" *ngIf="!insert && !loading && creditor == null">
  <div class="operation-container">
    <app-button-edit label="Incluir" [primary]="false" (onclick)="insertClick()"></app-button-edit>
  </div>
</div>

<app-pending-search *ngIf="!insert && !loading && creditor == null"></app-pending-search>
<app-loading *ngIf="loading" [backgroundBlue]="true"></app-loading>
<app-edit-integrations *ngIf="!insert && !loading && creditor != null" [creditor]="creditor"
                       (onedit)="editPartner($event)" (ondelete)="deletePartner($event)"></app-edit-integrations>
<app-edit-partner *ngIf="insert && !loading" [creditor]="creditor" (oncancel)="editPartnerCanceled()"
                  (onsave)="editPartnerSaved($event)"></app-edit-partner>
