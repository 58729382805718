import {Component, OnInit} from '@angular/core';
import {CreditorService} from '../../../core/services/creditor.service';
import {SuspdCredor, SuspdSistema} from '../../../models/domain';
import {SystemService} from '../../../core/services/system.service';

@Component({
  selector: 'app-search-systems',
  templateUrl: './search-systems.component.html',
  styleUrls: ['./search-systems.component.scss']
})

export class SearchSystemsComponent implements OnInit {
  titleSearch = 'Consulte o Sistema';

  insert = false;
  system: SuspdSistema | null = null;
  searchTerm: string | undefined;

  constructor(private systemService: SystemService) {
  }

  searchSubmit(val: string): void {
    this.searchTerm = val;
  }

  ngOnInit(): void {
    this.systemService.loadAuditLogs();
  }

  insertClick(): void {
    this.insert = true;
  }

  editSystemCanceled(): void {
    this.system = null;
    this.insert = false;
  }

  editSystemSaved(): void {
    this.system = null;
    this.insert = false;
  }

  editSystem(system: SuspdSistema): void {
    this.system = system;
    this.insert = true;
  }

  deleteSystem(): void {
    this.system = null;
    this.insert = false;
  }
}
