import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-button-edit',
  templateUrl: './btn-edit.component.html'
})
export class BtnEditComponent {
  @Input() label = '';
  @Input() span = '';
  @Input() disabled = false;
  @Input() primary = true;
  @Input() leftBuffer = false;
  @Output() onclick = new EventEmitter<any>();
  @Input() inverted = false;
  @Input() secundary = false;
  @Input() secundaryInvert = false;
  @Input() secundaryDisabled = false;
  @Input() secundaryLoading = false;

  onClickButton(event: any): void {
    this.onclick.emit(event);
  }

}
