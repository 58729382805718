import {LOCALE_ID, NgModule} from '@angular/core';
import {CustomerComponent} from './customer/customer.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaskPipe, NgxMaskModule} from 'ngx-mask';
import {ToastrModule} from 'ngx-toastr';
import {AuthInterceptor, ErrorInterceptor} from '../../core/security/auth-interceptor';
import {CommonModule, DatePipe} from '@angular/common';
import {CustomerService} from '../../core/services/customer.service';
import {AcCustomerModule} from '../../ac-customer.module';
import {NgbDateBRParserFormatter} from '../../ngb-date-br-parser-formatter';
import {NgxPaginationModule} from 'ngx-pagination';


@NgModule({
  declarations: [
    CustomerComponent
  ],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        AcCustomerModule,
        NgxMaskModule.forRoot(),
        ToastrModule.forRoot(),
        NgxPaginationModule
    ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: NgbDateParserFormatter, useClass: NgbDateBRParserFormatter},
    {provide: LOCALE_ID, useValue: 'pt'},
    DatePipe,
    MaskPipe,
    CustomerService
  ],
})
export class CustomersModule {
}
