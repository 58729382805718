import {Component, OnInit} from '@angular/core';
import {DebtorService} from '../../../core/services/debtor.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-search-hash-by-cpf',
  templateUrl: './search-hash-by-cpf.component.html',
  styleUrls: ['./search-hash-by-cpf.component.scss']
})
export class SearchHashByCpfComponent implements OnInit {

  searchTitle = 'Gere um Hash Code com CPF';
  showMenu = false;
  loading = false;
  private cpf = 0;
  private selectedDate: any;
  hashCode: String = "";

  constructor(private debtorService: DebtorService,
              private activatedRoute: ActivatedRoute) {
  }

  public setCpf(value: number) {
    this.cpf = value;
    this.loading = true;
    this.debtorService.searchHashUserCpf(value).subscribe(x => {
      this.loading = false;
      this.hashCode = x;
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      if (params.get('cpf') != null) {
        this.setCpf(Number(params.get('cpf')));
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    });
  }
}
