import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CreditorService} from '../../../core/services/creditor.service';
import {SuspdCredor} from '../../../models/domain';
import {AuditLogService} from '../../../core/services/audit-log.service';
import {FormBuilder, Validators} from '@angular/forms';
import {HelperService} from '../../../core/services/helper.service';

@Component({
  selector: 'app-edit-partner',
  templateUrl: './edit-partner.component.html',
  styleUrls: ['./edit-partner.component.scss']
})

export class EditPartnerComponent {

  creditorForm = this.formBuilder.group({
    nome: ['', [Validators.required]],
    identificador: ['', [Validators.required]],
    representacao: ['', [Validators.required]],
    status: ['', [Validators.required]],
    show: ['', [Validators.required]],
    order: ['', [Validators.required]],
    segmento: [''],
    msUrl: ['', [Validators.required]],
    logoUrl: [''],
    beanName: ['']
  });

  creditorValue: SuspdCredor | null = null;
  loading = false;
  @Output() onsave = new EventEmitter<SuspdCredor>();
  @Output() oncancel = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private creditorService: CreditorService, private helperService: HelperService) {
  }

  @Input()
  set creditor(creditor: SuspdCredor | null) {
    this.creditorValue = creditor;
    if (this.creditorValue != null) {
      this.creditorForm.setValue({
        nome: this.creditorValue.nome,
        identificador: this.creditorValue.identificador,
        representacao: this.creditorValue.representacao,
        status: this.creditorValue.status,
        show: this.creditorValue.show,
        order: this.creditorValue.order,
        segmento: this.creditorValue.segmento,
        msUrl: this.creditorValue.msUrl,
        logoUrl: this.creditorValue.logoUrl,
        beanName: this.creditorValue.beanName
      });
    } else {
      this.creditorForm.reset();
    }
  }

  onSubmit(): void {
    if (this.creditorForm.valid) {
      this.loading = true;
      if (this.creditorValue != null && this.creditorValue.id != null) {
        this.creditorService.update(this.creditorValue.id, this.creditorForm.value).subscribe(creditor => {
          this.loading = false;
          this.onsave.emit(creditor);
        });
      } else {
        this.creditorService.create(this.creditorForm.value).subscribe(creditor => {
          this.loading = false;
          this.onsave.emit(creditor);
        });
      }
    } else {
      this.helperService.showMessage('Formulário contém erros no preenchimento. Favor verificar.');
    }
  }

  onCancel(): void {
    this.oncancel.emit();
  }
}
