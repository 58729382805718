import { LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';

import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

import { AuthInterceptor, ErrorInterceptor } from './core/security/auth-interceptor';

import './models/domain';
import { DummyComponent, MenuComponent } from './core/menu/menu.component';
import { LoginComponent } from './core/login/login.component';

import { DealService } from './core/services/deal.service';
import { DebtorService } from './core/services/debtor.service';
import { DebtService } from './core/services/debt.service';
import { CustomerService } from './core/services/customer.service';
import { NgbDateBRParserFormatter } from './ngb-date-br-parser-formatter';


import { CpfComponent } from './modules/shared/cpf/cpf.component';
import { EmailComponent } from './modules/shared/email/email.component';
import { DddComponent } from './modules/shared/ddd/ddd.component';
import { PhoneComponent } from './modules/shared/phone/phone.component';
import { DateComponent } from './modules/shared/date/date.component';
import { SearchByCpfComponent } from './modules/shared/search-by-cpf/search-by-cpf.component';
import { SearchByEmailComponent } from './modules/shared/search-by-email/search-by-email.component';
import { BtnEditComponent } from './modules/shared/btn-edit/btn-edit.component';
import { ApiServices } from './core/services/api.services';
import { PendingSearchComponent } from './modules/shared/pending-search/pending-search.component';
import { LoadingComponent } from './modules/shared/loading/loading.component';
import { CalendarComponent } from './modules/shared/calendar/calendar.component';
import { UserRoleService } from './core/services/user-role.service';
import { SearchComponent } from './modules/shared/search/search.component';
import { CipService } from './core/services/cip.service';
import { RoleService } from './core/services/role.service';
import { NgxPaginationModule, PaginatePipe } from 'ngx-pagination';
import { BlocklistService } from './core/services/blocklist.service';
import { ActivationsService } from './core/services/activations.service';
import { BilletsService } from './core/services/billets.service';
import { ConfirmComponent } from './modules/shared/confirm/confirm.component';
import { SearchSelectComponent } from './modules/shared/search-select/search-select.component';
import { CreditorService } from './core/services/creditor.service';
import { AuditLogComponent } from './modules/shared/audit-log/audit-log.component';
import { PartnerService } from './core/services/partner.service';
import { DecimalComponent } from './modules/shared/decimal/decimal.component';
import { CheckboxComponent } from './modules/shared/checkbox/checkbox.component';
import { SystemService } from './core/services/system.service';
import { VarcharComponent } from './modules/shared/varchar/varchar.component';
import { SearchByHashComponent } from './modules/shared/search-by-hash/search-by-hash.component';
import { SelectReportComponent } from './modules/shared/select-report/select-report.component';
import { SendDataService } from './core/services/send-data.service';
import { DebtBlockingService } from './core/services/debt-blocking.service';
import { MartketplaceAdminService } from './core/services/marketplace-admin.service';
import { HiddenDebtService } from './core/services/hidden-debt.service';
import { ReclameAquiService } from './core/services/reclame-aqui.service';
import { SearchByPhoneNumberComponent } from './modules/shared/search-by-phone-number/search-by-phone.component';

@NgModule({
  declarations: [
    LoginComponent,
    MenuComponent,
    DummyComponent,
    AuditLogComponent,
    PendingSearchComponent,
    ConfirmComponent,
    LoadingComponent,
    SearchByCpfComponent,
    SearchByEmailComponent,
    BtnEditComponent,
    DateComponent,
    PhoneComponent,
    DddComponent,
    EmailComponent,
    DecimalComponent,
    VarcharComponent,
    CpfComponent,
    CalendarComponent,
    SearchComponent,
    SearchSelectComponent,
    CheckboxComponent,
    SearchByHashComponent,
    SelectReportComponent,
    SearchByPhoneNumberComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: NgbDateParserFormatter, useClass: NgbDateBRParserFormatter },
    { provide: LOCALE_ID, useValue: 'pt' },
    DatePipe,
    MaskPipe,
    PaginatePipe,
    DealService,
    ApiServices,
    DebtorService,
    CipService,
    UserRoleService,
    RoleService,
    DebtService,
    CustomerService,
    BlocklistService,
    ActivationsService,
    BilletsService,
    PartnerService,
    CreditorService,
    SystemService,
    SendDataService,
    DebtBlockingService,
    MartketplaceAdminService,
    HiddenDebtService,
    ReclameAquiService
  ],
  exports: [
    LoginComponent,
    MenuComponent,
    DummyComponent,
    AuditLogComponent,
    PendingSearchComponent,
    ConfirmComponent,
    LoadingComponent,
    SearchByCpfComponent,
    SearchByEmailComponent,
    BtnEditComponent,
    DateComponent,
    PhoneComponent,
    DddComponent,
    EmailComponent,
    DecimalComponent,
    VarcharComponent,
    CpfComponent,
    SearchComponent,
    CalendarComponent,
    SearchSelectComponent,
    CheckboxComponent,
    SearchByHashComponent,
    SelectReportComponent,
    SearchByPhoneNumberComponent
  ]
})
export class AcCustomerModule {
}
