import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {UserInfo} from '../../models/domain';
import {ApiServices} from './api.services';
import {UserAuthority} from '../../models/user.authority';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private userLogsIn = new Subject<string>();

  // uri -> main function
  private activationGuardMap: Map<string, string> = new Map<string, string>();

  constructor(private apiServices: ApiServices, private router: Router) {
    this.activationGuardMap.set('/search-debtor-by-cpf', '/debtor');
    this.activationGuardMap.set('/search-debtor-by-email', '/search-debtor-by-email');
    this.activationGuardMap.set('/customer', '/customer');
    this.activationGuardMap.set('/billets', '/billetAlerts');
    this.activationGuardMap.set('/search-cip', '/cip');
    this.activationGuardMap.set('/blocklist', '/blocklist');
    this.activationGuardMap.set('/activations', '/activations');
    this.activationGuardMap.set('/userRole', '/user');
    this.activationGuardMap.set('/installment', '/partner');
    this.activationGuardMap.set('/integrations', '/creditor/patch');
    this.activationGuardMap.set('/systems', '/system');
    this.activationGuardMap.set('/debt-blocking', '/debt-blocking');
    this.activationGuardMap.set('/hidden-debt', '/hidden-debt');
    this.activationGuardMap.set('/reclame-aqui', '/reclame-aqui');
    this.activationGuardMap.set('/report', '/report');
  }

  get userLoginObservable(): Observable<string> {
    return this.userLogsIn.asObservable();
  }

  hasMenuAuthorityFor(uri: string): boolean {
    const userAuthorities = this.getAuthorities();

    if (uri === '/privacy-policy' || uri === '/terms-of-use'){ //temporario
      return userAuthorities.find((el: any) => {
        return el.authority === '/site-policy'
      }) ? true : false;
    }

    if (!this.activationGuardMap.has(uri)) {
      return true;
    } else {
      const funcName = this.activationGuardMap.get(uri);
      return userAuthorities != null && userAuthorities.filter(x => x.authority === funcName).length > 0;
    }
  }

  hasAuthority(authority: string): boolean {
    const userAuthorities = this.getAuthorities();
    return userAuthorities != null && userAuthorities.filter(x => x.authority === authority).length > 0;
  }

  login(user: UserInfo): void {
    if (user != null && user.username != null && user.token != null) {
      sessionStorage.setItem('userName', user.username);
      sessionStorage.setItem('currentUser', btoa(user.username + ':' + user.token));
      this.apiServices.loadUserAuthorities().subscribe(authorityInfo => {
        if (authorityInfo != null && authorityInfo.role != null) {
          sessionStorage.setItem('authorities', JSON.stringify(authorityInfo.grantedAuthorities));
          sessionStorage.setItem('role', authorityInfo.role);
          this.userLogsIn.next(user.username);
        } else {
          this.clearSession();
        }
      }, err => {
        this.clearSession();
      });
    } else {
      this.clearSession();
    }
  }

  getUserName(): string | null {
    return sessionStorage.getItem('userName');
  }

  clearSession(): void {
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('authorities');
  }

  logout(): void {
    this.userLogsIn.next('');
    this.clearSession();
    this.router.navigate(['/login']);
  }

  getAuthData(): string | null {
    return sessionStorage.getItem('currentUser');
  }

  getAuthorities(): UserAuthority[] {
    const authJson = sessionStorage.getItem('authorities');
    if (authJson != null) {
      return JSON.parse(authJson);
    }
    return [];
  }

  getRole(): string | null {
    return sessionStorage.getItem('role');
  }
}
