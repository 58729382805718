import {LOCALE_ID, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaskPipe, NgxMaskModule} from 'ngx-mask';
import {ToastrModule} from 'ngx-toastr';
import {AuthInterceptor, ErrorInterceptor} from '../../core/security/auth-interceptor';
import {CommonModule, DatePipe} from '@angular/common';
import {AcCustomerModule} from '../../ac-customer.module';
import {NgbDateBRParserFormatter} from '../../ngb-date-br-parser-formatter';
import {NgxPaginationModule, PaginatePipe} from 'ngx-pagination';
import {SearchDebtorModule} from '../search-debtor/search-debtor.module';
import {SearchSystemsComponent} from './search-systems/search-systems.component';
import {EditSystemComponent} from './edit-system/edit-system.component';
import {EditSystemConfigurationComponent} from './modals/edit-system-configuration/edit-system-configuration.component';
import {ListSystemsComponent} from './list-systems/list-systems.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [
    SearchSystemsComponent,
    EditSystemComponent,
    EditSystemConfigurationComponent,
    ListSystemsComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AcCustomerModule,
    NgxPaginationModule,
    SearchDebtorModule,
    NgxMaskModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot(),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: NgbDateParserFormatter, useClass: NgbDateBRParserFormatter},
    {provide: LOCALE_ID, useValue: 'pt'},
    DatePipe,
    MaskPipe,
    PaginatePipe
  ],
})
export class SystemsModule {
}

