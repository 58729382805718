import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss']
})
export class SearchSelectComponent {
  @Output() searchSubmit: EventEmitter<any> = new EventEmitter();
  searchForm = this.formBuilder.group({
    value: ''
  });
  @Input() searchTitle = '';
  @Input() label = 'CONSULTAR';
  @Input() large = false;
  @Input() medium = false;
  @Input() data: any[] = [];
  @Input() getDescriptionFn: any;
  selected: any;

  constructor(private formBuilder: FormBuilder) {
  }

  onSubmit(): void {
    const form = this.searchForm.value;
    if (form.value !== '') {
      this.searchSubmit.emit(form.value);
    }
  }

}
