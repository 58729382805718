<app-loading *ngIf="loading" [backgroundBlue]="true"></app-loading>
<form [formGroup]="creditorForm">
  <div class="triangulo-para-cima"></div>
  <div class="container">
    <div class="partner-container">
      <div class="row">
        <div class="data-title">Nome:</div>
        <div class="data-value text-capitalize"><input class="form-control form-input" type="text"
                                                       formControlName="nome" maxlength="255"></div>
        <div
          *ngIf="creditorForm.controls.nome.invalid && (creditorForm.controls.nome.dirty || creditorForm.controls.nome.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="creditorForm.controls.nome.value !== ''">O valor do campo está inválido</li>
            <li *ngIf="creditorForm.controls.nome.value === ''">O valor do campo é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="data-title">Identificador:</div>
        <div class="data-value text-capitalize"><input class="form-control form-input" type="text"
                                                       formControlName="identificador" maxlength="255"></div>
        <div
          *ngIf="creditorForm.controls.identificador.invalid && (creditorForm.controls.identificador.dirty || creditorForm.controls.identificador.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="creditorForm.controls.identificador.value !== ''">O valor do campo está inválido</li>
            <li *ngIf="creditorForm.controls.identificador.value === ''">O valor do campo é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="data-title">Representação:</div>
        <div class="data-value text-capitalize"><input class="form-control form-input" type="text"
                                                       formControlName="representacao"></div>
        <div
          *ngIf="creditorForm.controls.representacao.invalid && (creditorForm.controls.representacao.dirty || creditorForm.controls.representacao.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="creditorForm.controls.representacao.value !== ''">O valor do campo está inválido</li>
            <li *ngIf="creditorForm.controls.representacao.value === ''">O valor do campo é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="data-title">Status:</div>
        <div class="data-value">
          <select class="form-control form-input" name="status" formControlName="status">
            <option value="0">Inativo</option>
            <option value="1">Ativo</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="data-title">Show:</div>
        <div class="data-value">
          <select class="form-control form-input" name="show" formControlName="show">
            <option value="0">Não</option>
            <option value="1">Sim</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="data-title">Ordem:</div>
        <div class="data-value text-capitalize"><input class="form-control form-input" type="number"
                                                       formControlName="order"></div>
        <div
          *ngIf="creditorForm.controls.order.invalid && (creditorForm.controls.order.dirty || creditorForm.controls.order.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="creditorForm.controls.order.value !== ''">O valor do campo está inválido</li>
            <li *ngIf="creditorForm.controls.order.value === ''">O valor do campo é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="data-title">Segmento:</div>
        <div class="data-value text-capitalize"><input class="form-control form-input" type="text"
                                                       formControlName="segmento" maxlength="255"></div>
        <div
          *ngIf="creditorForm.controls.segmento.invalid && (creditorForm.controls.segmento.dirty || creditorForm.controls.segmento.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="creditorForm.controls.segmento.value !== ''">O valor do campo está inválido</li>
            <li *ngIf="creditorForm.controls.segmento.value === ''">O valor do campo é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="data-title">MS URL:</div>
        <div class="data-value text-capitalize"><input class="form-control form-input" type="text"
                                                       formControlName="msUrl" maxlength="255"></div>
        <div
          *ngIf="creditorForm.controls.msUrl.invalid && (creditorForm.controls.msUrl.dirty || creditorForm.controls.msUrl.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="creditorForm.controls.msUrl.value !== ''">O valor do campo está inválido</li>
            <li *ngIf="creditorForm.controls.msUrl.value === ''">O valor do campo é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="data-title">Logo URL:</div>
        <div class="data-value text-capitalize"><input class="form-control form-input" type="text"
                                                       formControlName="logoUrl" maxlength="255"></div>
        <div
          *ngIf="creditorForm.controls.logoUrl.invalid && (creditorForm.controls.logoUrl.dirty || creditorForm.controls.logoUrl.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="creditorForm.controls.logoUrl.value !== ''">O valor do campo está inválido</li>
            <li *ngIf="creditorForm.controls.logoUrl.value === ''">O valor do campo é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="data-title">Bean Name:</div>
        <div class="data-value text-capitalize"><input class="form-control form-input" type="text"
                                                       formControlName="beanName" maxlength="255"></div>
        <div
          *ngIf="creditorForm.controls.beanName.invalid && (creditorForm.controls.beanName.dirty || creditorForm.controls.beanName.touched)"
          class="itemValidator">
          <ul>
            <li *ngIf="creditorForm.controls.beanName.value !== ''">O valor do campo está inválido</li>
            <li *ngIf="creditorForm.controls.beanName.value === ''">O valor do campo é obrigatório</li>
          </ul>
        </div>
      </div>
      <div class="row top-buffer btn-edit-container">
        <app-button-edit (onclick)="onSubmit()" label="Salvar" [disabled]="loading"></app-button-edit>
        <app-button-edit [leftBuffer]="true" [primary]="false" (onclick)="onCancel()"
                         label="Cancelar"></app-button-edit>
      </div>
    </div>
  </div>
</form>

