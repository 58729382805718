<form [formGroup]="userRoleForm">
  <div class="container">
    <div class="debtor-container">
      <div class="row">
        <div class="data-title">Perfil:</div>
        <div class="data-value" *ngIf="!editable && userRoleValue != undefined">{{ userRoleValue.name }}</div>
        <div class="data-value" *ngIf="!editable && userRoleValue === undefined">Novo perfil</div>
        <div class="data-value" *ngIf="editable">
          <select class="form-control form-input" name="roleSelect" formControlName="roleSelect">
            <option *ngFor="let role of roles" [value]="role.roleId" >{{role.name}}</option>
          </select>
        </div>
      </div>
      <div class="row top-buffer btn-edit-container">
        <app-button-edit *ngIf="!editable" (onclick)="edit()" [label]="labelEdit"></app-button-edit>
        <app-button-edit [disabled]="!userRoleForm.valid" (onclick)="onSubmit()" [label]="labelSave" *ngIf="editable"></app-button-edit>
        <app-button-edit [leftBuffer]="true" [primary]="false" *ngIf="editable" (onclick)="onCancel()" [label]="labelCancel"></app-button-edit>
      </div>
    </div>
  </div>
</form>
