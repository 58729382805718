export default class CpfUtils {

    static format(cpf: string): string {
      const aux = cpf.replace(/(\.|\/|\-)/g, '');
      return aux.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
    }

    static unformat(cpf: string): string {
      return cpf.replace(/\D/g,'');
    }

    static convertToString(cpf: number): string {
      let parsedCpf = cpf.toString();
      return parsedCpf.padStart(11, '0');
    }
  
    static isValid(cpf: string): boolean {
      if (!cpf || cpf === null || cpf === '') {
        return false;
      }
  
      cpf = cpf.replace(/[^\d]+/g, '');
      if (cpf === '' || cpf.length < 11) {
        return false;
      }
  
      let soma: number;
      let resto: number;
      let i: number;
      soma = 0;
  
      if (cpf === '00000000000'
        || cpf === '11111111111'
        || cpf === '22222222222'
        || cpf === '33333333333'
        || cpf === '44444444444'
        || cpf === '55555555555'
        || cpf === '66666666666'
        || cpf === '77777777777'
        || cpf === '88888888888'
        || cpf === '99999999999') {
        return false;
      }
  
      for (i = 1; i <= 9; i++) {
        soma = soma + Number(cpf.substring(i - 1, i)) * (11 - i);
      }
      resto = (soma * 10) % 11;
  
      if ((resto === 10) || (resto === 11)) {
        resto = 0;
      }
  
      if (resto !== Number(cpf.substring(9, 10))) {
        return false;
      }
  
      soma = 0;
      for (i = 1; i <= 10; i++) {
        soma = soma + Number(cpf.substring(i - 1, i)) * (12 - i);
      }
      resto = (soma * 10) % 11;
  
      if ((resto === 10) || (resto === 11)) {
        resto = 0;
      }
  
      return resto === Number(cpf.substring(10, 11));
    }
  }
  