import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function isValidDate(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlDate = getAsDate(control.value);
    if (controlDate === null) {
      return {invalidDate: {value: control.value}};
    }
    return null;
  };
}

export function getAsDate(val: string): Date | undefined | null {
  if (val != null) {
    val = val.replace(/[^\d]/g, '');
    const matchDatePattern = val.match(/^\d{8}$/);
    if (matchDatePattern) {
      const match = val.match(/^(([012]\d)|([3][01]))((0\d)|(1[012]))(\d{4})$/);
      if (match) {
        const date = new Date(Number(match[7]), Number(match[4]), 0);
        const day = Number(match[1]);
        if (day <= date.getDate()) {
          return new Date(Number(match[7]), Number(match[4]) - 1, day);
        }
      }
    }
  }
  return null;
}

export function isDateBefore(date: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlDate = getAsDate(control.value);
    if (controlDate === undefined) {
      return null;
    } else if (controlDate === null || controlDate.getTime() > date.getTime()) {
      return {invalidDate: {value: control.value}};
    }
    return null;
  };
}
