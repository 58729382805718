import {Component, Input} from '@angular/core';
import {BlockListInfo, CipInfo} from '../../../models/domain';
import {sortBy} from 'sort-by-typescript';

@Component({
  selector: 'app-detalhe-blocklist',
  templateUrl: './detalhe-consulta-blocklist.component.html',
  styleUrls: ['./detalhe-consulta-blocklist.component.scss']
})

export class DetalheConsultaBlocklistComponent {

  @Input() blocklist: BlockListInfo[] | undefined;

  public sort: any;
  public blocklistSort: any;
  public desc = false;
  public setaSort: any = 'seta-baixo';


  sortTable(sort: string): void {
    if(this.sort === sort && this.desc === false){
      this.desc = true;
      this.setaSort = 'seta-cima';
    }else{
      this.desc = false;
      this.setaSort = 'seta-baixo';
    }
    this.sort = sort;
    if(this.desc){
      this.blocklistSort.sort(sortBy('-'.concat(sort)));
    }else{
      this.blocklistSort.sort(sortBy(sort));
    }
  }

}
