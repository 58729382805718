import {Component, Input} from '@angular/core';
import {SuspdAcordo} from '../../../models/domain';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DebtDetailComponent} from '../modals/debt-detail/debt-detail.component';
import {DebtPaymentOptionsComponent} from '../modals/debt-payment-option/debt-payment-options.component';
import {sortBy} from 'sort-by-typescript';
import {DealDetailComponent} from '../modals/deal-detail/deal-detail.component';
import { DividaResponse } from 'src/app/models/response/devedorDetalheResponse.model';

@Component({
  selector: 'app-debt-list',
  templateUrl: './debt-list.component.html',
  styleUrls: ['./debt-list.component.scss']
})
export class DebtListComponent {

  debtList?: DividaResponse[];
  @Input() dealList?: SuspdAcordo[];
  public debtListSort: any;
  public sort: any;
  public desc = false;
  public setaSort: any = 'seta-baixo';

  size = 10;
  page = 1;

  labelPrevious = 'Ant.';
  labelNext = 'Próx.';

  constructor(private modalService: NgbModal) {
  }

  @Input()
  set debtListValue(debtList: DividaResponse[] | undefined) {
    if (debtList !== undefined) {
      this.debtList = debtList;
      this.debtListSort = debtList;
    }
  }

  showModalDetail(debt: DividaResponse): void {
    const modalRef = this.modalService.open(DebtDetailComponent, {windowClass: 'large-modal', backdrop: 'static'});
    modalRef.shown.subscribe(x => {
      modalRef.componentInstance.setDebtValue(debt);
    });
    modalRef.closed.subscribe(x => {
      console.log(x);
    });
  }

  showModalPayment(debt: DividaResponse): void {
    const modalRef = this.modalService.open(DebtPaymentOptionsComponent, {windowClass: 'large-modal', backdrop: 'static'});
    modalRef.shown.subscribe(x => {
      modalRef.componentInstance.setDebt(debt);
    });
    modalRef.closed.subscribe(x => {
      console.log(x);
    });
  }


  sortTable(sort: string): void {
    if(this.sort === sort && this.desc === false){
      this.desc = true;
      this.setaSort = 'seta-cima';
    }else{
      this.desc = false;
      this.setaSort = 'seta-baixo';
    }
    this.sort = sort;
    if(this.desc){
      this.debtListSort.sort(sortBy('-'.concat(sort)));
    }else{
      this.debtListSort.sort(sortBy(sort));
    }
  }

  tabSize($event: number): void {
    this.page = $event;
  }

  showModalDeal(dealId: number | undefined): void {
    if (dealId != null && this.dealList != null) {
      const deal = this.dealList.filter(x => x.id === dealId);
      if (deal != null && deal.length > 0) {
        const modalRef = this.modalService.open(DealDetailComponent, {windowClass: 'large-modal', backdrop: 'static'});
        modalRef.shown.subscribe(x => {
          modalRef.componentInstance.setDealValue(deal[0]);
        });
        modalRef.closed.subscribe(x => {
          console.log(x);
        });
      }
    }
  }
}
