import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadService {
  load = false;
  constructor() { }


  addLoad() {
    this.load = true;
  }

  removeLoad(){
    this.load = false
  }

  getLoad(){
    return this.load;
  }
}
