import { Component } from '@angular/core';

@Component({
  selector: 'app-debt-blocking',
  templateUrl: './debt-blocking.component.html',
  styleUrls: ['./debt-blocking.component.scss']
})

export class DebtBlockingComponent {

  selection: boolean = true;
  debtBlocking: boolean = false;
  searchDebtBlocking: boolean = false;

  onClickDebtBlocking(): void {
    this.debtBlocking = true;
    this.selection = false;
  }

  onClickSearchDebtBlocking(): void {
    this.searchDebtBlocking = true;
    this.selection = false;
  }
}
