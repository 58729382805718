import {Observable} from 'rxjs';
import {DatePipe} from '@angular/common';
import {SuspdAcordo, SuspdAcordoParcela} from '../../models/domain';
import {Injectable} from '@angular/core';
import {ApiServices} from './api.services';
import { ParcelaResponse } from '../../models/response/parcela-response.model';

@Injectable()
export class DealService {

  constructor(
    private datepipe: DatePipe,
    private apiServices: ApiServices) {
  }

  deleteDeal(dealId: number): Observable<SuspdAcordo> {
    return this.apiServices.deleteDeal(dealId);
  }

  reactivateDeal(dealId: number): Observable<SuspdAcordo> {
    return this.apiServices.reactivateDeal(dealId);
  }

  getInstallments(dealId: number): Observable<ParcelaResponse[]> {
    return this.apiServices.getInstallments(dealId);
  }

  changeInstallmentDate(dealId: number, installmentId: number, paymentDate: Date | null): Observable<SuspdAcordoParcela> {
    if (paymentDate == null) {
      return this.apiServices.removeInstallmentDate(dealId, installmentId);
    } else {
      const date = this.datepipe.transform(paymentDate, 'yyyy-MM-dd');
      return this.apiServices.changeInstallmentDate(dealId, installmentId, date);
    }
  }

  invalidateBilletCache(installmentId: number): Observable<boolean>{
    return this.apiServices.invalidateBilletCache(installmentId);
  }

  getBilletURL(installmentId: number): Observable<string> {
    return this.apiServices.getBilletURL(installmentId);
  }

  sendEmailBillet(installmentId: number): Observable<boolean> {
    return this.apiServices.sendEmailBillet(installmentId);
  }
}
