<div class="container top-buffer" *ngIf="auditLog != undefined && auditLog.length > 0">
  <table class="table-dark-blue">
    <thead>
    <tr>
      <th class="container-title" colspan="10">
        <span class="table-title">Últimas alterações</span>
      </th>
    </tr>
    <tr>
      <th class="cursor" (click)="sortTable('userName', '')">Alterador por<span class="{{setaSort}}"></span></th>
      <th class="cursor" (click)="sortTable('operation', 'entityName')">Alteração<span class="{{setaSort}}"></span></th>
      <th class="cursor" id="acordo-alterado" *ngIf="isCustomer">ID do acordo alterado<span></span></th>
      <th class="cursor" id="id-parcela" *ngIf="isCustomer">ID da parcela<span></span></th>
      <th class="cursor" (click)="sortTable('dateTime', '')">Data e hora da alteração<span class="{{setaSort}}"></span></th>
    </tr>
    <tr></tr>
    </thead>
    <tbody *ngIf="auditLog != undefined">
    <ng-container *ngFor="let log of auditLog | paginate : {
                id:'audit-log',
                itemsPerPage: size,
                currentPage: page,
                totalItems: auditLog.length
              };">
      <tr *ngIf="auditLogService.getAuditLogDescription(log) != null && auditLogService.getAuditLogDescription(log) !== ''">
        <td>{{ log.userName }}</td>
        <td>{{ auditLogService.getAuditLogDescription(log) }}</td>
        <td *ngIf="isCustomer">{{ getDirtyAgreementId(log) }}</td>
        <td *ngIf="isCustomer">{{ getInstallment(log) }}</td>
        <td>{{ log.dateTime | date:'dd/MM/yyyy HH:mm:ss' }}</td>
      </tr>
    </ng-container>
    <tr *ngIf="auditLog == null || auditLog.length == 0">
      <td colspan="3">Nenhum registro encontrado</td>
    </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-center top-buffer">
    <pagination-controls id="audit-log" [responsive]="false" [previousLabel]="labelPrevious" [nextLabel]="labelNext"
                         (pageChange)="tabSize($event)">
    </pagination-controls>
  </div>
</div>