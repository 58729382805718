import {Observable} from 'rxjs';
import { Devedor, CpfInfoResponse } from '../../models/domain';
import {Injectable} from '@angular/core';
import {ApiServices} from './api.services';
import {AuditLogService} from './audit-log.service';
import { DevedorDetalheResponse, DevedorResponse } from '../../models/response/devedorDetalheResponse.model';

@Injectable()
export class DebtorService {

  constructor(private apiService: ApiServices, private auditLogService: AuditLogService) {
  }

  searchByCpf(cpf: number | string): Observable<DevedorDetalheResponse> {
    return this.apiService.searchByCpf(cpf);
  }

  searchByEmail(email: String): Observable<DevedorDetalheResponse> {
    return this.apiService.searchByEmail(email);
  }

  update(id: number, debtor: Devedor): Observable<DevedorResponse> {
    return this.apiService.updateDebtor(id, debtor);
  }

  searchHashUserCpf(cpf: number): Observable<String> {
    return this.apiService.searchHashUserCpf(cpf);
  }

  loadAuditLogs(id: number): void {
    this.apiService.loadDebtorAuditLogs(id).subscribe(logs => {
      this.auditLogService.emitLog(logs);
    });
  }

  cpfToHash(content: string): Observable<CpfInfoResponse[]> {
    return this.apiService.cpfToHash(content);
  }
}
