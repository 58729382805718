import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { sortBy } from 'sort-by-typescript';
import { map, tap, finalize } from 'rxjs/operators';

import { SuspdAcordoParcela } from '../../../../models/domain';
import { DealService } from '../../../../core/services/deal.service';
import { HelperService } from 'src/app/core/services/helper.service';
import { AcordoResponse } from 'src/app/models/response/devedorDetalheResponse.model';
import { PaymentMethods } from 'src/app/core/services/utils/paymentMethods-enum';
import { InstallmentStatusConverter } from 'src/app/core/services/utils/installment-status-convert.utils';

@Component({
  selector: 'app-deal-detail-status',
  templateUrl: './deal-detail.component.html',
  styleUrls: ['./deal-detail.component.scss']
})
export class DealDetailComponent implements OnInit {
  billet: boolean = false;

  maxPaymentDate: any;
  deal: AcordoResponse = {} as AcordoResponse;
  installmentList?: SuspdAcordoParcela[];
  installmentId?: number;
  installmentDate?: NgbDateStruct | undefined;
  public installmentListSort: any;
  public sort: any;
  public desc = false;
  public setaSort: any = 'seta-baixo';
  loading = true;
  modalTitle: any = null;
  statusPagamento: number = 3

  constructor(public activeModal: NgbActiveModal, private dealService: DealService, private helperService: HelperService) {
  }

  @Input()
  public setDealValue(deal: AcordoResponse): void {
    this.deal = deal;
    this.desc = false;
    if (deal.id != null) {
      this.hasBillet(deal.paymentMethod);
      this.getInstallments(deal.id);
    }
  }

  getInstallments(acordoId: number) {
    this.loading = true;
    this.dealService.getInstallments(acordoId).pipe(
      map(response => response.map(installment => ({
        ...installment,
        descricaoStatus: InstallmentStatusConverter.convert(installment.status)
      }))),
      tap(installmentList => {
        this.installmentList = installmentList;
        this.installmentListSort = this.installmentList;
      }),
      finalize(() => this.loading = false)
    ).subscribe();
  }

  paymentDateClick(installment: SuspdAcordoParcela): void {
    if (installment.status !== 0) {
      this.installmentId = installment.id;
    }
  }

  changePaymentDate(installment: SuspdAcordoParcela): void {
    if (this.installmentDate != null) {
      const date = new Date(this.installmentDate.year, this.installmentDate.month - 1, this.installmentDate.day);
      this.getChangeInstallmentDate(installment, date);
    }
  }

  removePaymentDate(installment: SuspdAcordoParcela): void {
    this.getChangeInstallmentDate(installment, null);
  }

  getChangeInstallmentDate(installment: SuspdAcordoParcela, date: Date | null): void {
    this.modalTitle = "parcelas atualizadas";
    this.loading = true;
    if (installment.id != null && this.deal != null && this.deal.id != null) {
      this.dealService.changeInstallmentDate(this.deal.id, installment.id, date)
        .subscribe(x => {
          this.getInstallments(Number(this.deal?.id));
          this.installmentId = undefined;
          this.modalTitle = null;
        }, error => {
          this.helperService.showError(error.error.message);
          this.loading = false;
        });
    }
  }

  removeBilletCache(installment: SuspdAcordoParcela): void {
    this.getInvalidateBilletCache(installment);
  }

  getInvalidateBilletCache(installment: SuspdAcordoParcela): void {
    this.loading = true;
    if (installment.id != null) {
      this.dealService.invalidateBilletCache(installment.id)
        .subscribe(x => {
          this.loading = false;
          this.helperService.showMessage("Cache do boleto removido")
        }, error => {
          this.loading = false;
        }
        );
    }
  }

  ngOnInit(): void {
    const currentDate = new Date();
    this.maxPaymentDate = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate() };
  }

  sortTable(sort: string): void {
    if (this.sort === sort && this.desc === false) {
      this.desc = true;
      this.setaSort = 'seta-cima';
    } else {
      this.desc = false;
      this.setaSort = 'seta-baixo';
    }
    this.sort = sort;
    if (this.desc) {
      this.installmentListSort.sort(sortBy('-'.concat(sort)));
    } else {
      this.installmentListSort.sort(sortBy(sort));
    }
  }

  generateBillet(installment: SuspdAcordoParcela): void {
    if (installment != null && installment.id != null) {
      this.dealService.getBilletURL(installment.id).subscribe(url => {
        window.open(url, '_blank');
      });
    }
  }

  sendBilletEmail(installment: SuspdAcordoParcela): void {
    if (installment != null && installment.id != null) {
      this.loading = true;
      this.dealService.sendEmailBillet(installment.id).subscribe(_ => {
        this.loading = false;
      });
    }
  }

  showRemovePayment(installment: SuspdAcordoParcela, installmentList: SuspdAcordoParcela[]): boolean {
    let installmentsPaid = installmentList.filter(dealInstallment => dealInstallment.status == 4);
    let lastInstallmentPaid = installmentsPaid[installmentsPaid.length - 1]
    return this.installmentId != installment.id && installment.dataPagamento != null && lastInstallmentPaid.id == installment.id
  }

  hasBillet(paymentMethod: string): void {
    this.billet = this.isNotPaymentCreditCard(paymentMethod);
  }

  isNotPaymentCreditCard(paymentMethod: string): boolean {
    if (paymentMethod != PaymentMethods.CREDIT_CARD.label) {
      return true;
    }
    return false;
  }
}
