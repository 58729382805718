<div class="container top-buffer">
    <table class="table-blue">
      <caption class="description"></caption>
      <thead>
        <tr>
          <th scope="col" class="container-title" colspan="7">
            <div class="table-title">Termos de Uso</div>
          </th>
        </tr>
        <tr>
          <th scope="col" class="text-center">Id</th>
          <th scope="col" class="text-center">Versão</th>
          <th scope="col" class="text-center">Data de criação</th>
          <th scope="col" class="text-center">Publicado</th>
          <th scope="col" class="text-center">Data de Publicação</th>
          <th scope="col" colspan="2" class="text-center"></th>
        </tr>
        <tr></tr>
      </thead>
      <tbody *ngIf="terms.length > 0">
        <tr *ngFor="let term of terms| paginate : {
          id:'termsList',
          itemsPerPage: size,
          currentPage: page,
          totalItems: terms.length
        };"[ngClass]="{'published': term.published}">
          <td class="text-center">{{ term.id }}</td>
          <td class="text-center">{{ term.version }}</td>
          <td class="text-center">{{ term.createdAt | date : "dd/MM/yyyy HH:mm:ss" }}</td>
          <td class="text-center">{{ term.published ? "Sim" : "" }}</td>
          <td class="text-center">{{ term.publishedAt | date : "dd/MM/yyyy HH:mm:ss" }}</td>
          <td class="text-center">
            <a href="javascript:void(0)" (click)="viewContent(term)">Visualizar</a>
          </td>
          <td class="text-center">
            <a href="javascript:void(0)" (click)="onedit.emit(term)">Criar Derivado</a>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="terms.length === 0">
        <tr>
          <td colspan="7" class="text-center">Nenhum resultado</td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-center top-buffer">
      <pagination-controls id="termsList" [responsive]="false" [previousLabel]="'Ant.'" [nextLabel]="'Prox.'"
                           (pageChange)="tabSize($event)">
      </pagination-controls>
    </div>
  </div>
  