import {Component, OnInit} from '@angular/core';
import {SuspdCredor, SuspdPoliticaParcelamento} from '../../../models/domain';
import {CreditorService} from '../../../core/services/creditor.service';
import {PartnerService} from '../../../core/services/partner.service';
import {HelperService} from '../../../core/services/helper.service';
import {ApiServices} from '../../../core/services/api.services';
import {AuditLogService} from '../../../core/services/audit-log.service';

@Component({
  selector: 'app-search-debtor-by-cpf',
  templateUrl: './search-by-partner.component.html',
  styleUrls: ['./search-by-partner.component.scss']
})
export class SearchByPartnerComponent implements OnInit {
  titleSearch = 'Selecione o Parceiro/Integração';

  loading = false;
  creditors: SuspdCredor[] = [];
  installmentList?: SuspdPoliticaParcelamento[];
  creditor: SuspdCredor | null = null;
  batchUpdate = false;
  installmentPolicyUpdate = false;

  constructor(private creditorService: CreditorService,
              private partnerService: PartnerService,
              private helperService: HelperService,
              private auditLogService: AuditLogService) {
  }

  public getDescription(creditor: SuspdCredor): string {
    return `${creditor.nome} (${creditor.identificador})`;
  }

  searchSubmit(val: SuspdCredor): void {
    this.loading = true;
    this.creditor = val;
    if (this.creditor != null && this.creditor.id != null) {
      this.partnerService.getInstallments(this.creditor.id).subscribe(x => {
        this.loading = false;
        this.installmentList = x;
        if (this.creditor?.id != null) {
          this.auditLogService.clear();
          this.partnerService.loadAuditLogs(this.creditor.id);
        }
      }, error => {
        this.loading = false;
      });
    }
  }

  goBack(): void {
  }

  ngOnInit(): void {
    this.loading = true;
    this.creditorService.getCreditors().subscribe(creditors => {
      this.loading = false;
      this.creditors = creditors;
    }, err => {
      this.loading = false;
    });
  }

  onBatchUpdate(): void {
    this.batchUpdate = true;
  }

  onInstallmentPolicyUpdate(): void {
    this.installmentPolicyUpdate = true;
  }

  onInstallmentPolicyUpdateCancel(): void {
    this.installmentPolicyUpdate = false;
  }

  onBatchUpdateSave(): void {
    this.batchUpdate = false;
  }

  onBatchUpdateCancel(): void {
    this.batchUpdate = false;
  }

  onSaveSuspdPoliticaParcelamento(): void {
    if (this.creditor != null) {
      this.searchSubmit(this.creditor);
    }
  }
}
